import {
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
  differenceInMonths,
  differenceInSeconds,
  differenceInWeeks,
} from "date-fns"

type Unit = "second" | "minute" | "hour" | "day" | "week" | "month"

type DiffUnit = {
  diff: number
  unit: Unit
}

export default function relativeDifferenceTimeWithUnit(date: Date): DiffUnit {
  const now = new Date()
  const timeDiffs = {
    second: differenceInSeconds(date, now),
    minute: differenceInMinutes(date, now),
    hour: differenceInHours(date, now),
    day: differenceInDays(date, now),
    week: differenceInWeeks(date, now),
    month: differenceInMonths(date, now),
  }

  const threshold = {
    second: 60,
    minute: 60,
    hour: 24,
    day: 7,
    week: 4,
  }

  const isInsideThreshold = (unit: Unit, threshold: number) =>
    timeDiffs[unit] > -threshold

  const units = ["second", "minute", "hour", "day", "week"] as const

  for (const unit of units) {
    if (isInsideThreshold(unit, threshold[unit])) {
      return { diff: timeDiffs[unit], unit }
    }
  }

  return { diff: timeDiffs.month, unit: "month" }
}
