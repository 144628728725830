import { createFileRoute, lazyRouteComponent } from "@tanstack/react-router"
import { loadQuery } from "react-relay"

import DocumentPageQuery, {
  DocumentPageQuery as DocumentPageQueryType,
} from "src/pages/DocumentPage/__generated__/DocumentPageQuery.graphql"
import DocumentPageLayout from "src/pages/DocumentPage/DocumentPageLayout"
import requireIsLoggedIn from "src/routeUtils/requireIsLoggedIn"

export const Route = createFileRoute("/documents/$id")({
  beforeLoad: requireIsLoggedIn({ redirectTo: "/sign-up" }),
  pendingComponent: DocumentPageLayout,
  loader: ({ context, params }) =>
    loadQuery<DocumentPageQueryType>(
      context.relayEnvironment(),
      DocumentPageQuery,
      {
        id: params.id,
      },
    ),
  component: lazyRouteComponent(
    () => import("src/pages/DocumentPage/DocumentPage"),
  ),
})
