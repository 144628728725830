/**
 * @generated SignedSource<<a75614017d85bd223c48ea741f567484>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type AddressNavQuery$variables = Record<PropertyKey, never>;
export type AddressNavQuery$data = {
  readonly household: {
    readonly address: {
      readonly firstLine: string | null | undefined;
    } | null | undefined;
  } | null | undefined;
};
export type AddressNavQuery = {
  response: AddressNavQuery$data;
  variables: AddressNavQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "concreteType": "HouseholdAddress",
  "kind": "LinkedField",
  "name": "address",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "firstLine",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "AddressNavQuery",
    "selections": [
      {
        "alias": "household",
        "args": null,
        "concreteType": "Household",
        "kind": "LinkedField",
        "name": "maybeHousehold",
        "plural": false,
        "selections": [
          (v0/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "AddressNavQuery",
    "selections": [
      {
        "alias": "household",
        "args": null,
        "concreteType": "Household",
        "kind": "LinkedField",
        "name": "maybeHousehold",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "93be3a457c505a6414c5b5721fb60e96",
    "id": null,
    "metadata": {},
    "name": "AddressNavQuery",
    "operationKind": "query",
    "text": "query AddressNavQuery {\n  household: maybeHousehold {\n    address {\n      firstLine\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "b7e8952488b0e6f24a924040d906606f";

export default node;
