import {
  createFileRoute,
  lazyRouteComponent,
  redirect,
} from "@tanstack/react-router"
import { loadQuery } from "react-relay"
import { z } from "zod"

import InflationaryPayRiseCalculatorPage2Query, {
  InflationaryPayRiseCalculatorPage2Query as InflationaryPayRiseCalculatorPage2QueryType,
} from "src/pages/InflationaryPayRiseCalculatorPage/__generated__/InflationaryPayRiseCalculatorPage2Query.graphql"
const searchSchema = z
  .object({
    salary: z.number().int(),
    inflation: z.number(),
  })
  .or(
    z.object({
      salary: z.undefined(),
    }),
  )
  .catch({})

export const Route = createFileRoute("/payrise")({
  validateSearch: searchSchema,
  loaderDeps({ search }) {
    return {
      input:
        search.salary == null
          ? null
          : { salary: search.salary, inflation: search.inflation },
    }
  },
  loader: ({ context, deps }) =>
    loadQuery<InflationaryPayRiseCalculatorPage2QueryType>(
      context.relayEnvironment(),
      InflationaryPayRiseCalculatorPage2Query,
      {
        skip: deps.input == null,
        input: {
          annualSalary: {
            amount: deps.input?.salary ?? 0,
            precision: 0,
            currency: "GBP",
          },
          inflation: deps.input?.inflation,
        },
      },
    ),
  beforeLoad: ({ context }) => {
    if (context.authState.isAuthenticated) {
      throw redirect({
        to: "/tools/payrise",
        replace: true,
      })
    }
  },
  component: lazyRouteComponent(
    () =>
      import(
        "src/pages/InflationaryPayRiseCalculatorPage/InflationaryPayRiseCalculatorPage"
      ),
  ),
})
