import { createFileRoute, lazyRouteComponent } from "@tanstack/react-router"
import { loadQuery } from "react-relay"
import { z } from "zod"

import MortgageWizardPageQuery, {
  MortgageWizardPageQuery as MortgageWizardPageQueryType,
} from "src/pages/Wizards/MortgageWizardPage/__generated__/MortgageWizardPageQuery.graphql"
import requireIsLoggedIn from "src/routeUtils/requireIsLoggedIn"

const searchSchema = z.object({
  from: z.string().optional(),
  id: z.string().optional(),
})

export const Route = createFileRoute("/wizard/mortgage")({
  beforeLoad: requireIsLoggedIn({ redirectTo: "/login" }),
  validateSearch: searchSchema,
  loaderDeps: ({ search }) => ({ id: search.id }),
  loader: ({ context, deps }) =>
    loadQuery<MortgageWizardPageQueryType>(
      context.relayEnvironment(),
      MortgageWizardPageQuery,
      { mortgageServiceSummaryId: deps.id ?? null },
    ),
  component: lazyRouteComponent(
    () => import("src/pages/Wizards/MortgageWizardPage/MortgageWizardPage"),
  ),
})
