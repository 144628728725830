/**
 * @generated SignedSource<<1198d679b36efb4a67819d826192fcd9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TaskPageQuery$variables = {
  id: string;
};
export type TaskPageQuery$data = {
  readonly userTask: {
    readonly __typename: string;
    readonly completed: boolean | null | undefined;
    readonly completedAt: string | null | undefined;
    readonly dealCardId?: string | null | undefined;
    readonly quest: {
      readonly __typename: string;
      readonly id: string;
    } | null | undefined;
    readonly " $fragmentSpreads": FragmentRefs<"CompletedTask_userTask" | "DocumentUploadTask_userTask" | "SubmitBankAccountDetailsTask_userTask" | "UpdateHouseholdPreferencesTask_userTask" | "WizardTaskRedirect_userTask">;
  } | null | undefined;
};
export type TaskPageQuery = {
  response: TaskPageQuery$data;
  variables: TaskPageQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "completed",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "completedAt",
  "storageKey": null
},
v5 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "dealCardId",
    "storageKey": null
  }
],
v6 = {
  "kind": "InlineFragment",
  "selections": (v5/*: any*/),
  "type": "ReviewEnergyRecommendationUserTask",
  "abstractKey": null
},
v7 = {
  "kind": "InlineFragment",
  "selections": (v5/*: any*/),
  "type": "ReviewMobileRecommendationUserTask",
  "abstractKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "TaskPageQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "userTask",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "CompletedTask_userTask"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "DocumentUploadTask_userTask"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "SubmitBankAccountDetailsTask_userTask"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "UpdateHouseholdPreferencesTask_userTask"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "WizardTaskRedirect_userTask"
          },
          (v6/*: any*/),
          (v7/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "quest",
            "plural": false,
            "selections": [
              (v8/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "TaskPageQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "userTask",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "kind": "TypeDiscriminator",
            "abstractKey": "__isIUserTask"
          },
          (v8/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "quest",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v8/*: any*/)
            ],
            "storageKey": null
          },
          (v6/*: any*/),
          (v7/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "f52d6e752002dd6d8855fa27bbda1cd1",
    "id": null,
    "metadata": {},
    "name": "TaskPageQuery",
    "operationKind": "query",
    "text": "query TaskPageQuery(\n  $id: ID!\n) {\n  userTask(id: $id) {\n    __typename\n    completed\n    completedAt\n    ...CompletedTask_userTask\n    ...DocumentUploadTask_userTask\n    ...SubmitBankAccountDetailsTask_userTask\n    ...UpdateHouseholdPreferencesTask_userTask\n    ...WizardTaskRedirect_userTask\n    ... on ReviewEnergyRecommendationUserTask {\n      dealCardId\n    }\n    ... on ReviewMobileRecommendationUserTask {\n      dealCardId\n    }\n    quest {\n      id\n      __typename\n    }\n    id\n  }\n}\n\nfragment CompletedTask_userTask on IUserTask {\n  __isIUserTask: __typename\n  id\n  __typename\n  completedAt\n  quest {\n    __typename\n    id\n  }\n}\n\nfragment DocumentUploadTask_userTask on IUserTask {\n  __isIUserTask: __typename\n  ...useCompleteUserTask_userTask\n}\n\nfragment SubmitBankAccountDetailsTask_userTask on IUserTask {\n  __isIUserTask: __typename\n  ...useCompleteUserTask_userTask\n}\n\nfragment UpdateHouseholdPreferencesTask_userTask on IUserTask {\n  __isIUserTask: __typename\n  ...useCompleteUserTask_userTask\n}\n\nfragment WizardTaskRedirect_userTask on IUserTask {\n  __isIUserTask: __typename\n  id\n  __typename\n}\n\nfragment useCompleteUserTask_userTask on IUserTask {\n  __isIUserTask: __typename\n  __typename\n  id\n}\n"
  }
};
})();

(node as any).hash = "1724c364152dc7182ed401cb76f9c572";

export default node;
