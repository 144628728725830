import { Header } from "src/components/Header/Header"
import { MobileTopNav } from "src/components/Nav/MobileTopNav"
import { GlowLayoutCentered } from "src/glow"
import { NavLayout } from "src/layouts/NavLayout/NavLayout"
import LoadingPagePlaceholder from "src/LoadingPagePlaceholder"

export function UploadDocumentViaLinkPageLayout(props: {
  children: React.ReactNode
}) {
  return (
    <div className="bg-gray-100 min-h-full">
      <NavLayout
        mobileNav={{
          top: <MobileTopNav variant="primary" />,
          bottom: null,
        }}
        desktopNav={
          <Header>
            <Header.Logo />
          </Header>
        }
      >
        <GlowLayoutCentered className="pt:4 md:pt-12 px-4 md:px-0">
          {props.children ?? <LoadingPagePlaceholder />}
        </GlowLayoutCentered>
      </NavLayout>
    </div>
  )
}
