export declare class Property<T> {
  isValid(value: unknown): value is T
  nullable(): Property<T | null>
}

export function enumString<U extends string, T extends Readonly<[U, ...U[]]>>(
  values: T,
): Property<T[number]> {
  return {
    isValid(value: unknown): value is U {
      if (typeof value !== "string") {
        return false
      }
      return (values as Readonly<string[]>).includes(value)
    },
    nullable() {
      return nullable(enumString(values))
    },
  }
}

export function union<T extends [Property<any>, ...Property<any>[]]>(
  properties: T,
): Property<T[number] extends Property<infer U> ? U : never> {
  return {
    isValid(
      value: unknown,
    ): value is T[number] extends Property<infer U> ? U : never {
      return properties.some((property) => property.isValid(value))
    },
    nullable() {
      return nullable(union(properties))
    },
  }
}

export function string(): Property<string> {
  return {
    isValid(value: unknown): value is string {
      return typeof value === "string"
    },
    nullable() {
      return nullable(string())
    },
  }
}

export function number(): Property<number> {
  return {
    isValid(value: unknown): value is number {
      return typeof value === "number"
    },
    nullable() {
      return nullable(number())
    },
  }
}

export function boolean(): Property<boolean> {
  return {
    isValid(value: unknown): value is boolean {
      return typeof value === "boolean"
    },
    nullable() {
      return nullable(boolean())
    },
  }
}

export function nullable<T>(innerProperty: Property<T>): Property<T | null> {
  return {
    isValid(value: unknown): value is T | null {
      return value === null || innerProperty.isValid(value)
    },
    nullable() {
      return innerProperty
    },
  }
}

export function array<T>(innerProperty: Property<T>): Property<T[]> {
  return {
    isValid(value: unknown): value is T[] {
      return Array.isArray(value) && value.every(innerProperty.isValid)
    },
    nullable() {
      return nullable(array(innerProperty))
    },
  }
}

export type BasePropertiesType = Record<string, any> | undefined

export declare class Properties<TProperties extends BasePropertiesType> {
  properties: { [K in keyof TProperties]: Property<TProperties[K]> } | undefined
  isValid(properties: unknown): properties is TProperties
}

export function properties<
  TProperties extends { [k: string]: any } & { service?: never },
>(properties: {
  [K in keyof TProperties]: Property<TProperties[K]>
}): Properties<TProperties> {
  return {
    properties,
    isValid(propertiesToCheck: unknown): propertiesToCheck is TProperties {
      if (typeof propertiesToCheck !== "object" || propertiesToCheck === null) {
        return false
      }
      for (const key in properties) {
        if (
          !properties[key].isValid((propertiesToCheck as Record<any, any>)[key])
        ) {
          return false
        }
      }
      return true
    },
  }
}

export function noProperties(): Properties<undefined> {
  return {
    properties: undefined,
    isValid(properties: unknown): properties is undefined {
      return properties === undefined
    },
  }
}
