import {
  createFileRoute,
  lazyRouteComponent,
  redirect,
} from "@tanstack/react-router"
import { z } from "zod"
const searchSchema = z.object({
  redirectTo: z.string().optional(),
  email: z.string().optional(),
  passwordResetSuccessful: z.boolean().optional(),
  afterLogout: z.boolean().optional(),
})

export const Route = createFileRoute("/login")({
  validateSearch: searchSchema,
  beforeLoad({ context, search }) {
    if (context.authState.isAuthenticated && !search.afterLogout) {
      throw redirect({ to: search.redirectTo || "/" })
    }
  },
  component: lazyRouteComponent(() => import("src/pages/LoginPage/LoginPage")),
})
