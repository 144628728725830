/* eslint-disable no-console */
import { datadogLogs } from "@datadog/browser-logs"
import * as Sentry from "@sentry/react"

export const initLogger = (clientToken: string, site: string) => {
  datadogLogs.init({
    clientToken,
    site,
    forwardErrorsToLogs: true,
    sessionSampleRate: 100,
    trackSessionAcrossSubdomains: true,
    env: __NOUS_ENV__,
    service: __SERVICE_NAME__,
    version: __GITHUB_SHA__ ?? "unknown",
    useSecureSessionCookie: true,
    useCrossSiteSessionCookie: true,
  })
}

export const addUserIdGlobalContextToLogger = (userId: string) => {
  datadogLogs.setGlobalContextProperty("userId", userId)
}

const browserLogger = datadogLogs.logger

const debug = (message: string, messageContext?: object): void => {
  browserLogger.debug(message, messageContext)
  if (__NODE_ENV__ !== "production") {
    console.debug(
      "%cDEBUG",
      "font-weight: bold; color: blue",
      message,
      messageContext,
    )
  }
}
const info = (message: string, messageContext?: object): void => {
  browserLogger.info(message, messageContext)
  if (__NODE_ENV__ !== "production") {
    console.info(
      "%cINFO",
      "font-weight: bold; color: green",
      message,
      messageContext,
    )
  }
}
const warn = (message: string, messageContext?: object): void => {
  browserLogger.warn(message, messageContext)
  if (__NODE_ENV__ !== "production") {
    console.warn(
      "%cWARN",
      "font-weight: bold; color: orange",
      message,
      messageContext,
    )
  }
}
const error = (message: string, messageContext?: object): void => {
  browserLogger.error(message, messageContext)
  if (__NODE_ENV__ !== "production") {
    console.error(
      "%cERROR",
      "font-weight: bold; color: red",
      message,
      messageContext,
    )
  }
}

const redirecting = (
  where: string,
  reason: string,
  messageContext?: object,
): void => {
  debug(`Redirecting to ${where}`, { reason, ...messageContext })
}

const errorWithSentryExeption = (
  error: Error,
  errorInfo?: React.ErrorInfo,
  message = " Error boundary",
) => {
  Sentry.withScope((scope) => {
    scope.setExtras({ errorInfo })
    Sentry.captureException(error)
  })
  browserLogger.error(message, error)
}

export const logger = {
  debug,
  info,
  warn,
  error,
  redirecting,
  errorWithSentryExeption,
}
