import React from "react"

import { Listbox } from "@headlessui/react"
import clsx from "clsx"

import { GlowFlexbox } from "./GlowFlexbox"
import { GlowIcon } from "./GlowIcon"
import { GlowInputErrors, GlowInputErrorsProps } from "./GlowInput"
import { GlowText } from "./GlowText"

export type GlowInputSelectProps<T> = {
  placeholder?: string
  label: string
  emptyLabel?: string
  hideLabel?: boolean
  assistiveText?: string
  value: T
  onChange: (value: T) => void
  options: Array<{ value: T; label: string }>
  errors?: GlowInputErrorsProps["errors"]
}

export default function GlowInputSelect<T>(props: GlowInputSelectProps<T>) {
  const selected = props.options.find((o) => o.value === props.value)
  return (
    <GlowFlexbox direction="column" gap="1">
      {!props.hideLabel && (
        <GlowText size="sm" fontWeight="medium">
          {props.label}
        </GlowText>
      )}
      {props.assistiveText && (
        <GlowText size="xs" className="text-off-black-64">
          {props.assistiveText}
        </GlowText>
      )}

      <Listbox
        disabled={props.options.length === 0}
        as="div"
        className="w-full relative"
        value={props.value}
        onChange={props.onChange}
      >
        {({ open }) => {
          return (
            <>
              <Listbox.Button
                className={clsx(
                  "w-full",
                  "border rounded-lg h-12 box-border",
                  "focus:outline-serene-blue-400 focus:outline-[3px] focus:outline-offset-0 focus:border-transparent ",
                  "disabled:cursor-not-allowed disabled:bg-gray-50 disabled:border-gray-500/20",
                  props.errors?.length
                    ? "border-angsty-red-400"
                    : "border-off-black-20",
                )}
              >
                <GlowFlexbox
                  alignItems="center"
                  padding="3"
                  justifyContent="space-between"
                >
                  {props.options.length ? (
                    selected ? (
                      <GlowText
                        className="text-gray-500 truncate"
                        textAlign="left"
                      >
                        {selected.label}
                      </GlowText>
                    ) : (
                      <GlowText className="text-gray-500/64">
                        {props.placeholder}
                      </GlowText>
                    )
                  ) : (
                    <GlowText className="text-gray-500/64">
                      {props.emptyLabel}
                    </GlowText>
                  )}
                  <GlowIcon
                    name="arrow_down_1_regular"
                    className={clsx(
                      "w-6 h-6",
                      props.errors?.length
                        ? "text-angsty-red-400"
                        : "text-gary-500",
                    )}
                  />
                </GlowFlexbox>
              </Listbox.Button>
              {open && props.options.length > 0 && (
                <Listbox.Options className="absolute w-full bg-white rounded-lg border-off-black-20 border z-dropdown overflow-auto divide-y max-h-56 top-full">
                  {props.options.map((option, i) => (
                    <Listbox.Option
                      value={option.value}
                      key={i}
                      as={React.Fragment}
                    >
                      {({ active }) => (
                        <li
                          className={clsx(
                            "cursor-pointer py-2 px-3",
                            active && "bg-gray-100",
                          )}
                        >
                          {option.label}
                        </li>
                      )}
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              )}
            </>
          )
        }}
      </Listbox>
      <GlowInputErrors errors={props.errors} />
    </GlowFlexbox>
  )
}
