import {
  createFileRoute,
  lazyRouteComponent,
  redirect,
} from "@tanstack/react-router"
import { z } from "zod"

const searchSchema = z.object({
  email: z.string().optional(),
})

export const Route = createFileRoute("/forgot-password")({
  validateSearch: searchSchema,
  beforeLoad: ({ context }) => {
    if (context.authState.isAuthenticated) {
      throw redirect({ to: "/your-household" })
    }
  },
  component: lazyRouteComponent(
    () => import("src/pages/ForgotPasswordPage/ForgotPasswordPage"),
  ),
})
