/**
 * @generated SignedSource<<1c84842389faf21ab761d526a1c3d38f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ConnectEmailPageQuery$variables = Record<PropertyKey, never>;
export type ConnectEmailPageQuery$data = {
  readonly household: {
    readonly " $fragmentSpreads": FragmentRefs<"OnboardingConnectEmailBenefitsStepContent_household">;
  };
};
export type ConnectEmailPageQuery = {
  response: ConnectEmailPageQuery$data;
  variables: ConnectEmailPageQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "ConnectEmailPageQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Household",
        "kind": "LinkedField",
        "name": "household",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "OnboardingConnectEmailBenefitsStepContent_household"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "ConnectEmailPageQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Household",
        "kind": "LinkedField",
        "name": "household",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "HouseholdFormAnswers",
            "kind": "LinkedField",
            "name": "formAnswers",
            "plural": false,
            "selections": [
              {
                "alias": "connectEmailBenefits",
                "args": [
                  {
                    "kind": "Literal",
                    "name": "key",
                    "value": "connectEmailBenefits"
                  }
                ],
                "concreteType": "FormAnswer",
                "kind": "LinkedField",
                "name": "answer",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "key",
                    "storageKey": null
                  },
                  (v0/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "booleanValue",
                    "storageKey": null
                  }
                ],
                "storageKey": "answer(key:\"connectEmailBenefits\")"
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "EmailConnection",
            "kind": "LinkedField",
            "name": "emailConnections",
            "plural": true,
            "selections": [
              (v0/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "email",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "lastScannedAt",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "source",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v0/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "3f1f122f66273d9f2759cb16adc85699",
    "id": null,
    "metadata": {},
    "name": "ConnectEmailPageQuery",
    "operationKind": "query",
    "text": "query ConnectEmailPageQuery {\n  household {\n    ...OnboardingConnectEmailBenefitsStepContent_household\n    id\n  }\n}\n\nfragment OnboardingConnectEmailBenefitsStepConnectedEmailsSection_emailConnection on EmailConnection {\n  id\n  email\n  lastScannedAt\n  source\n}\n\nfragment OnboardingConnectEmailBenefitsStepConnectedEmailsSection_emailConnections on EmailConnection {\n  id\n  ...OnboardingConnectEmailBenefitsStepConnectedEmailsSection_emailConnection\n}\n\nfragment OnboardingConnectEmailBenefitsStepContent_household on Household {\n  formAnswers {\n    connectEmailBenefits: answer(key: \"connectEmailBenefits\") {\n      key\n      id\n      booleanValue\n    }\n  }\n  emailConnections {\n    ...OnboardingConnectEmailBenefitsStepConnectedEmailsSection_emailConnections\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "e7a2762213cab7f092da5caf6fe32275";

export default node;
