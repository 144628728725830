import { createFileRoute, lazyRouteComponent } from "@tanstack/react-router"
import { loadQuery } from "react-relay"
import { z } from "zod"

import ValidDocumentUploadLinkProviderQuery, {
  ValidDocumentUploadLinkProviderQuery as ValidDocumentUploadLinkProviderQueryType,
} from "src/pages/UploadDocumentViaLinkPage/__generated__/ValidDocumentUploadLinkProviderQuery.graphql"
import { UploadDocumentViaLinkPageLayout } from "src/pages/UploadDocumentViaLinkPage/UploadDocumentViaLinkPageLayout"
const searchSchema = z.object({
  id: z.string(),
})

export const Route = createFileRoute("/upload-document")({
  validateSearch: searchSchema,
  pendingComponent: UploadDocumentViaLinkPageLayout,
  loaderDeps({ search }) {
    return {
      id: search.id,
    }
  },
  loader({ context, deps }) {
    return loadQuery<ValidDocumentUploadLinkProviderQueryType>(
      context.relayEnvironment(),
      ValidDocumentUploadLinkProviderQuery,
      { id: deps.id },
    )
  },
  component: lazyRouteComponent(
    () =>
      import("src/pages/UploadDocumentViaLinkPage/UploadDocumentViaLinkPage"),
  ),
})
