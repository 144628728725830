/**
 * @generated SignedSource<<f98fa54d1d6e6767e3b4ee1b90c46c65>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type NotificationNav_household$data = {
  readonly userNotifications: {
    readonly totalCount: number;
  } | null | undefined;
  readonly " $fragmentType": "NotificationNav_household";
};
export type NotificationNav_household$key = {
  readonly " $data"?: NotificationNav_household$data;
  readonly " $fragmentSpreads": FragmentRefs<"NotificationNav_household">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "NotificationNav_household",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "first",
          "value": 0
        },
        {
          "kind": "Literal",
          "name": "read",
          "value": false
        }
      ],
      "concreteType": "HouseholdUserNotificationConnection",
      "kind": "LinkedField",
      "name": "userNotifications",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "totalCount",
          "storageKey": null
        }
      ],
      "storageKey": "userNotifications(first:0,read:false)"
    }
  ],
  "type": "Household",
  "abstractKey": null
};

(node as any).hash = "b8fba98e6233ca08bc62d25d67ee9264";

export default node;
