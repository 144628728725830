import { useNavigate } from "@tanstack/react-router"
import { useIntl } from "react-intl"

import { Logo } from "src/components/Logo/Logo"
import { Main } from "src/components/Main/Main"
import { GlowButton } from "src/glow"

export default () => {
  const intl = useIntl()
  const navigate = useNavigate()
  return (
    <Main size="xl" className="bg-white md:bg-gray-100 h-full">
      <section className="flex justify-center h-full">
        <Logo className="absolute top-6 md:left-6 h-5" />

        <div className="flex flex-col justify-center items-center gap-6 text-center h-full w-full">
          <img src="/images/404.png" alt="404" className="w-1/2" />
          <div className="flex flex-col gap-2 max-w-xs">
            <h1 className="text-xl font-medium">
              {intl.formatMessage({
                id: "notFoundPage.title",
                defaultMessage: "Page not found",
              })}
            </h1>
            <p className="text-sm">
              {intl.formatMessage({
                id: "notFoundPage.text",
                defaultMessage:
                  "The page you are looking for doesn't exist or has been moved.",
              })}
            </p>
          </div>
          <GlowButton
            variant="tertiary"
            className="w-full max-w-xs"
            onClick={() =>
              navigate({
                to: "/",
              })
            }
            label={intl.formatMessage({
              id: "notFoundPage.goToDashboard",
              defaultMessage: "Go to your dashboard",
            })}
          />
        </div>
      </section>
    </Main>
  )
}
