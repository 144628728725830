import { createFileRoute, lazyRouteComponent } from "@tanstack/react-router"
import { loadQuery } from "react-relay"

import StatementPageQuery, {
  StatementPageQuery as StatementPageQueryType,
} from "src/pages/StatementPage/__generated__/StatementPageQuery.graphql"
import requireIsLoggedIn from "src/routeUtils/requireIsLoggedIn"

export const Route = createFileRoute("/statement/$id")({
  beforeLoad: requireIsLoggedIn({ redirectTo: "/login" }),
  loader: ({ context, params }) =>
    loadQuery<StatementPageQueryType>(
      context.relayEnvironment(),
      StatementPageQuery,
      {
        id: params.id,
      },
    ),
  component: lazyRouteComponent(
    () => import("src/pages/StatementPage/StatementPage"),
  ),
})
