import "@formatjs/intl-numberformat/polyfill"
import "@formatjs/intl-numberformat/locale-data/en" // locale-data for en
import "@formatjs/intl-getcanonicallocales/polyfill"
import "@formatjs/intl-locale/polyfill"
import "@formatjs/intl-pluralrules/polyfill"
import "@formatjs/intl-pluralrules/locale-data/en" // locale-data for en
import { useMemo, useState } from "react"
import React from "react"

import { OnErrorFn } from "@formatjs/intl"
import { IntlProvider, IntlShape } from "react-intl"

import { logger } from "src/logger"

export const GBP_ZERO_DECIMAL_PLACES_CONFIG = {
  style: "currency" as const,
  currency: "GBP" as const,
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
}

export const GBP_TWO_DECIMAL_PLACES_CONFIG = {
  style: "currency" as const,
  currency: "GBP" as const,
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
}

const skipRemoteIntl = __NOUS_ENV__ === "test" || __NOUS_ENV__ === "development"

const CURRENT_MESSAGES = skipRemoteIntl ? {} : INTL_MESSAGES
const I18N_KEYS = skipRemoteIntl ? [] : __I18N_KEYS__

const maybeLogError: OnErrorFn = (arg) => {
  if (arg.code === "MISSING_TRANSLATION") {
    return
  }
  logger.errorWithSentryExeption(arg, undefined, "IntlProvider error")
}

type DebugConfig = {
  debugLocaleId: boolean
}
const Context = React.createContext<
  readonly [DebugConfig, React.Dispatch<React.SetStateAction<DebugConfig>>]
>([{ debugLocaleId: false }, () => {}])

export function IntlDebugContextSafeHaven({
  children,
}: {
  children: React.ReactNode
}) {
  return (
    <IntlProvider
      locale="en-GB"
      messages={CURRENT_MESSAGES}
      onError={maybeLogError}
    >
      {children}
    </IntlProvider>
  )
}

export function useIntlDebugLocaleId() {
  return React.useContext(Context)
}
export const pencePerKilowattHour = (pence: number, intl: IntlShape) =>
  pencePer(pence, intl, "kWh")
export const pencePerDay = (pence: number, intl: IntlShape) =>
  pencePer(pence, intl, "day")
const pencePer = (p: number, intl: IntlShape, per: string) =>
  `${pence(p, intl)} /${per}`
export const pence = (
  pence: number,
  intl: IntlShape,
  opts: {
    minimumFractionDigits?: number
    maximumFractionDigits?: number
  } = {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  },
) => `${intl.formatNumber(pence, opts)}p`

export function Intl(props: { children: React.ReactNode }) {
  const [value, setValue] = useState<DebugConfig>({
    debugLocaleId: false,
  })
  const contextValue = useMemo(() => {
    return [value, setValue] as const
  }, [value, setValue])
  const msgs = useMemo(() => {
    if (value.debugLocaleId) {
      return Object.fromEntries(I18N_KEYS.map((key) => [key, key]))
    }
    return CURRENT_MESSAGES
  }, [value.debugLocaleId])
  return (
    <IntlProvider locale="en-GB" messages={msgs} onError={maybeLogError}>
      <Context.Provider value={contextValue}>{props.children}</Context.Provider>
    </IntlProvider>
  )
}
