import { GlowFlexbox } from "./GlowFlexbox"
import { GlowIcon } from "./GlowIcon"
import { GlowInput } from "./GlowInput"
import { GlowText } from "./GlowText"

export type GlowNumberInputProps = {
  value: number
  label?: string
  onChange: (value: number) => void
  minimumValue?: number
  maximumValue?: number
  defaultValue?: number
}

export const GlowNumberInput = ({
  onChange,
  value,
  label,
  minimumValue = 0,
  maximumValue,
}: GlowNumberInputProps) => {
  return (
    <GlowFlexbox direction="column" gap="1">
      {label && (
        <GlowText
          size={{ sm: "sm", md: "base" }}
          fontWeight="medium"
          textAlign="center"
        >
          {label}
        </GlowText>
      )}
      <GlowFlexbox as="fieldset" gap={"4"}>
        <button
          aria-label="Subtract button"
          onClick={() => {
            if (minimumValue !== undefined) {
              if (value > minimumValue) {
                onChange(value - 1)
              } else {
                onChange(minimumValue)
              }
            } else {
              onChange(value - 1)
            }
          }}
          className="rounded-full border-2 p-3 enabled:hover:bg-gray-50 disabled:cursor-not-allowed"
          disabled={minimumValue !== undefined && value <= minimumValue}
          type="button"
        >
          <GlowIcon name="minus_small_regular" className="h-5 w-5" />
        </button>
        <GlowInput
          type="number"
          value={value}
          onChange={(value) => {
            const numberValue = Number(value)
            if (minimumValue !== undefined && numberValue < minimumValue) {
              onChange(minimumValue)
            } else if (
              maximumValue !== undefined &&
              numberValue > maximumValue
            ) {
              onChange(maximumValue)
            } else {
              onChange(Number(value))
            }
          }}
          className="hide-number-input-arrows border-2 hover:border-gray-500/20 text-center"
        />
        <button
          onClick={() => {
            if (maximumValue !== undefined) {
              if (value < maximumValue) {
                onChange(value + 1)
              } else {
                onChange(maximumValue)
              }
            } else {
              onChange(value + 1)
            }
          }}
          className="rounded-full border-2 p-3 enabled:hover:bg-gray-50 disabled:cursor-not-allowed"
          disabled={maximumValue !== undefined && value >= maximumValue}
          type="button"
        >
          <GlowIcon name="plus_small_regular" className="h-5 w-5" />
        </button>
      </GlowFlexbox>
    </GlowFlexbox>
  )
}
