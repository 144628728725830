import { MobileBottomNav } from "src/components/Nav/MobileBottomNav"
import { useViewport, Viewport } from "src/hooks/responsive"

type NavLayoutProps = {
  mobileNav: {
    top: React.ReactNode
    bottom?: React.ReactNode | null
  }
  desktopNav?: React.ReactNode
  children?: React.ReactNode
}

export const NavLayout = ({
  children,
  mobileNav,
  desktopNav,
}: NavLayoutProps) => {
  const viewport = useViewport()
  const isMobile = viewport <= Viewport.SM
  const topNav = isMobile ? mobileNav.top : desktopNav
  const bottomNav =
    isMobile &&
    (mobileNav.bottom !== undefined ? (
      <div>{mobileNav.bottom}</div>
    ) : (
      <MobileBottomNav />
    ))

  return (
    <>
      <div
        className="grid h-full"
        style={{
          gridTemplateRows: "auto 1fr auto",
          gridTemplateColumns: "100%",
        }}
      >
        <div>{topNav}</div>
        <div>
          {/* REVIEW should we create the <main> element here? */}
          {children}
        </div>
        {bottomNav}
      </div>
    </>
  )
}
