import { createFileRoute, lazyRouteComponent } from "@tanstack/react-router"
import { loadQuery } from "react-relay"
import { z } from "zod"

import OnboardingLayout from "src/pages/Onboarding/layouts/OnboardingLayout"
import OnboardingBankDetailsPageQuery, {
  OnboardingBankDetailsPageQuery as OnboardingBankDetailsPageQueryType,
} from "src/pages/Onboarding/OnboardingBankDetailsPage/__generated__/OnboardingBankDetailsPageQuery.graphql"
import requireIsLoggedIn from "src/routeUtils/requireIsLoggedIn"

const searchSchema = z.object({
  delegationContext: z.string().optional(),
})

export const Route = createFileRoute("/onboarding/bank-details")({
  beforeLoad: requireIsLoggedIn({ redirectTo: "/onboarding/" }),
  validateSearch: searchSchema,
  pendingComponent: () => (
    <OnboardingLayout title={""}>
      <></>
    </OnboardingLayout>
  ),

  loader: ({ context }) =>
    loadQuery<OnboardingBankDetailsPageQueryType>(
      context.relayEnvironment(),
      OnboardingBankDetailsPageQuery,
      {},
    ),
  component: lazyRouteComponent(
    () =>
      import(
        "src/pages/Onboarding/OnboardingBankDetailsPage/OnboardingBankDetailsPage"
      ),
  ),
})
