import { FileRoutesByPath, redirect } from "@tanstack/react-router"

import { RouteContext } from "src/tanstack-routes/__root"

export default function requireIsLoggedIn(opts: {
  redirectTo: keyof FileRoutesByPath
}) {
  return (args: { context: RouteContext }) => {
    if (!args.context.authState.isAuthenticated) {
      throw redirect({ to: opts.redirectTo })
    }
  }
}
