import React from "react"

import { Helmet } from "react-helmet-async"

type RobotsParams =
  | "noindex"
  | "index"
  | "follow"
  | "nofollow"
  | "noimageindex"
  | "none"
  | "noarchive"
  | "nocache"
  | "nosnippet"

interface MetaTagsProps {
  tag?: `og:${string}`

  ogType?: string
  ogWidth?: string
  ogHeight?: string

  locale?: string

  ogContentUrl?: string
  ogUrl?: `${"http://" | "https://"}${string}`
  contentType?: string

  robots?: RobotsParams | RobotsParams[]

  /**
   * Title of the page shown in the tab.
   *
   * Already include "| Nous"
   */
  title?: string
  description?: string
  author?: string

  children?: React.ReactNode
}

export default function MetaTags({
  tag = "og:image",
  ogType = "website",
  ogContentUrl,
  robots,
  contentType,
  ogWidth,
  ogHeight,
  ogUrl,
  title,
  locale,
  description,
  author,
  children,
}: MetaTagsProps) {
  return (
    <>
      {title && (
        <Helmet titleTemplate={`${title} | Nous`}>
          <title>{title}</title>
          <meta property="og:title" content={title} key="title" />
          <meta property="twitter:title" content={title} />
        </Helmet>
      )}

      {description && (
        <Helmet>
          <meta name="description" content={description} />
          <meta name="twitter:description" content={description} />
          <meta property="og:description" content={description} />
        </Helmet>
      )}

      {author && (
        <Helmet>
          <meta name="author" content={author} />
          <meta name="twitter:site" content={author} />
          <meta name="twitter:creator" content={author} />
        </Helmet>
      )}

      {ogUrl && (
        <Helmet>
          <meta property="og:url" content={ogUrl} />
        </Helmet>
      )}

      {locale && (
        <Helmet>
          <html lang={locale} />
          <meta property="og:locale" content={locale} />
        </Helmet>
      )}

      <Helmet>
        <meta property="og:type" content={ogType} />
      </Helmet>

      {ogContentUrl && (
        <Helmet>
          <meta property={tag} content={ogContentUrl} />
        </Helmet>
      )}

      {contentType && (
        <Helmet>
          <meta property={`${tag}:type`} content={contentType} />
        </Helmet>
      )}

      {tag === "og:image" && (
        <Helmet>
          {ogWidth && <meta property="image:width" content={ogWidth} />}
          {ogHeight && <meta property="image:height" content={ogHeight} />}
          <meta property="twitter:card" content="summary_large_image" />
          <meta property="twitter:image" content={ogContentUrl} />
        </Helmet>
      )}

      {robots && (
        <Helmet>
          <meta
            name="robots"
            content={Array.isArray(robots) ? robots.join(", ") : robots}
          />
        </Helmet>
      )}

      {children}
    </>
  )
}
