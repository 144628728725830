import React, { useCallback, useEffect, useMemo, useState } from "react"

import { GlowCheckbox, GlowCheckboxProps } from "./GlowCheckbox"
import {
  GlowDayPicker,
  GlowDayPickerDate,
  GlowDayPickerProps,
  GlowMonthPicker,
  GlowMonthPickerDate,
  GlowMonthPickerProps,
  GlowQuizDayPicker,
  GlowQuizMonthPicker,
} from "./GlowDatePicker"
import {
  GlowInput,
  GlowInputErrorMessagesType,
  GlowInputProps,
} from "./GlowInput"
import GlowInputSelect, { GlowInputSelectProps } from "./GlowInputSelect"
import { GlowNumberInput, GlowNumberInputProps } from "./GlowNumberInput"
import { GlowQuizInput, GlowQuizInputProps } from "./GlowQuizInput"
import { GlowTextArea, GlowTextAreaProps } from "./GlowTextArea"

type Props<TFormData> = {
  onSubmit: (data: TFormData) => void
  value: TFormData
  onChange: (data: TFormData) => void
  children?: React.ReactNode
} & Omit<React.ComponentProps<"form">, "onSubmit" | "onChange">

export function GlowForm<TFormData>({
  children,
  onSubmit: onSubmitProp,
  value,
  onChange,
  ...props
}: Props<TFormData>) {
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [errors, setErrors] = React.useState<Record<string, string[]>>({})
  const [dirty, setDirtyState] = useState<{ [key: string]: boolean }>({})
  const context = useMemo(
    () => ({
      value,
      onChange,
      registerErrors: (key: string, errors: string[]) => {
        setErrors((prevErrors) => ({ ...prevErrors, [key]: errors }))
      },
      isSubmitted,
      dirty,
      setDirty: (key: string) => {
        setDirtyState((prevDirty) => ({ ...prevDirty, [key]: true }))
      },
    }),
    [value, onChange, setErrors, isSubmitted, dirty, setDirtyState],
  )
  const onSubmit = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault()
      setIsSubmitted(true)
      if (Object.values(errors).some((errors) => errors.length > 0)) {
        return
      }
      onSubmitProp(value)
    },
    [onSubmitProp, value, setIsSubmitted, errors],
  )
  return (
    <FormContext.Provider value={context}>
      <form {...props} onSubmit={onSubmit}>
        {children}
      </form>
    </FormContext.Provider>
  )
}

type FormContextType<TFormData> = {
  value: TFormData
  onChange: (data: TFormData) => void
  registerErrors: (key: string, errors: string[]) => void
  isSubmitted: boolean
  dirty: { [key: string]: boolean }
  setDirty: (key: string) => void
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const FormContext = React.createContext<FormContextType<any> | null>(null)

function useGlowFormInput<TValue>(
  formKey: string,
  getErrors?: (value: TValue) => GlowInputErrorMessagesType,
  getIsDirty?: (value: boolean) => void,
): {
  value: TValue
  onChange: (value: TValue) => void
  errors?: string[]
} {
  const context = React.useContext(FormContext)
  const value = useMemo(() => context?.value[formKey], [context, formKey])
  const errors = useMemo(
    () => (getErrors?.(value)?.filter(Boolean) ?? []) as string[],
    [value, getErrors],
  )

  const isDirty = context?.dirty[formKey] ?? false

  useEffect(() => {
    context?.registerErrors(formKey, errors)
    return () => context?.registerErrors(formKey, [])
  }, [errors, context, formKey])
  const onChange = useCallback(
    (value: TValue) => {
      context?.onChange({ ...context.value, [formKey]: value })
      getIsDirty?.(isDirty)
      if (!isDirty) {
        context?.setDirty(formKey)
      }
    },
    [context, formKey, isDirty, getIsDirty],
  )
  return {
    value,
    onChange,
    errors: context?.isSubmitted ? errors : undefined,
  }
}

type FormInputProps<TComponent, TValue> = Omit<
  TComponent,
  "value" | "onChange" | "errors"
> & {
  formKey: string
  getErrors?: (value: TValue) => NonNullable<GlowQuizInputProps["errors"]>
  getIsDirty?: (value: boolean) => void
}

type GlowQuizFormInputProps = FormInputProps<GlowQuizInputProps, string>

export function GlowQuizFormInput({
  formKey,
  getErrors,
  getIsDirty,
  ...props
}: GlowQuizFormInputProps) {
  const formContentProps = useGlowFormInput(formKey, getErrors, getIsDirty)

  return <GlowQuizInput {...props} {...formContentProps} />
}

type GlowFormInputProps = FormInputProps<GlowInputProps, string>

export function GlowFormInput({
  formKey,
  getErrors,
  getIsDirty,
  ...props
}: GlowFormInputProps) {
  const formContentProps = useGlowFormInput(formKey, getErrors, getIsDirty)
  return <GlowInput {...props} {...formContentProps} />
}

export function GlowFormNumberInput({
  formKey,
  getErrors,
  getIsDirty,
  ...props
}: FormInputProps<GlowNumberInputProps, number>) {
  const formContentProps = useGlowFormInput(formKey, getErrors, getIsDirty)
  return <GlowNumberInput {...props} {...formContentProps} />
}

export function GlowFormDayPicker({
  formKey,
  getErrors,
  getIsDirty,
  ...props
}: FormInputProps<GlowDayPickerProps, GlowDayPickerDate>) {
  const formContentProps = useGlowFormInput(formKey, getErrors, getIsDirty)
  return <GlowDayPicker {...props} {...formContentProps} />
}

export function GlowFormQuizDayPicker({
  formKey,
  getErrors,
  getIsDirty,
  ...props
}: FormInputProps<GlowDayPickerProps, GlowDayPickerDate>) {
  const formContentProps = useGlowFormInput(formKey, getErrors, getIsDirty)
  return <GlowQuizDayPicker {...props} {...formContentProps} />
}

export function GlowFormQuizMonthPicker({
  formKey,
  getErrors,
  getIsDirty,
  ...props
}: FormInputProps<GlowMonthPickerProps, GlowMonthPickerDate>) {
  const formContentProps = useGlowFormInput(formKey, getErrors, getIsDirty)
  return <GlowQuizMonthPicker {...props} {...formContentProps} />
}

export function GlowFormMonthPicker({
  formKey,
  getErrors,
  getIsDirty,
  ...props
}: FormInputProps<GlowMonthPickerProps, GlowMonthPickerDate>) {
  const formContentProps = useGlowFormInput(formKey, getErrors, getIsDirty)
  return <GlowMonthPicker {...props} {...formContentProps} />
}

export function GlowFormInputSelect<T>({
  formKey,
  getErrors,
  getIsDirty,
  ...props
}: FormInputProps<GlowInputSelectProps<T>, T>) {
  const formContentProps = useGlowFormInput(formKey, getErrors, getIsDirty)
  return <GlowInputSelect {...props} {...formContentProps} />
}

export function GlowFormInputCheckbox({
  formKey,
  getErrors,
  getIsDirty,
  ...props
}: FormInputProps<GlowCheckboxProps, boolean>) {
  const formContentProps = useGlowFormInput(formKey, getErrors, getIsDirty)
  return (
    <GlowCheckbox
      {...props}
      isChecked={formContentProps.value}
      errors={formContentProps.errors}
      onChange={formContentProps.onChange}
    />
  )
}

export function GlowFormSortCodeInput({
  formKey,
  getErrors,
  getIsDirty,
  ...props
}: GlowFormInputProps) {
  const formContentProps = useGlowFormInput(formKey, getErrors, getIsDirty)

  const onChange = useCallback(
    (value: string) => {
      const formattedValue = value.replace(/\D/g, "")

      const maskedInput = formattedValue
        .substring(0, 6)
        .replace(/(\d{2})(\d{2})?(\d{0,2})?/, (_, p1, p2, p3) =>
          [p1, p2 && `-${p2}`, p3 && `-${p3}`].filter(Boolean).join(""),
        )

      formContentProps.onChange(maskedInput)
    },
    [formContentProps],
  )

  return <GlowInput {...props} {...formContentProps} onChange={onChange} />
}

type GlowFormTextAreaProps = FormInputProps<GlowTextAreaProps, string>

export function GlowFormTextArea({
  formKey,
  getErrors,
  getIsDirty,
  ...props
}: GlowFormTextAreaProps) {
  const formContentProps = useGlowFormInput(formKey, getErrors, getIsDirty)
  return <GlowTextArea {...props} {...formContentProps} />
}
