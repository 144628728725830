import React from "react"

import { Menu as HeadlessMenu } from "@headlessui/react"

import { MenuItems } from "./MenuItems"
import { MenuTransition } from "./MenuTransition"
import { MenuPosition } from "./types"

type MenuProps = React.PropsWithChildren<{
  position: MenuPosition
  button: React.ReactNode
}>
export const Menu = ({ position, button, children }: MenuProps) => {
  return (
    <div className="flex h-full items-center">
      <HeadlessMenu as="div" className="relative inline-block h-full">
        <HeadlessMenu.Button className={"h-full"}>{button}</HeadlessMenu.Button>
        <MenuTransition>
          <MenuItems position={position}>{children}</MenuItems>
        </MenuTransition>
      </HeadlessMenu>
    </div>
  )
}

type MenuItemProps = React.PropsWithChildren<{
  onClick?: () => void
}>
const Item = React.forwardRef<HTMLButtonElement, MenuItemProps>(
  (props, ref) => {
    return (
      <button
        ref={ref}
        onClick={props.onClick}
        className="flex w-full items-center justify-start space-x-3 whitespace-nowrap bg-white py-3 px-4 hover:bg-gray-50"
      >
        {props.children}
      </button>
    )
  },
)

Menu.Item = Item
