import { FileRoutesByPath, useNavigate } from "@tanstack/react-router"

type Breadcrumb = {
  route?: keyof FileRoutesByPath
  params?: Record<string, string>
  text: string
}

type BreadcrumbsProps = {
  crumbs: [Breadcrumb, ...Breadcrumb[]]
}

export const Breadcrumbs = ({ crumbs }: BreadcrumbsProps) => {
  const lastIndex = crumbs.length - 1
  const navigate = useNavigate()
  return (
    <div className="flex flex-row space-x-3">
      {crumbs.map(({ text, route, params }, index) => {
        return (
          <div key={text} className="flex flex-row space-x-3">
            {route ? (
              <>
                <button
                  className="font-normal text-gray-300 text-2xl hover:text-gray-500"
                  onClick={() =>
                    navigate({
                      to: route,
                      params,
                    })
                  }
                >
                  {text}
                </button>
                {index < lastIndex && (
                  <h1 className="font-normal text-gray-300 text-2xl">/</h1>
                )}
              </>
            ) : (
              <>
                <h1 className="font-normal text-gray-500 text-2xl">{text}</h1>
                {index < lastIndex && (
                  <h1 className="font-normal text-gray-300 text-2xl">/</h1>
                )}
              </>
            )}
          </div>
        )
      })}
    </div>
  )
}
