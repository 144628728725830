import clsx from "clsx"
import { MessageDescriptor, defineMessage, useIntl } from "react-intl"

import { GlowFlexbox, GlowIcon, GlowIconName, GlowText } from "src/glow"
import { CheckedRelayEnum, handleFutureValueOnRelayEnum } from "src/utils"

import { UserNotificationPriority } from "./__generated__/UserNotification_notification.graphql"

const priorityTagRecord: Record<
  CheckedRelayEnum<UserNotificationPriority>,
  {
    textColor: string
    iconName?: GlowIconName
    label: MessageDescriptor
  }
> = {
  URGENT: {
    textColor: "text-angsty-red-500",
    iconName: "alert_triangle_bold",
    label: defineMessage({
      id: "userNotification.priority.label.urgent",
      defaultMessage: "Urgent",
    }),
  },
  IMPORTANT: {
    textColor: `text-nous-glow-600`,
    iconName: "alert_circle_bold",
    label: defineMessage({
      id: "userNotification.priority.label.important",
      defaultMessage: "Priority",
    }),
  },
  GENERIC: {
    textColor: `text-gray-200`,
    label: defineMessage({
      id: "userNotification.priority.label.generic",
      defaultMessage: "Generic",
    }),
  },
}

export default function NotificationPriority(props: {
  priority: UserNotificationPriority
}) {
  const options =
    priorityTagRecord[handleFutureValueOnRelayEnum(props.priority, "GENERIC")]
  const intl = useIntl()
  return (
    <GlowFlexbox as="span" gap="1" alignItems="center" justifyContent="center">
      {options.iconName && (
        <GlowIcon
          name={options.iconName}
          className={clsx("w-3 h-3 flex-shrink-0", options.textColor)}
        />
      )}
      <GlowText size="xs" fontWeight="medium" className={options.textColor}>
        {intl.formatMessage(options.label)}
      </GlowText>
    </GlowFlexbox>
  )
}
