import { createFileRoute, lazyRouteComponent } from "@tanstack/react-router"

import requireIsLoggedIn from "src/routeUtils/requireIsLoggedIn"

export const Route = createFileRoute("/tools/energy-forecast")({
  beforeLoad: requireIsLoggedIn({ redirectTo: "/login" }),
  component: lazyRouteComponent(
    () =>
      import("src/pages/Tools/EnergyForecast2023Page/EnergyForecast2023Page"),
  ),
})
