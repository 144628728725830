import { MessageDescriptor, defineMessage } from "react-intl"

import { ServiceSummaryTypename } from "../__types__/ServiceSummaryTypename"

export const serviceSummaryTypenameToServiceNameRecord: Record<
  ServiceSummaryTypename,
  MessageDescriptor
> = {
  BroadbandServiceSummary: defineMessage({
    id: "serviceDetails.serviceName.broadband",
    defaultMessage: "Broadband",
  }),
  CouncilTaxServiceSummary: defineMessage({
    id: "serviceDetails.serviceName.councilTax",
    defaultMessage: "Council Tax",
  }),
  CarInsuranceServiceSummary: defineMessage({
    id: "serviceDetails.serviceName.carInsurance",
    defaultMessage: "Car Insurance",
  }),
  EnergyServiceSummary: defineMessage({
    id: "serviceDetails.serviceName.energy",
    defaultMessage: "Energy",
  }),
  HomeInsuranceServiceSummary: defineMessage({
    id: "serviceDetails.serviceName.homeInsurance",
    defaultMessage: "Home Insurance",
  }),
  MortgageServiceSummary: defineMessage({
    id: "serviceDetails.serviceName.mortgage",
    defaultMessage: "Mortgage",
  }),
  MobileServiceSummary: defineMessage({
    id: "serviceDetails.serviceName.mobile",
    defaultMessage: "Mobile",
  }),
  TelevisionServiceSummary: defineMessage({
    id: "serviceDetails.serviceName.television",
    defaultMessage: "Television",
  }),
  WaterServiceSummary: defineMessage({
    id: "serviceDetails.serviceName.water",
    defaultMessage: "Water",
  }),
}
