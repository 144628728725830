import { MutableRefObject, useCallback, useContext } from "react"
import React from "react"

import {
  Environment,
  fetchQuery,
  graphql,
  useRelayEnvironment,
} from "react-relay"
import Statsig, { DynamicConfig, EvaluationReason } from "statsig-js"
import * as uuid from "uuid"
import { ZodSchema } from "zod"

import { GateQuery } from "./__generated__/GateQuery.graphql"
import { User, useUser } from "./auth"
import { logger } from "./logger"

type StatsigState =
  | {
      status: "INITIALIZING"
      promise: Promise<unknown>
    }
  | {
      status: "READY"
    }
  | {
      status: "ERROR"
      error: Error
    }

type GateProviderProps = {
  children: React.ReactNode
}

// @ts-expect-error
window.Statsig = Statsig

const StatsigContext = React.createContext<{
  current: StatsigState
} | null>(null)

const STABLE_ID =
  localStorage.getItem("STATSIG_LOCAL_STORAGE_STABLE_ID") ?? uuid.v4()
localStorage.setItem("STATSIG_LOCAL_STORAGE_STABLE_ID", STABLE_ID)

const CLIENT_KEY = __STATSIG_CLIENT_API_KEY__ || null

let cachedStatsig: {
  key: string
  value: MutableRefObject<StatsigState>
} | null = null

function buildUtmQueryParameterCustomProperties() {
  const searchParams = new URLSearchParams(window.location.search)
  const utmSource = searchParams.get("utm_source")
  const utmMedium = searchParams.get("utm_medium")
  const utmCampaign = searchParams.get("utm_campaign")
  const cohort = searchParams.get("cohort")
  const utmTerm = searchParams.get("utm_term")
  const utmContent = searchParams.get("utm_content")
  return [
    { key: "utm_source", value: utmSource },
    { key: "utm_medium", value: utmMedium },
    { key: "utm_campaign", value: utmCampaign },
    { key: "cohort", value: cohort },
    { key: "utm_term", value: utmTerm },
    { key: "utm_content", value: utmContent },
  ].filter(({ value }) => value != null)
}

let initialized = false

async function initializeOrUpdateUser({
  clientKey,
  userId,
  userEmail,
  custom,
}: {
  clientKey: string
  userId?: string
  userEmail?: string
  custom?: Record<string, string>
}) {
  const user =
    userId == null
      ? {
          custom,
        }
      : {
          userID: userId,
          email: userEmail,
          custom,
        }
  const environment = {
    tier: __NOUS_ENV__ || "development",
  }
  const overrideStableID = STABLE_ID
  if (initialized) {
    logger.debug("Updating Statsig user", {
      userId,
      userEmail,
      custom,
      environment,
      overrideStableID,
    })
    await Statsig.updateUser(user)
    return
  }

  logger.debug("Initializing Statsig", {
    userId,
    userEmail,
    custom,
    environment,
    overrideStableID,
  })

  await Statsig.initialize(clientKey, user, {
    environment,
    overrideStableID,
  })
  initialized = true
}

async function initialize({
  user,
  clientKey,
  relayEnvironment,
}: {
  clientKey: string
  relayEnvironment: Environment
  user: User | null
}) {
  const data =
    user == null
      ? null
      : await fetchQuery<GateQuery>(
          relayEnvironment,
          graphql`
            query GateQuery {
              currentUser {
                id
                email
                statsigMetadata {
                  customProperties {
                    key
                    value
                  }
                }
              }
            }
          `,
          {},
        ).toPromise()
  const userId = user?.id
  const userEmail = data?.currentUser?.email ?? user?.email ?? undefined
  const customPropertiesArray = [
    ...buildUtmQueryParameterCustomProperties(),
    ...(data?.currentUser?.statsigMetadata?.customProperties ?? []),
  ]
  const custom = customPropertiesArray.reduce((acc, { key, value }) => {
    if (key && value) {
      return { ...acc, [key]: value }
    }
    return acc
  }, {})

  await initializeOrUpdateUser({
    clientKey,
    userId,
    userEmail,
    custom,
  })
}

type BuildStateOptions = { relayEnvironment: Environment }

function buildState(
  currentUser: User | null,
  options: BuildStateOptions,
): MutableRefObject<StatsigState> {
  const userId = currentUser?.id ?? null
  if (CLIENT_KEY == null) {
    logger.debug("Statsig not initialized", { userId })
    return {
      current: {
        status: "READY",
      },
    }
  }
  const result: MutableRefObject<StatsigState> = {
    current: {
      status: "INITIALIZING",
      promise: initialize({
        ...options,
        user: currentUser,
        clientKey: CLIENT_KEY,
      }).then(
        () => {
          logger.debug("Statsig initialized", { userId })
          result.current = {
            status: "READY",
          }
        },
        (error) => {
          logger.error("Statsig initialization failed", { userId, error })
          result.current = {
            status: "ERROR",
            error,
          }
        },
      ),
    },
  }
  return result
}

function buildStateMemoized(
  currentUser: User | null,
  options: BuildStateOptions,
): MutableRefObject<StatsigState> {
  const key = currentUser?.id ?? ""
  if (cachedStatsig?.key === key) {
    return cachedStatsig.value
  }
  const value = buildState(currentUser, options)
  cachedStatsig = { key, value }
  return value
}

export const GateProvider = ({ children }: GateProviderProps) => {
  const user = useUser()
  const relayEnvironment = useRelayEnvironment()
  const state = buildStateMemoized(user ?? null, { relayEnvironment })

  return (
    <StatsigContext.Provider value={state}>{children}</StatsigContext.Provider>
  )
}

const useWaitForStatsig = () => {
  const statsigState = useContext(StatsigContext)
  const state = statsigState?.current
  if (CLIENT_KEY == null) {
    return
  }
  if (state == null) {
    throw new Error(
      "Gate not initialized. Make sure you have wrapped your app in a <GateProvider />",
    )
  }
  if (state.status === "INITIALIZING") {
    throw state.promise
  }
  if (state.status === "ERROR") {
    throw state.error
  }
}

export function useCheckGate(): (gateName: string) => boolean {
  useWaitForStatsig()
  return useCallback((gateName) => {
    if (CLIENT_KEY == null) {
      logger.debug("Statsig not initialized")
      return false
    }
    const gate = Statsig.checkGate(gateName)
    logger.debug("Gate check", { gateName, gate })
    return gate
  }, [])
}

class Config {
  _config: DynamicConfig

  constructor(config: DynamicConfig) {
    this._config = config
  }

  getBoolean(name: string, defaultValue = false): boolean {
    if (CLIENT_KEY == null) {
      return defaultValue
    }
    return this._config.get(
      name,
      defaultValue,
      (v): v is boolean => typeof v === "boolean",
    )
  }
  getValue<T>(name: string, defaultValue: T, schema: ZodSchema<T>): T {
    if (CLIENT_KEY == null) {
      return defaultValue
    }
    const value = this._config.get(name, defaultValue)
    const parsed = schema.safeParse(value)
    if (parsed.success) {
      return parsed.data
    }
    return defaultValue
  }
}

export function useDynamicConfig(): (name: string) => Config {
  useWaitForStatsig()
  return useCallback((name) => {
    if (CLIENT_KEY == null) {
      logger.debug("Statsig not initialized")
      return new Config(
        new DynamicConfig(name, {}, "", {
          time: new Date().getTime(),
          reason: EvaluationReason.LocalOverride,
        }),
      )
    }
    const config = new Config(Statsig.getConfig(name))
    logger.debug("Dynamic config", { name, config })
    return config
  }, [])
}

export function getStableId() {
  return STABLE_ID
}
