import { createFileRoute, lazyRouteComponent } from "@tanstack/react-router"
import { loadQuery } from "react-relay"

import WaitlistPageQuery, {
  WaitlistPageQuery as WaitlistPageQueryType,
} from "src/pages/WaitlistPage/__generated__/WaitlistPageQuery.graphql"
import WaitlistPageLayout from "src/pages/WaitlistPage/WaitlistPageLayout"
import requireIsLoggedIn from "src/routeUtils/requireIsLoggedIn"

export const Route = createFileRoute("/waitlist")({
  beforeLoad: requireIsLoggedIn({ redirectTo: "/login" }),
  pendingComponent: WaitlistPageLayout,
  loader({ context }) {
    return loadQuery<WaitlistPageQueryType>(
      context.relayEnvironment(),
      WaitlistPageQuery,
      {},
    )
  },
  component: lazyRouteComponent(
    () => import("src/pages/WaitlistPage/WaitlistPage"),
  ),
})
