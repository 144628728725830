import clsx from "clsx"

type GlowLoadingDotsProps = {
  className?: string
}

export const GlowLoadingDots = ({ className }: GlowLoadingDotsProps) => {
  return (
    <div
      className={clsx(
        "loading-dots-animation flex items-center justify-center space-x-1 font-bold",
        className,
      )}
    >
      {/* eslint-disable-next-line formatjs/no-literal-string-in-jsx */}
      <span>.</span>
      {/* eslint-disable-next-line formatjs/no-literal-string-in-jsx */}
      <span>.</span>
      {/* eslint-disable-next-line formatjs/no-literal-string-in-jsx */}
      <span>.</span>
    </div>
  )
}
