import {
  ResponsiveMap,
  flexAlignItemsMap,
  flexDirectionsMap,
  flexJustifyContentMap,
  gapMap,
  marginBottomMap,
  marginLeftMap,
  marginMap,
  marginRightMap,
  marginTopMap,
  marginXMap,
  marginYMap,
  paddingBottomMap,
  paddingLeftMap,
  paddingMap,
  paddingRightMap,
  paddingTopMap,
  paddingXMap,
  paddingYMap,
} from "./classLookupMaps"
import {
  GlowBreakpoint,
  breakpointsList,
  isPropByBreakpoint,
  sortObjectKeys,
} from "./utils"

export const BASE_SPACING_PX = 4

export const glowSpacingList = [
  "0",
  "0.5",
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "8",
  "10",
  "12",
  "14",
  "16",
  "20",
  "24",
  "32",
] as const

export type GlowSpacingTokens = (typeof glowSpacingList)[number]

export type GlowFlexDirectionTokens =
  | "row"
  | "row-reverse"
  | "column"
  | "column-reverse"

export type GlowAlignItemsTokens =
  | "start"
  | "end"
  | "center"
  | "baseline"
  | "stretch"

export type GlowJustifyContentTokens =
  | "center"
  | "space-between"
  | "space-around"
  | "start"
  | "end"
  | "evenly"
  | "stretch"

type GlowSpacingProperties = {
  x?: GlowSpacingTokens
  y?: GlowSpacingTokens
  top?: GlowSpacingTokens
  bottom?: GlowSpacingTokens
  left?: GlowSpacingTokens
  right?: GlowSpacingTokens
}

export type GlowResponsiveSpacing =
  | GlowSpacingTokens
  | GlowSpacingProperties
  | Partial<Record<GlowBreakpoint, GlowSpacingTokens | GlowSpacingProperties>>

export type GlowResponsiveWrapper<PropValue> =
  | PropValue
  | Partial<Record<GlowBreakpoint, PropValue>>

type Type = "m" | "p"

const typeToMap: Record<Type, ResponsiveMap<string, string>> = {
  m: marginMap,
  p: paddingMap,
}

const typeToXMap: Record<Type, ResponsiveMap<string, string>> = {
  m: marginXMap,
  p: paddingXMap,
}

const typeToYMap: Record<Type, ResponsiveMap<string, string>> = {
  m: marginYMap,
  p: paddingYMap,
}

const typeToTopMap: Record<Type, ResponsiveMap<string, string>> = {
  m: marginTopMap,
  p: paddingTopMap,
}

const typeToBottomMap: Record<Type, ResponsiveMap<string, string>> = {
  m: marginBottomMap,
  p: paddingBottomMap,
}

const typeToLeftMap: Record<Type, ResponsiveMap<string, string>> = {
  m: marginLeftMap,
  p: paddingLeftMap,
}

const typeToRightMap: Record<Type, ResponsiveMap<string, string>> = {
  m: marginRightMap,
  p: paddingRightMap,
}

export function glowSpacingToClassNames(
  spacing: GlowResponsiveSpacing,
  type: Type,
) {
  const classNames: string[] = []

  function resolveSpacing(
    spacing: GlowResponsiveSpacing,
    breakpoint?: GlowBreakpoint,
  ) {
    if (typeof spacing === "string") {
      classNames.push(typeToMap[type][spacing])
    } else if (isPropByBreakpoint(spacing)) {
      const sortedSpacing = sortObjectKeys(spacing, breakpointsList)
      Object.entries(sortedSpacing).forEach(([breakpoint, value], index) => {
        if (typeof value === "string") {
          classNames.push(
            index === 0
              ? typeToMap[type][value]
              : typeToMap[type][`${breakpoint as GlowBreakpoint}:${value}`],
          )
        } else {
          resolveSpacing(
            value,
            index === 0 ? undefined : (breakpoint as GlowBreakpoint),
          )
        }
      })
    } else {
      if (spacing.x) {
        classNames.push(
          breakpoint
            ? typeToXMap[type][`${breakpoint}:${spacing.x}`]
            : typeToXMap[type][spacing.x],
        )
      }
      if (spacing.y) {
        classNames.push(
          breakpoint
            ? typeToYMap[type][`${breakpoint}:${spacing.y}`]
            : typeToYMap[type][spacing.y],
        )
      }
      if (spacing.top) {
        classNames.push(
          breakpoint
            ? typeToTopMap[type][`${breakpoint}:${spacing.top}`]
            : typeToTopMap[type][spacing.top],
        )
      }
      if (spacing.bottom) {
        classNames.push(
          breakpoint
            ? typeToBottomMap[type][`${breakpoint}:${spacing.bottom}`]
            : typeToBottomMap[type][spacing.bottom],
        )
      }
      if (spacing.left) {
        classNames.push(
          breakpoint
            ? typeToLeftMap[type][`${breakpoint}:${spacing.left}`]
            : typeToLeftMap[type][spacing.left],
        )
      }
      if (spacing.right) {
        classNames.push(
          breakpoint
            ? typeToRightMap[type][`${breakpoint}:${spacing.right}`]
            : typeToRightMap[type][spacing.right],
        )
      }
    }
  }

  resolveSpacing(spacing)
  return classNames
}

export function glowGapToClassNames(
  gap: GlowResponsiveWrapper<GlowSpacingTokens> | undefined,
) {
  const classNames: string[] = []

  if (typeof gap === "undefined") {
    return ["gap-0"]
  }

  if (typeof gap === "string") {
    return [`${gapMap[gap]}`]
  }

  const sortedGap = sortObjectKeys(gap, breakpointsList)
  Object.entries(sortedGap).forEach(([breakpoint, value], index) => {
    classNames.push(
      index === 0
        ? `${gapMap[value]}`
        : `${gapMap[`${breakpoint as GlowBreakpoint}:${value}`]}`,
    )
  })

  return classNames
}

export function glowFlexDirectionToClassNames(
  direction: GlowResponsiveWrapper<GlowFlexDirectionTokens> | undefined,
) {
  const classNames: string[] = []

  if (typeof direction === "undefined") {
    return ["flex-row"]
  }

  if (typeof direction === "string") {
    return [`${flexDirectionsMap[direction]}`]
  }

  const sortedDirections = sortObjectKeys(direction, breakpointsList) as Record<
    string,
    GlowFlexDirectionTokens
  >
  Object.entries(sortedDirections).forEach(([breakpoint, value], index) => {
    classNames.push(
      index === 0
        ? `${flexDirectionsMap[value]}`
        : `${flexDirectionsMap[`${breakpoint as GlowBreakpoint}:${value}`]}`,
    )
  })

  return classNames
}

export function glowAlignItemsToClassNames(
  direction: GlowResponsiveWrapper<GlowAlignItemsTokens> | undefined,
) {
  const classNames: string[] = []

  if (typeof direction === "undefined") {
    return []
  }

  if (typeof direction === "string") {
    return [`${flexAlignItemsMap[direction]}`]
  }

  const sortedDirections = sortObjectKeys(direction, breakpointsList) as Record<
    string,
    GlowAlignItemsTokens
  >
  Object.entries(sortedDirections).forEach(([breakpoint, value], index) => {
    classNames.push(
      index === 0
        ? `${flexAlignItemsMap[value]}`
        : `${flexAlignItemsMap[`${breakpoint as GlowBreakpoint}:${value}`]}`,
    )
  })

  return classNames
}

export function glowJustifyContentToClassNames(
  direction: GlowResponsiveWrapper<GlowJustifyContentTokens> | undefined,
) {
  const classNames: string[] = []

  if (typeof direction === "undefined") {
    return []
  }

  if (typeof direction === "string") {
    return [`${flexJustifyContentMap[direction]}`]
  }

  const sortedDirections = sortObjectKeys(direction, breakpointsList) as Record<
    string,
    GlowJustifyContentTokens
  >
  Object.entries(sortedDirections).forEach(([breakpoint, value], index) => {
    classNames.push(
      index === 0
        ? `${flexJustifyContentMap[value]}`
        : `${flexJustifyContentMap[`${breakpoint as GlowBreakpoint}:${value}`]}`,
    )
  })

  return classNames
}
