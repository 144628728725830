import { createFileRoute, lazyRouteComponent } from "@tanstack/react-router"
import { loadQuery } from "react-relay"

import ConnectWhatsAppPageQuery, {
  ConnectWhatsAppPageQuery as ConnectWhatsAppPageQueryType,
} from "src/pages/ConnectWhatsAppPage/__generated__/ConnectWhatsAppPageQuery.graphql"
import ConnectWhatsAppPageLayout from "src/pages/ConnectWhatsAppPage/ConnectWhatsAppPageLayout"
import requireIsLoggedIn from "src/routeUtils/requireIsLoggedIn"

export const Route = createFileRoute("/connect-whatsapp")({
  beforeLoad: requireIsLoggedIn({ redirectTo: "/login" }),
  pendingComponent: ConnectWhatsAppPageLayout,
  loader({ context }) {
    return loadQuery<ConnectWhatsAppPageQueryType>(
      context.relayEnvironment(),
      ConnectWhatsAppPageQuery,
      {},
    )
  },
  component: lazyRouteComponent(
    () => import("src/pages/ConnectWhatsAppPage/ConnectWhatsAppPage"),
  ),
})
