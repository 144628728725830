import * as t from "./definitions/tracker"
import * as u from "./definitions/types"
import { schema } from "./schema"

type SchemaDefinitions = (typeof schema)["definitions"]

export type EventObjects = u.EventObjects<SchemaDefinitions>

export type EventActions<TObject extends EventObjects> = u.EventActions<
  SchemaDefinitions,
  TObject
>

export type EventProperties<
  TObject extends EventObjects,
  TAction extends EventActions<TObject>,
> = u.EventProperties<SchemaDefinitions, TObject, TAction>

export type EventTuple<
  TObject extends EventObjects,
  TAction extends EventActions<TObject>,
> = u.EventTuple<SchemaDefinitions, TObject, TAction>

export function tracker<TOptions, TResult>(
  trackerFn: t.TrackerFn<TOptions, TResult>,
) {
  return t.tracker(schema, trackerFn)
}

export default schema

export * from "./schema"
