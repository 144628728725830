import React from "react"

import clsx from "clsx"

import { IconNames, iconRecord } from "./assets/icons"

export const glowIconNames = Object.keys(iconRecord) as IconNames[]

export type GlowIconName = IconNames

type GlowIconProps = {
  name: GlowIconName
} & React.SVGProps<SVGSVGElement>

export const GlowIcon = ({ name, ...props }: GlowIconProps) => {
  const { className, ...otherProps } = props

  const hasWidth = className?.includes("w-")
  const hasHeight = className?.includes("h-")

  return React.cloneElement(iconRecord[name], {
    className: clsx(
      {
        "w-6": !hasWidth,
        "h-6": !hasHeight,
      },
      className,
    ),
    ...otherProps,
  })
}

export const iconNameIsGlowIconName = (
  iconName: string,
): iconName is GlowIconName =>
  (iconRecord as Record<string, React.ReactElement>)[iconName] !== undefined
