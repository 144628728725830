import React from "react"

import { useNavigate } from "@tanstack/react-router"
import clsx from "clsx"
import { useIntl } from "react-intl"
import {
  PreloadedQuery,
  graphql,
  useFragment,
  useLazyLoadQuery,
  usePreloadedQuery,
  useQueryLoader,
} from "react-relay"

import { GlowFlexbox, GlowIcon, GlowLink, GlowText } from "src/glow"
import { Viewport, useViewport } from "src/hooks/responsive"
import useClickOutside from "src/hooks/useClickOutside"
import useEscListener from "src/hooks/useEscListener"

import { NotificationNav_household$key } from "./__generated__/NotificationNav_household.graphql"
import { NotificationNavNotificationListQuery as NotificationNavNotificationListQueryType } from "./__generated__/NotificationNavNotificationListQuery.graphql"
import NotificationNavNotificationListQuery from "./__generated__/NotificationNavNotificationListQuery.graphql"
import { NotificationNavQuery } from "./__generated__/NotificationNavQuery.graphql"

import { LoadingDots } from "../LoadingDots/LoadingDots"
import UserNotification from "../UserNotification/UserNotification"

function NotificationIcon(props: {
  hasUnreadNotifications?: boolean
  as?: React.ElementType
  onClick?: () => void
}) {
  const as = props.as ?? "div"
  return React.createElement(
    as,
    {
      className:
        "bg-white rounded-full grid place-items-center w-10 h-10 relative md:border md:border-off-black-12 cursor-pointer hover:border-off-black-20",

      onClick: props.onClick,
    },
    <>
      <span
        className={clsx(
          "absolute top-[8px] right-[8px] w-[12px] h-[12px] rounded-full bg-nous-glow-400 border-white border-2",
          props.hasUnreadNotifications ? "block" : "hidden",
        )}
      />
      <GlowIcon name="alarm_bell_regular" className="h-6 w-6" />
    </>,
  )
}

function NotificationList(props: { children?: React.ReactNode }) {
  const intl = useIntl()

  return (
    <div className="absolute top-[calc(100%+8px)] right-0 w-80 bg-white max-h-[472px] min-h-[200px] flex flex-col shadow overflow-scroll rounded-lg">
      <GlowFlexbox direction="column" grow={1}>
        <GlowText
          size="sm"
          fontWeight="medium"
          padding={{
            x: "4",
            y: "2",
          }}
        >
          {intl.formatMessage({
            defaultMessage: "Notifications",
            id: "nav.notifications.list.title",
          })}
        </GlowText>
        <GlowFlexbox direction="column" grow={1}>
          {props.children}
        </GlowFlexbox>
      </GlowFlexbox>
    </div>
  )
}

function NotificationListFetcher(props: {
  query: PreloadedQuery<NotificationNavNotificationListQueryType>
}) {
  const intl = useIntl()
  const navigate = useNavigate()
  const data = usePreloadedQuery<NotificationNavNotificationListQueryType>(
    graphql`
      query NotificationNavNotificationListQuery {
        maybeHousehold {
          userNotifications(first: 15) {
            edges {
              cursor
              node {
                ...UserNotification_notification
              }
            }
            pageInfo {
              hasNextPage
            }
          }
        }
      }
    `,
    props.query,
  )

  if (data.maybeHousehold?.userNotifications?.edges.length === 0) {
    return (
      <NotificationList>
        <GlowFlexbox
          justifyContent="center"
          alignItems="center"
          className="h-full"
          grow={1}
        >
          <GlowText size="sm" fontWeight="medium" className="text-off-black-64">
            {intl.formatMessage({
              defaultMessage: "No notifications yet",
              id: "nav.notifications.list.noNotifications",
            })}
          </GlowText>
        </GlowFlexbox>
      </NotificationList>
    )
  }
  return (
    <NotificationList>
      {data.maybeHousehold?.userNotifications?.edges.map((edge) => (
        <UserNotification
          key={edge.cursor}
          notification={edge.node}
          forceMobileSpacing
        />
      ))}
      <GlowFlexbox
        justifyContent="center"
        className="sticky bottom-0 bg-white py-4"
      >
        <GlowLink
          size="sm"
          onClick={() =>
            navigate({
              to: "/notifications",
            })
          }
          label={intl.formatMessage({
            defaultMessage: "View all",
            id: "nav.notifications.list.seeAll",
          })}
        />
      </GlowFlexbox>
    </NotificationList>
  )
}

function NotificationDropdown(props: { hasUnreadNotifications?: boolean }) {
  const [query, load, dispose] =
    useQueryLoader<NotificationNavNotificationListQueryType>(
      NotificationNavNotificationListQuery,
    )
  useEscListener(dispose)
  const ref = useClickOutside<HTMLDivElement>(dispose)

  return (
    <div className="relative" ref={ref}>
      <NotificationIcon
        hasUnreadNotifications={props.hasUnreadNotifications}
        onClick={() => {
          if (query) {
            dispose()
          } else {
            load({})
          }
        }}
      />
      {query && (
        <React.Suspense
          fallback={
            <NotificationList>
              <GlowFlexbox alignSelf="center" grow={1}>
                <LoadingDots />
              </GlowFlexbox>
            </NotificationList>
          }
        >
          <NotificationListFetcher query={query} />
        </React.Suspense>
      )}
    </div>
  )
}

function InternalNotificationNav() {
  const data = useLazyLoadQuery<NotificationNavQuery>(
    graphql`
      query NotificationNavQuery {
        household: maybeHousehold {
          ...NotificationNav_household
        }
      }
    `,
    {},
  )

  const household = useFragment<NotificationNav_household$key>(
    graphql`
      fragment NotificationNav_household on Household {
        userNotifications(first: 0, read: false) {
          totalCount
        }
      }
    `,
    data.household,
  )

  const hasUnreadNotifications =
    (household?.userNotifications?.totalCount ?? 0) > 0
  const isMobile = useViewport() < Viewport.MD
  const navigate = useNavigate()
  if (isMobile) {
    return (
      <NotificationIcon
        hasUnreadNotifications={hasUnreadNotifications}
        as="button"
        onClick={() =>
          navigate({
            to: "/notifications",
          })
        }
      />
    )
  }
  return (
    <NotificationDropdown hasUnreadNotifications={hasUnreadNotifications} />
  )
}

export default function NotificationNav() {
  return (
    <React.Suspense fallback={<NotificationIcon />}>
      <InternalNotificationNav />
    </React.Suspense>
  )
}
