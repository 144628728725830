import { useEffect } from "react"

export default function useRefreshIn({ days: timeoutDays }: { days: number }) {
  useEffect(() => {
    const timeout = setTimeout(
      () => {
        window.location.reload()
      },
      timeoutDays * 24 * 60 * 60 * 1000,
    )
    return () => clearTimeout(timeout)
  }, [timeoutDays])
}
