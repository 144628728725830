import React from "react"
import { useMemo } from "react"

export type UseModalReturnType = {
  isOpen: boolean
  open: () => void
  close: () => void
  toggle: () => void
}

export const useModal = (openInitially = false): UseModalReturnType => {
  const [isOpen, setIsOpen] = React.useState(openInitially)
  return useMemo(
    () => ({
      isOpen,
      open() {
        setIsOpen(true)
      },
      close() {
        setIsOpen(false)
      },
      toggle() {
        setIsOpen(!isOpen)
      },
    }),
    [isOpen],
  )
}

type UsePopupOptions = {
  openInitially?: boolean
}
export const usePopup = (options?: UsePopupOptions) =>
  useModal(options?.openInitially)
