import { Footer } from "src/components/Footer/Footer"
import MetaTags from "src/components/MetaTags/MetaTags"
import { DesktopNav } from "src/components/Nav/DesktopNav"
import { MobileTopNav } from "src/components/Nav/MobileTopNav"
import { GlowLayoutCentered } from "src/glow"
import { NavLayout } from "src/layouts/NavLayout/NavLayout"
import LoadingPagePlaceholder from "src/LoadingPagePlaceholder"

export default function YourHouseholdPageLayout(props: {
  children?: React.ReactNode
}) {
  return (
    <>
      <MetaTags title="Profile" />
      <NavLayout
        mobileNav={{
          top: (
            <MobileTopNav
              variant="secondary"
              title="Household settings"
              showBack
            />
          ),
        }}
        desktopNav={<DesktopNav />}
      >
        <GlowLayoutCentered className="pt-6 pb-8 !h-auto">
          {props.children ?? <LoadingPagePlaceholder />}
        </GlowLayoutCentered>

        <Footer>
          <Footer.Legal />
        </Footer>
      </NavLayout>
    </>
  )
}
