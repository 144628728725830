import { createFileRoute, lazyRouteComponent } from "@tanstack/react-router"
import { loadQuery } from "react-relay"

import InflationaryPayRiseCalculatorPageQuery, {
  InflationaryPayRiseCalculatorPageQuery as InflationaryPayRiseCalculatorPageQueryType,
} from "src/pages/Tools/InflationaryPayRiseCalculatorPage/__generated__/InflationaryPayRiseCalculatorPageQuery.graphql"
import requireIsLoggedIn from "src/routeUtils/requireIsLoggedIn"
import { DEFAULT_INFLATION } from "src/tools/InflationaryPayRiseCalculator/constants"

export const Route = createFileRoute("/tools/payrise")({
  beforeLoad: requireIsLoggedIn({ redirectTo: "/login" }),

  loader: ({ context }) =>
    loadQuery<InflationaryPayRiseCalculatorPageQueryType>(
      context.relayEnvironment(),
      InflationaryPayRiseCalculatorPageQuery,
      { inflation: DEFAULT_INFLATION },
    ),
  component: lazyRouteComponent(
    () =>
      import(
        "src/pages/Tools/InflationaryPayRiseCalculatorPage/InflationaryPayRiseCalculatorPage"
      ),
  ),
})
