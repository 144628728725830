type LogoProps = {
  className?: string
}

export const Logo = ({ className }: LogoProps) => (
  <svg
    viewBox="0 0 93 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M0.64 22H6.56V11.36C6.56 7.92 8.36 5.68 11.24 5.68C13.8 5.68 15.32 7.52 15.32 10.56V22H21.32V10.24C21.32 3.88 18.8 0.559999 13.6 0.559999C10.28 0.559999 7.96 1.96 6.56 4.2V1.2H0.64V22ZM35.0231 22.64C41.5431 22.64 46.1431 18.04 46.1431 11.56C46.1431 5.12 41.5431 0.559999 35.0231 0.559999C28.5031 0.559999 23.9431 5.12 23.9431 11.56C23.9431 18.04 28.5031 22.64 35.0231 22.64ZM35.0231 17.52C32.0231 17.52 29.9031 15.04 29.9031 11.56C29.9031 8.12 32.0231 5.68 35.0231 5.68C38.0631 5.68 40.1831 8.12 40.1831 11.56C40.1831 15.04 38.0631 17.52 35.0231 17.52ZM69.4816 1.2H63.5616V11.84C63.5616 15.28 61.7616 17.52 58.8816 17.52C56.3216 17.52 54.8016 15.68 54.8016 12.64V1.2H48.8016V12.96C48.8016 19.32 51.3216 22.64 56.5216 22.64C59.8416 22.64 62.1616 21.24 63.5616 19V22H69.4816V1.2ZM82.4247 22.64C88.4647 22.64 92.1047 20.08 92.1047 15.96C92.1047 6.92 78.8247 11.2 78.8247 7.16C78.8247 5.68 80.1047 4.68 82.3047 4.68C84.7047 4.68 86.1447 5.92 86.2647 7.96H92.1047C91.7447 3.36 88.2247 0.559999 82.6647 0.559999C76.5447 0.559999 72.8647 3.2 72.8647 7.4C72.8647 16.2 86.1847 11.92 86.1847 16.16C86.1847 17.4 84.8647 18.52 82.5847 18.52C80.1047 18.52 78.3047 17.36 78.2247 15.36H72.3447C72.4647 19.92 76.2647 22.64 82.4247 22.64Z"
      fill="#000000"
    />
  </svg>
)
