import { useCallback, useState } from "react"

export default function useCounter(initialValue = 0): {
  value: number
  increment: () => void
  decrement: () => void
  setValue: (value: number) => void
} {
  const [value, setValue] = useState(initialValue)
  const increment = useCallback(() => setValue((v) => v + 1), [])
  const decrement = useCallback(() => setValue((v) => v - 1), [])
  return {
    value,
    increment,
    decrement,
    setValue,
  }
}
