import React from "react"

import { Menu } from "@headlessui/react"
import { clsx } from "clsx"

import { MenuPosition } from "./types"

type MenuItemsProps = {
  position: MenuPosition
  children: React.ReactNode
}
export const MenuItems = React.forwardRef<HTMLDivElement, MenuItemsProps>(
  (props, ref) => (
    <Menu.Items
      ref={ref}
      className={clsx(
        "absolute rounded-md bg-white drop-shadow-md z-dropdown",
        props.position === "right" && "right-0 origin-top-right",
        props.position === "left" && "left-0 origin-top-left",
      )}
    >
      <div className="flex flex-col py-1">
        {React.Children.map(props.children, (child, key) => (
          <Menu.Item key={key}>{child}</Menu.Item>
        ))}
      </div>
    </Menu.Items>
  ),
)
