import React from "react"

import { Toast, ToastBar, Toaster, toast } from "react-hot-toast"

export const ToastNotifications = () => (
  <Toaster
    position="bottom-center"
    reverseOrder
    containerStyle={{
      left: 0,
      bottom: 0,
      right: 0,
    }}
    toastOptions={{
      duration: 3000,
      style: {
        maxWidth: "100%",
        color: "#fff",
        fontWeight: 500,
      },
      icon: null,
      success: {
        style: {
          background: "#5DAF91",
        },
      },
      error: {
        style: {
          background: "#F76D52",
        },
      },
    }}
  >
    {(toast: Toast) => <ToastNotification toast={toast} />}
  </Toaster>
)

export const notify = toast

type ToastNotificationProps = {
  toast: Toast
}

const ToastNotification = ({ toast }: ToastNotificationProps) => {
  function dismiss() {
    notify.dismiss(toast.id)
  }
  return (
    <div
      className="z-notification w-full cursor-pointer"
      onClick={dismiss}
      onKeyDown={dismiss}
      role="button"
      tabIndex={-1}
    >
      <ToastBar toast={toast} style={{ margin: 0, borderRadius: 0 }} />
    </div>
  )
}
