import { useCallback } from "react"

import { Options } from "@segment/analytics-next"

import { useUser } from "src/auth"
import { getStableId } from "src/Gate"

import { buildIntegrations } from "./buildIntegrations"
import { useAnalytics } from "./useAnalytics"

type Track = (
  event: string,
  data?: object,
  options?: Options,
) => Promise<unknown>

const consoleTrack = (event: string, data?: object, options?: Options) => {
  type Args = { event: string; data?: object; options?: Options }
  const args: Args = {
    event,
  }
  if (data !== undefined) {
    args.data = data
  }
  if (options !== undefined) {
    args.options = options
  }
  // eslint-disable-next-line no-console
  console.debug("%cTRACK", "font-weight: bold; color: darkCyan", args)
}

export default function useTrackingInternal({
  path,
}: {
  path?: string | undefined
} = {}): Track {
  const analytics = useAnalytics()
  const user = useUser()
  const track = useCallback(
    async (event: string, data?: object, options?: Options) => {
      const resolvedExperiments = {}

      const fullData =
        data !== undefined
          ? {
              path,
              experiments: resolvedExperiments,
              ...data,
              statsigCustomIDs: ["stableID", getStableId()],
              ...(user?.email && { email: user.email }),
            }
          : { path, experiments: resolvedExperiments, stableID: getStableId() }

      // eslint-disable-next-line @nous/valid-tracking
      await analytics?.track(event, fullData, {
        ...options,
        integrations: buildIntegrations(),
      })
      consoleTrack(event, fullData, options)
    },
    [path, analytics, user],
  )
  return track
}
