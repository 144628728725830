import { createFileRoute, lazyRouteComponent } from "@tanstack/react-router"
import { loadQuery } from "react-relay"

import requireIsLoggedIn from "src/routeUtils/requireIsLoggedIn"
import MobilePriceHikeCheckerQuery, {
  MobilePriceHikeCheckerQuery as MobilePriceHikeCheckerQueryType,
} from "src/tools/MobilePriceHikeChecker/__generated__/MobilePriceHikeCheckerQuery.graphql"

export const Route = createFileRoute("/tools/mobile-hikes")({
  beforeLoad: requireIsLoggedIn({ redirectTo: "/login" }),
  loader: ({ context }) =>
    loadQuery<MobilePriceHikeCheckerQueryType>(
      context.relayEnvironment(),
      MobilePriceHikeCheckerQuery,
      {},
    ),
  component: lazyRouteComponent(
    () =>
      import(
        "src/pages/Tools/MobilePriceHikeCheckerPage/MobilePriceHikeCheckerPage"
      ),
  ),
})
