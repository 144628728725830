import { useIntl } from "react-intl"

import MetaTags from "src/components/MetaTags/MetaTags"
import { DesktopNav } from "src/components/Nav/DesktopNav"
import { MobileTopNav } from "src/components/Nav/MobileTopNav"
import { NavLayout } from "src/layouts/NavLayout/NavLayout"
import LoadingPagePlaceholder from "src/LoadingPagePlaceholder"

export default function DocumentPageLayout(props: {
  children?: React.ReactNode
  customName?: string | null
  customMenu?: React.ReactElement
}) {
  const intl = useIntl()
  const pageTitle = intl.formatMessage({
    defaultMessage: "Document",
    id: "documentPage.pageTitle",
  })

  const pageDescription = intl.formatMessage({
    defaultMessage: "Document Page",
    id: "documentPage.pageDescription",
  })
  return (
    <>
      <MetaTags title={pageTitle} description={pageDescription} />
      <NavLayout
        mobileNav={{
          top: (
            <div className="bg-white">
              <MobileTopNav
                variant="secondary"
                title={
                  props.customName ??
                  intl.formatMessage({
                    id: "documentPage.mobileNav.title.default",
                    defaultMessage: "Uploaded Document",
                  })
                }
                showBack
                customMenu={props.customMenu}
              />
            </div>
          ),
        }}
        desktopNav={<DesktopNav />}
      >
        {props.children ?? <LoadingPagePlaceholder />}
      </NavLayout>
    </>
  )
}
