import { useEffect } from "react"

export default function useClickOutsideRef<TElement extends HTMLElement>(
  ref: React.RefObject<TElement>,
  fn: () => void,
) {
  useEffect(() => {
    const handleClick = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        fn()
      }
    }
    document.addEventListener("click", handleClick)
    return () => document.removeEventListener("click", handleClick)
  }, [fn, ref])
}
