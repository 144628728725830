import { createFileRoute, lazyRouteComponent } from "@tanstack/react-router"
import { loadQuery } from "react-relay"
import { z } from "zod"

import OnboardingLayout from "src/pages/Onboarding/layouts/OnboardingLayout"
import OnboardingServiceLevelPageQuery, {
  OnboardingServiceLevelPageQuery as OnboardingServiceLevelPageQueryType,
} from "src/pages/Onboarding/OnboardingServiceLevelPage/__generated__/OnboardingServiceLevelPageQuery.graphql"
import requireIsLoggedIn from "src/routeUtils/requireIsLoggedIn"

const searchSchema = z.object({
  delegationContext: z.string().optional(),
})

export const Route = createFileRoute("/onboarding/service-level")({
  beforeLoad: requireIsLoggedIn({ redirectTo: "/onboarding/" }),
  validateSearch: searchSchema,
  pendingComponent: () => (
    <OnboardingLayout title={""}>
      <></>
    </OnboardingLayout>
  ),

  loader: ({ context }) =>
    loadQuery<OnboardingServiceLevelPageQueryType>(
      context.relayEnvironment(),
      OnboardingServiceLevelPageQuery,
      {},
    ),
  component: lazyRouteComponent(
    () =>
      import(
        "src/pages/Onboarding/OnboardingServiceLevelPage/OnboardingServiceLevelPage"
      ),
  ),
})
