import { createFileRoute, lazyRouteComponent } from "@tanstack/react-router"
import { loadQuery } from "react-relay"

import QuestPageQuery, {
  QuestPageQuery as QuestPageQueryType,
} from "src/pages/QuestPage/__generated__/QuestPageQuery.graphql"
import { QuestPageLayout } from "src/pages/QuestPage/components/QuestPageLayout"

import requireIsLoggedIn from "../routeUtils/requireIsLoggedIn"

export const Route = createFileRoute("/quests/$questId")({
  beforeLoad: requireIsLoggedIn({ redirectTo: "/login" }),
  pendingComponent: QuestPageLayout,
  loader: ({ context }) =>
    loadQuery<QuestPageQueryType>(
      context.relayEnvironment(),
      QuestPageQuery,
      {},
    ),
  component: lazyRouteComponent(() => import("src/pages/QuestPage/QuestPage")),
})
