import { useEffect, useState } from "react"

import { createId } from "@paralleldrive/cuid2"
import { useIntl } from "react-intl"
import { useMutation } from "react-relay"
import { graphql } from "relay-runtime"

import {
  GlowButton,
  GlowFlexbox,
  GlowForm,
  GlowFormInput,
  GlowFormTextArea,
} from "src/glow"
import useTracking from "src/tracking/useTracking"

import { InviteFormSendInviteMutation } from "./__generated__/InviteFormSendInviteMutation.graphql"

type InviteFormProps = {
  disableSubmit?: boolean
}

export function InviteForm(props: InviteFormProps) {
  const intl = useIntl()
  const track = useTracking()

  const [formState, setFormState] = useState({
    firstName: "",
    lastName: "",
    email: "",
    customMessage: "",
  })
  const [customMessageIsDirty, setCustomMessageIsDirty] = useState(false)

  useEffect(() => {
    if (customMessageIsDirty) {
      return
    }
    setFormState((prev) => ({
      ...prev,
      customMessage: intl.formatMessage(
        {
          id: "inviteForm.custom_message_input_default",
          defaultMessage:
            "Hi{nameWithSpace}, I'm setting up Nous for our household. I've invited you to join as you pay some of the bills. Please click to accept the invitation.",
        },
        {
          nameWithSpace: formState.firstName ? ` ${formState.firstName}` : "",
        },
      ),
    }))
  }, [formState.firstName, intl, customMessageIsDirty])

  const [sendInvite, sending] = useMutation<InviteFormSendInviteMutation>(
    graphql`
      mutation InviteFormSendInviteMutation(
        $input: CreateHouseholdMemberInviteInput!
      ) {
        sendHouseholdInviteV2(input: $input) {
          household {
            pendingInvites {
              id
              ...InviteModalPendingInvite_invite
              ...InviteModalContent_invites
            }
          }
        }
      }
    `,
  )

  const actuallySendInvite = async (
    email: string,
    firstName: string,
    lastName: string,
    customMessage?: string,
  ) => {
    const id = createId()
    const input = {
      id,
      firstName,
      lastName,
      email,
      customMessage,
    }

    track([
      "Invite",
      "Sent",
      {
        inviteId: id,
        email,
        firstName,
        lastName,
        customMessage: customMessage ?? null,
      },
    ])

    sendInvite({
      variables: { input },
    })
  }

  return (
    <GlowForm
      onSubmit={() =>
        actuallySendInvite(
          formState.email,
          formState.firstName,
          formState.lastName,
          formState.customMessage,
        )
      }
      value={formState}
      onChange={setFormState}
    >
      <GlowFlexbox direction="column" gap="4">
        <GlowFlexbox gap="2">
          <GlowFormInput
            className="grow"
            formKey="firstName"
            placeholder={intl.formatMessage({
              id: "inviteForm.firstName.placeholder",
              defaultMessage: "Jane",
            })}
            label={intl.formatMessage({
              id: "inviteForm.firstName.label",
              defaultMessage: "First name",
            })}
            getErrors={(value) => [
              !value &&
                intl.formatMessage({
                  id: "inviteForm.firstName.error",
                  defaultMessage: "Please enter your first name",
                }),
            ]}
          />
          <GlowFormInput
            className="grow"
            formKey="lastName"
            placeholder={intl.formatMessage({
              id: "inviteForm.lastName.placeholder",
              defaultMessage: "Doe",
            })}
            label={intl.formatMessage({
              id: "inviteForm.lastName.label",
              defaultMessage: "Last name",
            })}
            getErrors={(value) => [
              !value &&
                intl.formatMessage({
                  id: "inviteForm.lastName.error",
                  defaultMessage: "Please enter your last name",
                }),
            ]}
          />
        </GlowFlexbox>
        <GlowFormInput
          className="grow"
          formKey="email"
          placeholder={intl.formatMessage({
            id: "inviteForm.email.placeholder",
            defaultMessage: "email@domain.com",
          })}
          label={intl.formatMessage({
            id: "inviteForm.email.label",
            defaultMessage: "Email",
          })}
          type="email"
          getErrors={(value) =>
            !value.includes("@")
              ? [
                  intl.formatMessage({
                    id: "inviteForm.email.wrong.error",
                    defaultMessage: "Please enter a valid email address",
                  }),
                ]
              : []
          }
        />
        <GlowFormTextArea
          className="grow h-24 resize-none"
          formKey="customMessage"
          placeholder={intl.formatMessage(
            {
              id: "inviteForm.customMessage.defaultInputValue",
              defaultMessage:
                "Hi{nameWithSpace}, I'm setting up Nous for our household. I've invited you to join as you pay some of the bills. Please click to accept the invitation.",
            },
            {
              nameWithSpace: formState.firstName
                ? ` ${formState.firstName}`
                : "",
            },
          )}
          label={intl.formatMessage({
            id: "inviteForm.customMessage.label",
            defaultMessage: "Add a custom message (optional)",
          })}
          getIsDirty={(isDirty) => setCustomMessageIsDirty(isDirty)}
        />
        <GlowButton
          type="submit"
          label={intl.formatMessage({
            id: "inviteForm.submit",
            defaultMessage: "Invite",
          })}
          isLoading={props.disableSubmit || sending}
        />
      </GlowFlexbox>
    </GlowForm>
  )
}
