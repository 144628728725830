import {
  GlowAlignItemsTokens,
  GlowFlexDirectionTokens,
  GlowJustifyContentTokens,
  GlowSpacingTokens,
} from "./structure"
import { GlowBreakpoint } from "./utils"

type Responsive<T extends string> = T | `${GlowBreakpoint}:${T}`

export type ResponsiveMap<T extends string, P extends string> = Record<
  Responsive<T>,
  Responsive<`${P}-${T}`>
>

export const flexDirectionsMap: Record<
  Responsive<GlowFlexDirectionTokens>,
  string
> = {
  row: "flex-row",
  "row-reverse": "flex-row-reverse",
  column: "flex-col",
  "column-reverse": "flex-col-reverse",
  "xs:row": "xs:flex-row",
  "xs:row-reverse": "xs:flex-row-reverse",
  "xs:column": "xs:flex-col",
  "xs:column-reverse": "xs:flex-col-reverse",
  "sm:row": "sm:flex-row",
  "sm:row-reverse": "sm:flex-row-reverse",
  "sm:column": "sm:flex-col",
  "sm:column-reverse": "sm:flex-col-reverse",
  "md:row": "md:flex-row",
  "md:row-reverse": "md:flex-row-reverse",
  "md:column": "md:flex-col",
  "md:column-reverse": "md:flex-col-reverse",
  "lg:row": "lg:flex-row",
  "lg:row-reverse": "lg:flex-row-reverse",
  "lg:column": "lg:flex-col",
  "lg:column-reverse": "lg:flex-col-reverse",
  "xl:row": "xl:flex-row",
  "xl:row-reverse": "xl:flex-row-reverse",
  "xl:column": "xl:flex-col",
  "xl:column-reverse": "xl:flex-col-reverse",
  "xxl:row": "xxl:flex-row",
  "xxl:row-reverse": "xxl:flex-row-reverse",
  "xxl:column": "xxl:flex-col",
  "xxl:column-reverse": "xxl:flex-col-reverse",
}

export const flexAlignItemsMap: ResponsiveMap<GlowAlignItemsTokens, "items"> = {
  baseline: "items-baseline",
  center: "items-center",
  start: "items-start",
  end: "items-end",
  stretch: "items-stretch",
  "xs:baseline": "xs:items-baseline",
  "xs:center": "xs:items-center",
  "xs:start": "xs:items-start",
  "xs:end": "xs:items-end",
  "xs:stretch": "xs:items-stretch",
  "sm:baseline": "sm:items-baseline",
  "sm:center": "sm:items-center",
  "sm:start": "sm:items-start",
  "sm:end": "sm:items-end",
  "sm:stretch": "sm:items-stretch",
  "md:baseline": "md:items-baseline",
  "md:center": "md:items-center",
  "md:start": "md:items-start",
  "md:end": "md:items-end",
  "md:stretch": "md:items-stretch",
  "lg:baseline": "lg:items-baseline",
  "lg:center": "lg:items-center",
  "lg:start": "lg:items-start",
  "lg:end": "lg:items-end",
  "lg:stretch": "lg:items-stretch",
  "xl:baseline": "xl:items-baseline",
  "xl:center": "xl:items-center",
  "xl:start": "xl:items-start",
  "xl:end": "xl:items-end",
  "xl:stretch": "xl:items-stretch",
  "xxl:baseline": "xxl:items-baseline",
  "xxl:center": "xxl:items-center",
  "xxl:start": "xxl:items-start",
  "xxl:end": "xxl:items-end",
  "xxl:stretch": "xxl:items-stretch",
}

export const flexJustifyContentMap: Record<
  Responsive<GlowJustifyContentTokens>,
  string
> = {
  center: "justify-center",
  "space-between": "justify-between",
  "space-around": "justify-around",
  start: "justify-start",
  end: "justify-end",
  evenly: "justify-evenly",
  stretch: "justify-stretch",
  "xs:center": "xs:justify-center",
  "xs:space-between": "xs:justify-between",
  "xs:space-around": "xs:justify-around",
  "xs:start": "xs:justify-start",
  "xs:end": "xs:justify-end",
  "xs:evenly": "xs:justify-evenly",
  "xs:stretch": "xs:justify-stretch",
  "sm:center": "sm:justify-center",
  "sm:space-between": "sm:justify-between",
  "sm:space-around": "sm:justify-around",
  "sm:start": "sm:justify-start",
  "sm:end": "sm:justify-end",
  "sm:evenly": "sm:justify-evenly",
  "sm:stretch": "sm:justify-stretch",
  "md:center": "md:justify-center",
  "md:space-between": "md:justify-between",
  "md:space-around": "md:justify-around",
  "md:start": "md:justify-start",
  "md:end": "md:justify-end",
  "md:evenly": "md:justify-evenly",
  "md:stretch": "md:justify-stretch",
  "lg:center": "lg:justify-center",
  "lg:space-between": "lg:justify-between",
  "lg:space-around": "lg:justify-around",
  "lg:start": "lg:justify-start",
  "lg:end": "lg:justify-end",
  "lg:evenly": "lg:justify-evenly",
  "lg:stretch": "lg:justify-stretch",
  "xl:center": "xl:justify-center",
  "xl:space-between": "xl:justify-between",
  "xl:space-around": "xl:justify-around",
  "xl:start": "xl:justify-start",
  "xl:end": "xl:justify-end",
  "xl:evenly": "xl:justify-evenly",
  "xl:stretch": "xl:justify-stretch",
  "xxl:center": "xxl:justify-center",
  "xxl:space-between": "xxl:justify-between",
  "xxl:space-around": "xxl:justify-around",
  "xxl:start": "xxl:justify-start",
  "xxl:end": "xxl:justify-end",
  "xxl:evenly": "xxl:justify-evenly",
  "xxl:stretch": "xxl:justify-stretch",
}

export const gapMap: ResponsiveMap<GlowSpacingTokens, "gap"> = {
  "0": "gap-0",
  "0.5": "gap-0.5",
  "1": "gap-1",
  "2": "gap-2",
  "3": "gap-3",
  "4": "gap-4",
  "5": "gap-5",
  "6": "gap-6",
  "8": "gap-8",
  "10": "gap-10",
  "12": "gap-12",
  "14": "gap-14",
  "16": "gap-16",
  "20": "gap-20",
  "24": "gap-24",
  "32": "gap-32",
  "xs:0": "xs:gap-0",
  "xs:0.5": "xs:gap-0.5",
  "xs:1": "xs:gap-1",
  "xs:2": "xs:gap-2",
  "xs:3": "xs:gap-3",
  "xs:4": "xs:gap-4",
  "xs:5": "xs:gap-5",
  "xs:6": "xs:gap-6",
  "xs:8": "xs:gap-8",
  "xs:10": "xs:gap-10",
  "xs:12": "xs:gap-12",
  "xs:14": "xs:gap-14",
  "xs:16": "xs:gap-16",
  "xs:20": "xs:gap-20",
  "xs:24": "xs:gap-24",
  "xs:32": "xs:gap-32",
  "sm:0": "sm:gap-0",
  "sm:0.5": "sm:gap-0.5",
  "sm:1": "sm:gap-1",
  "sm:2": "sm:gap-2",
  "sm:3": "sm:gap-3",
  "sm:4": "sm:gap-4",
  "sm:5": "sm:gap-5",
  "sm:6": "sm:gap-6",
  "sm:8": "sm:gap-8",
  "sm:10": "sm:gap-10",
  "sm:12": "sm:gap-12",
  "sm:14": "sm:gap-14",
  "sm:16": "sm:gap-16",
  "sm:20": "sm:gap-20",
  "sm:24": "sm:gap-24",
  "sm:32": "sm:gap-32",
  "md:0": "md:gap-0",
  "md:0.5": "md:gap-0.5",
  "md:1": "md:gap-1",
  "md:2": "md:gap-2",
  "md:3": "md:gap-3",
  "md:4": "md:gap-4",
  "md:5": "md:gap-5",
  "md:6": "md:gap-6",
  "md:8": "md:gap-8",
  "md:10": "md:gap-10",
  "md:12": "md:gap-12",
  "md:14": "md:gap-14",
  "md:16": "md:gap-16",
  "md:20": "md:gap-20",
  "md:24": "md:gap-24",
  "md:32": "md:gap-32",
  "lg:0": "lg:gap-0",
  "lg:0.5": "lg:gap-0.5",
  "lg:1": "lg:gap-1",
  "lg:2": "lg:gap-2",
  "lg:3": "lg:gap-3",
  "lg:4": "lg:gap-4",
  "lg:5": "lg:gap-5",
  "lg:6": "lg:gap-6",
  "lg:8": "lg:gap-8",
  "lg:10": "lg:gap-10",
  "lg:12": "lg:gap-12",
  "lg:14": "lg:gap-14",
  "lg:16": "lg:gap-16",
  "lg:20": "lg:gap-20",
  "lg:24": "lg:gap-24",
  "lg:32": "lg:gap-32",
  "xl:0": "xl:gap-0",
  "xl:0.5": "xl:gap-0.5",
  "xl:1": "xl:gap-1",
  "xl:2": "xl:gap-2",
  "xl:3": "xl:gap-3",
  "xl:4": "xl:gap-4",
  "xl:5": "xl:gap-5",
  "xl:6": "xl:gap-6",
  "xl:8": "xl:gap-8",
  "xl:10": "xl:gap-10",
  "xl:12": "xl:gap-12",
  "xl:14": "xl:gap-14",
  "xl:16": "xl:gap-16",
  "xl:20": "xl:gap-20",
  "xl:24": "xl:gap-24",
  "xl:32": "xl:gap-32",
  "xxl:0": "xxl:gap-0",
  "xxl:0.5": "xxl:gap-0.5",
  "xxl:1": "xxl:gap-1",
  "xxl:2": "xxl:gap-2",
  "xxl:3": "xxl:gap-3",
  "xxl:4": "xxl:gap-4",
  "xxl:5": "xxl:gap-5",
  "xxl:6": "xxl:gap-6",
  "xxl:8": "xxl:gap-8",
  "xxl:10": "xxl:gap-10",
  "xxl:12": "xxl:gap-12",
  "xxl:14": "xxl:gap-14",
  "xxl:16": "xxl:gap-16",
  "xxl:20": "xxl:gap-20",
  "xxl:24": "xxl:gap-24",
  "xxl:32": "xxl:gap-32",
}

export const paddingMap: ResponsiveMap<GlowSpacingTokens, "p"> = {
  "0": "p-0",
  "0.5": "p-0.5",
  "1": "p-1",
  "2": "p-2",
  "3": "p-3",
  "4": "p-4",
  "5": "p-5",
  "6": "p-6",
  "8": "p-8",
  "10": "p-10",
  "12": "p-12",
  "14": "p-14",
  "16": "p-16",
  "20": "p-20",
  "24": "p-24",
  "32": "p-32",
  "xs:0": "xs:p-0",
  "xs:0.5": "xs:p-0.5",
  "xs:1": "xs:p-1",
  "xs:2": "xs:p-2",
  "xs:3": "xs:p-3",
  "xs:4": "xs:p-4",
  "xs:5": "xs:p-5",
  "xs:6": "xs:p-6",
  "xs:8": "xs:p-8",
  "xs:10": "xs:p-10",
  "xs:12": "xs:p-12",
  "xs:14": "xs:p-14",
  "xs:16": "xs:p-16",
  "xs:20": "xs:p-20",
  "xs:24": "xs:p-24",
  "xs:32": "xs:p-32",
  "sm:0": "sm:p-0",
  "sm:0.5": "sm:p-0.5",
  "sm:1": "sm:p-1",
  "sm:2": "sm:p-2",
  "sm:3": "sm:p-3",
  "sm:4": "sm:p-4",
  "sm:5": "sm:p-5",
  "sm:6": "sm:p-6",
  "sm:8": "sm:p-8",
  "sm:10": "sm:p-10",
  "sm:12": "sm:p-12",
  "sm:14": "sm:p-14",
  "sm:16": "sm:p-16",
  "sm:20": "sm:p-20",
  "sm:24": "sm:p-24",
  "sm:32": "sm:p-32",
  "md:0": "md:p-0",
  "md:0.5": "md:p-0.5",
  "md:1": "md:p-1",
  "md:2": "md:p-2",
  "md:3": "md:p-3",
  "md:4": "md:p-4",
  "md:5": "md:p-5",
  "md:6": "md:p-6",
  "md:8": "md:p-8",
  "md:10": "md:p-10",
  "md:12": "md:p-12",
  "md:14": "md:p-14",
  "md:16": "md:p-16",
  "md:20": "md:p-20",
  "md:24": "md:p-24",
  "md:32": "md:p-32",
  "lg:0": "lg:p-0",
  "lg:0.5": "lg:p-0.5",
  "lg:1": "lg:p-1",
  "lg:2": "lg:p-2",
  "lg:3": "lg:p-3",
  "lg:4": "lg:p-4",
  "lg:5": "lg:p-5",
  "lg:6": "lg:p-6",
  "lg:8": "lg:p-8",
  "lg:10": "lg:p-10",
  "lg:12": "lg:p-12",
  "lg:14": "lg:p-14",
  "lg:16": "lg:p-16",
  "lg:20": "lg:p-20",
  "lg:24": "lg:p-24",
  "lg:32": "lg:p-32",
  "xl:0": "xl:p-0",
  "xl:0.5": "xl:p-0.5",
  "xl:1": "xl:p-1",
  "xl:2": "xl:p-2",
  "xl:3": "xl:p-3",
  "xl:4": "xl:p-4",
  "xl:5": "xl:p-5",
  "xl:6": "xl:p-6",
  "xl:8": "xl:p-8",
  "xl:10": "xl:p-10",
  "xl:12": "xl:p-12",
  "xl:14": "xl:p-14",
  "xl:16": "xl:p-16",
  "xl:20": "xl:p-20",
  "xl:24": "xl:p-24",
  "xl:32": "xl:p-32",
  "xxl:0": "xxl:p-0",
  "xxl:0.5": "xxl:p-0.5",
  "xxl:1": "xxl:p-1",
  "xxl:2": "xxl:p-2",
  "xxl:3": "xxl:p-3",
  "xxl:4": "xxl:p-4",
  "xxl:5": "xxl:p-5",
  "xxl:6": "xxl:p-6",
  "xxl:8": "xxl:p-8",
  "xxl:10": "xxl:p-10",
  "xxl:12": "xxl:p-12",
  "xxl:14": "xxl:p-14",
  "xxl:16": "xxl:p-16",
  "xxl:20": "xxl:p-20",
  "xxl:24": "xxl:p-24",
  "xxl:32": "xxl:p-32",
}

export const marginMap: ResponsiveMap<GlowSpacingTokens, "m"> = {
  "0": "m-0",
  "0.5": "m-0.5",
  "1": "m-1",
  "2": "m-2",
  "3": "m-3",
  "4": "m-4",
  "5": "m-5",
  "6": "m-6",
  "8": "m-8",
  "10": "m-10",
  "12": "m-12",
  "14": "m-14",
  "16": "m-16",
  "20": "m-20",
  "24": "m-24",
  "32": "m-32",
  "xs:0": "xs:m-0",
  "xs:0.5": "xs:m-0.5",
  "xs:1": "xs:m-1",
  "xs:2": "xs:m-2",
  "xs:3": "xs:m-3",
  "xs:4": "xs:m-4",
  "xs:5": "xs:m-5",
  "xs:6": "xs:m-6",
  "xs:8": "xs:m-8",
  "xs:10": "xs:m-10",
  "xs:12": "xs:m-12",
  "xs:14": "xs:m-14",
  "xs:16": "xs:m-16",
  "xs:20": "xs:m-20",
  "xs:24": "xs:m-24",
  "xs:32": "xs:m-32",
  "sm:0": "sm:m-0",
  "sm:0.5": "sm:m-0.5",
  "sm:1": "sm:m-1",
  "sm:2": "sm:m-2",
  "sm:3": "sm:m-3",
  "sm:4": "sm:m-4",
  "sm:5": "sm:m-5",
  "sm:6": "sm:m-6",
  "sm:8": "sm:m-8",
  "sm:10": "sm:m-10",
  "sm:12": "sm:m-12",
  "sm:14": "sm:m-14",
  "sm:16": "sm:m-16",
  "sm:20": "sm:m-20",
  "sm:24": "sm:m-24",
  "sm:32": "sm:m-32",
  "md:0": "md:m-0",
  "md:0.5": "md:m-0.5",
  "md:1": "md:m-1",
  "md:2": "md:m-2",
  "md:3": "md:m-3",
  "md:4": "md:m-4",
  "md:5": "md:m-5",
  "md:6": "md:m-6",
  "md:8": "md:m-8",
  "md:10": "md:m-10",
  "md:12": "md:m-12",
  "md:14": "md:m-14",
  "md:16": "md:m-16",
  "md:20": "md:m-20",
  "md:24": "md:m-24",
  "md:32": "md:m-32",
  "lg:0": "lg:m-0",
  "lg:0.5": "lg:m-0.5",
  "lg:1": "lg:m-1",
  "lg:2": "lg:m-2",
  "lg:3": "lg:m-3",
  "lg:4": "lg:m-4",
  "lg:5": "lg:m-5",
  "lg:6": "lg:m-6",
  "lg:8": "lg:m-8",
  "lg:10": "lg:m-10",
  "lg:12": "lg:m-12",
  "lg:14": "lg:m-14",
  "lg:16": "lg:m-16",
  "lg:20": "lg:m-20",
  "lg:24": "lg:m-24",
  "lg:32": "lg:m-32",
  "xl:0": "xl:m-0",
  "xl:0.5": "xl:m-0.5",
  "xl:1": "xl:m-1",
  "xl:2": "xl:m-2",
  "xl:3": "xl:m-3",
  "xl:4": "xl:m-4",
  "xl:5": "xl:m-5",
  "xl:6": "xl:m-6",
  "xl:8": "xl:m-8",
  "xl:10": "xl:m-10",
  "xl:12": "xl:m-12",
  "xl:14": "xl:m-14",
  "xl:16": "xl:m-16",
  "xl:20": "xl:m-20",
  "xl:24": "xl:m-24",
  "xl:32": "xl:m-32",
  "xxl:0": "xxl:m-0",
  "xxl:0.5": "xxl:m-0.5",
  "xxl:1": "xxl:m-1",
  "xxl:2": "xxl:m-2",
  "xxl:3": "xxl:m-3",
  "xxl:4": "xxl:m-4",
  "xxl:5": "xxl:m-5",
  "xxl:6": "xxl:m-6",
  "xxl:8": "xxl:m-8",
  "xxl:10": "xxl:m-10",
  "xxl:12": "xxl:m-12",
  "xxl:14": "xxl:m-14",
  "xxl:16": "xxl:m-16",
  "xxl:20": "xxl:m-20",
  "xxl:24": "xxl:m-24",
  "xxl:32": "xxl:m-32",
}

export const paddingXMap: ResponsiveMap<GlowSpacingTokens, "px"> = {
  "0": "px-0",
  "0.5": "px-0.5",
  "1": "px-1",
  "2": "px-2",
  "3": "px-3",
  "4": "px-4",
  "5": "px-5",
  "6": "px-6",
  "8": "px-8",
  "10": "px-10",
  "12": "px-12",
  "14": "px-14",
  "16": "px-16",
  "20": "px-20",
  "24": "px-24",
  "32": "px-32",
  "xs:0": "xs:px-0",
  "xs:0.5": "xs:px-0.5",
  "xs:1": "xs:px-1",
  "xs:2": "xs:px-2",
  "xs:3": "xs:px-3",
  "xs:4": "xs:px-4",
  "xs:5": "xs:px-5",
  "xs:6": "xs:px-6",
  "xs:8": "xs:px-8",
  "xs:10": "xs:px-10",
  "xs:12": "xs:px-12",
  "xs:14": "xs:px-14",
  "xs:16": "xs:px-16",
  "xs:20": "xs:px-20",
  "xs:24": "xs:px-24",
  "xs:32": "xs:px-32",
  "sm:0": "sm:px-0",
  "sm:0.5": "sm:px-0.5",
  "sm:1": "sm:px-1",
  "sm:2": "sm:px-2",
  "sm:3": "sm:px-3",
  "sm:4": "sm:px-4",
  "sm:5": "sm:px-5",
  "sm:6": "sm:px-6",
  "sm:8": "sm:px-8",
  "sm:10": "sm:px-10",
  "sm:12": "sm:px-12",
  "sm:14": "sm:px-14",
  "sm:16": "sm:px-16",
  "sm:20": "sm:px-20",
  "sm:24": "sm:px-24",
  "sm:32": "sm:px-32",
  "md:0": "md:px-0",
  "md:0.5": "md:px-0.5",
  "md:1": "md:px-1",
  "md:2": "md:px-2",
  "md:3": "md:px-3",
  "md:4": "md:px-4",
  "md:5": "md:px-5",
  "md:6": "md:px-6",
  "md:8": "md:px-8",
  "md:10": "md:px-10",
  "md:12": "md:px-12",
  "md:14": "md:px-14",
  "md:16": "md:px-16",
  "md:20": "md:px-20",
  "md:24": "md:px-24",
  "md:32": "md:px-32",
  "lg:0": "lg:px-0",
  "lg:0.5": "lg:px-0.5",
  "lg:1": "lg:px-1",
  "lg:2": "lg:px-2",
  "lg:3": "lg:px-3",
  "lg:4": "lg:px-4",
  "lg:5": "lg:px-5",
  "lg:6": "lg:px-6",
  "lg:8": "lg:px-8",
  "lg:10": "lg:px-10",
  "lg:12": "lg:px-12",
  "lg:14": "lg:px-14",
  "lg:16": "lg:px-16",
  "lg:20": "lg:px-20",
  "lg:24": "lg:px-24",
  "lg:32": "lg:px-32",
  "xl:0": "xl:px-0",
  "xl:0.5": "xl:px-0.5",
  "xl:1": "xl:px-1",
  "xl:2": "xl:px-2",
  "xl:3": "xl:px-3",
  "xl:4": "xl:px-4",
  "xl:5": "xl:px-5",
  "xl:6": "xl:px-6",
  "xl:8": "xl:px-8",
  "xl:10": "xl:px-10",
  "xl:12": "xl:px-12",
  "xl:14": "xl:px-14",
  "xl:16": "xl:px-16",
  "xl:20": "xl:px-20",
  "xl:24": "xl:px-24",
  "xl:32": "xl:px-32",
  "xxl:0": "xxl:px-0",
  "xxl:0.5": "xxl:px-0.5",
  "xxl:1": "xxl:px-1",
  "xxl:2": "xxl:px-2",
  "xxl:3": "xxl:px-3",
  "xxl:4": "xxl:px-4",
  "xxl:5": "xxl:px-5",
  "xxl:6": "xxl:px-6",
  "xxl:8": "xxl:px-8",
  "xxl:10": "xxl:px-10",
  "xxl:12": "xxl:px-12",
  "xxl:14": "xxl:px-14",
  "xxl:16": "xxl:px-16",
  "xxl:20": "xxl:px-20",
  "xxl:24": "xxl:px-24",
  "xxl:32": "xxl:px-32",
}

export const marginXMap: ResponsiveMap<GlowSpacingTokens, "mx"> = {
  "0": "mx-0",
  "0.5": "mx-0.5",
  "1": "mx-1",
  "2": "mx-2",
  "3": "mx-3",
  "4": "mx-4",
  "5": "mx-5",
  "6": "mx-6",
  "8": "mx-8",
  "10": "mx-10",
  "12": "mx-12",
  "14": "mx-14",
  "16": "mx-16",
  "20": "mx-20",
  "24": "mx-24",
  "32": "mx-32",
  "xs:0": "xs:mx-0",
  "xs:0.5": "xs:mx-0.5",
  "xs:1": "xs:mx-1",
  "xs:2": "xs:mx-2",
  "xs:3": "xs:mx-3",
  "xs:4": "xs:mx-4",
  "xs:5": "xs:mx-5",
  "xs:6": "xs:mx-6",
  "xs:8": "xs:mx-8",
  "xs:10": "xs:mx-10",
  "xs:12": "xs:mx-12",
  "xs:14": "xs:mx-14",
  "xs:16": "xs:mx-16",
  "xs:20": "xs:mx-20",
  "xs:24": "xs:mx-24",
  "xs:32": "xs:mx-32",
  "sm:0": "sm:mx-0",
  "sm:0.5": "sm:mx-0.5",
  "sm:1": "sm:mx-1",
  "sm:2": "sm:mx-2",
  "sm:3": "sm:mx-3",
  "sm:4": "sm:mx-4",
  "sm:5": "sm:mx-5",
  "sm:6": "sm:mx-6",
  "sm:8": "sm:mx-8",
  "sm:10": "sm:mx-10",
  "sm:12": "sm:mx-12",
  "sm:14": "sm:mx-14",
  "sm:16": "sm:mx-16",
  "sm:20": "sm:mx-20",
  "sm:24": "sm:mx-24",
  "sm:32": "sm:mx-32",
  "md:0": "md:mx-0",
  "md:0.5": "md:mx-0.5",
  "md:1": "md:mx-1",
  "md:2": "md:mx-2",
  "md:3": "md:mx-3",
  "md:4": "md:mx-4",
  "md:5": "md:mx-5",
  "md:6": "md:mx-6",
  "md:8": "md:mx-8",
  "md:10": "md:mx-10",
  "md:12": "md:mx-12",
  "md:14": "md:mx-14",
  "md:16": "md:mx-16",
  "md:20": "md:mx-20",
  "md:24": "md:mx-24",
  "md:32": "md:mx-32",
  "lg:0": "lg:mx-0",
  "lg:0.5": "lg:mx-0.5",
  "lg:1": "lg:mx-1",
  "lg:2": "lg:mx-2",
  "lg:3": "lg:mx-3",
  "lg:4": "lg:mx-4",
  "lg:5": "lg:mx-5",
  "lg:6": "lg:mx-6",
  "lg:8": "lg:mx-8",
  "lg:10": "lg:mx-10",
  "lg:12": "lg:mx-12",
  "lg:14": "lg:mx-14",
  "lg:16": "lg:mx-16",
  "lg:20": "lg:mx-20",
  "lg:24": "lg:mx-24",
  "lg:32": "lg:mx-32",
  "xl:0": "xl:mx-0",
  "xl:0.5": "xl:mx-0.5",
  "xl:1": "xl:mx-1",
  "xl:2": "xl:mx-2",
  "xl:3": "xl:mx-3",
  "xl:4": "xl:mx-4",
  "xl:5": "xl:mx-5",
  "xl:6": "xl:mx-6",
  "xl:8": "xl:mx-8",
  "xl:10": "xl:mx-10",
  "xl:12": "xl:mx-12",
  "xl:14": "xl:mx-14",
  "xl:16": "xl:mx-16",
  "xl:20": "xl:mx-20",
  "xl:24": "xl:mx-24",
  "xl:32": "xl:mx-32",
  "xxl:0": "xxl:mx-0",
  "xxl:0.5": "xxl:mx-0.5",
  "xxl:1": "xxl:mx-1",
  "xxl:2": "xxl:mx-2",
  "xxl:3": "xxl:mx-3",
  "xxl:4": "xxl:mx-4",
  "xxl:5": "xxl:mx-5",
  "xxl:6": "xxl:mx-6",
  "xxl:8": "xxl:mx-8",
  "xxl:10": "xxl:mx-10",
  "xxl:12": "xxl:mx-12",
  "xxl:14": "xxl:mx-14",
  "xxl:16": "xxl:mx-16",
  "xxl:20": "xxl:mx-20",
  "xxl:24": "xxl:mx-24",
  "xxl:32": "xxl:mx-32",
}

export const paddingYMap: ResponsiveMap<GlowSpacingTokens, "py"> = {
  "0": "py-0",
  "0.5": "py-0.5",
  "1": "py-1",
  "2": "py-2",
  "3": "py-3",
  "4": "py-4",
  "5": "py-5",
  "6": "py-6",
  "8": "py-8",
  "10": "py-10",
  "12": "py-12",
  "14": "py-14",
  "16": "py-16",
  "20": "py-20",
  "24": "py-24",
  "32": "py-32",
  "xs:0": "xs:py-0",
  "xs:0.5": "xs:py-0.5",
  "xs:1": "xs:py-1",
  "xs:2": "xs:py-2",
  "xs:3": "xs:py-3",
  "xs:4": "xs:py-4",
  "xs:5": "xs:py-5",
  "xs:6": "xs:py-6",
  "xs:8": "xs:py-8",
  "xs:10": "xs:py-10",
  "xs:12": "xs:py-12",
  "xs:14": "xs:py-14",
  "xs:16": "xs:py-16",
  "xs:20": "xs:py-20",
  "xs:24": "xs:py-24",
  "xs:32": "xs:py-32",
  "sm:0": "sm:py-0",
  "sm:0.5": "sm:py-0.5",
  "sm:1": "sm:py-1",
  "sm:2": "sm:py-2",
  "sm:3": "sm:py-3",
  "sm:4": "sm:py-4",
  "sm:5": "sm:py-5",
  "sm:6": "sm:py-6",
  "sm:8": "sm:py-8",
  "sm:10": "sm:py-10",
  "sm:12": "sm:py-12",
  "sm:14": "sm:py-14",
  "sm:16": "sm:py-16",
  "sm:20": "sm:py-20",
  "sm:24": "sm:py-24",
  "sm:32": "sm:py-32",
  "md:0": "md:py-0",
  "md:0.5": "md:py-0.5",
  "md:1": "md:py-1",
  "md:2": "md:py-2",
  "md:3": "md:py-3",
  "md:4": "md:py-4",
  "md:5": "md:py-5",
  "md:6": "md:py-6",
  "md:8": "md:py-8",
  "md:10": "md:py-10",
  "md:12": "md:py-12",
  "md:14": "md:py-14",
  "md:16": "md:py-16",
  "md:20": "md:py-20",
  "md:24": "md:py-24",
  "md:32": "md:py-32",
  "lg:0": "lg:py-0",
  "lg:0.5": "lg:py-0.5",
  "lg:1": "lg:py-1",
  "lg:2": "lg:py-2",
  "lg:3": "lg:py-3",
  "lg:4": "lg:py-4",
  "lg:5": "lg:py-5",
  "lg:6": "lg:py-6",
  "lg:8": "lg:py-8",
  "lg:10": "lg:py-10",
  "lg:12": "lg:py-12",
  "lg:14": "lg:py-14",
  "lg:16": "lg:py-16",
  "lg:20": "lg:py-20",
  "lg:24": "lg:py-24",
  "lg:32": "lg:py-32",
  "xl:0": "xl:py-0",
  "xl:0.5": "xl:py-0.5",
  "xl:1": "xl:py-1",
  "xl:2": "xl:py-2",
  "xl:3": "xl:py-3",
  "xl:4": "xl:py-4",
  "xl:5": "xl:py-5",
  "xl:6": "xl:py-6",
  "xl:8": "xl:py-8",
  "xl:10": "xl:py-10",
  "xl:12": "xl:py-12",
  "xl:14": "xl:py-14",
  "xl:16": "xl:py-16",
  "xl:20": "xl:py-20",
  "xl:24": "xl:py-24",
  "xl:32": "xl:py-32",
  "xxl:0": "xxl:py-0",
  "xxl:0.5": "xxl:py-0.5",
  "xxl:1": "xxl:py-1",
  "xxl:2": "xxl:py-2",
  "xxl:3": "xxl:py-3",
  "xxl:4": "xxl:py-4",
  "xxl:5": "xxl:py-5",
  "xxl:6": "xxl:py-6",
  "xxl:8": "xxl:py-8",
  "xxl:10": "xxl:py-10",
  "xxl:12": "xxl:py-12",
  "xxl:14": "xxl:py-14",
  "xxl:16": "xxl:py-16",
  "xxl:20": "xxl:py-20",
  "xxl:24": "xxl:py-24",
  "xxl:32": "xxl:py-32",
}

export const marginYMap: ResponsiveMap<GlowSpacingTokens, "my"> = {
  "0": "my-0",
  "0.5": "my-0.5",
  "1": "my-1",
  "2": "my-2",
  "3": "my-3",
  "4": "my-4",
  "5": "my-5",
  "6": "my-6",
  "8": "my-8",
  "10": "my-10",
  "12": "my-12",
  "14": "my-14",
  "16": "my-16",
  "20": "my-20",
  "24": "my-24",
  "32": "my-32",
  "xs:0": "xs:my-0",
  "xs:0.5": "xs:my-0.5",
  "xs:1": "xs:my-1",
  "xs:2": "xs:my-2",
  "xs:3": "xs:my-3",
  "xs:4": "xs:my-4",
  "xs:5": "xs:my-5",
  "xs:6": "xs:my-6",
  "xs:8": "xs:my-8",
  "xs:10": "xs:my-10",
  "xs:12": "xs:my-12",
  "xs:14": "xs:my-14",
  "xs:16": "xs:my-16",
  "xs:20": "xs:my-20",
  "xs:24": "xs:my-24",
  "xs:32": "xs:my-32",
  "sm:0": "sm:my-0",
  "sm:0.5": "sm:my-0.5",
  "sm:1": "sm:my-1",
  "sm:2": "sm:my-2",
  "sm:3": "sm:my-3",
  "sm:4": "sm:my-4",
  "sm:5": "sm:my-5",
  "sm:6": "sm:my-6",
  "sm:8": "sm:my-8",
  "sm:10": "sm:my-10",
  "sm:12": "sm:my-12",
  "sm:14": "sm:my-14",
  "sm:16": "sm:my-16",
  "sm:20": "sm:my-20",
  "sm:24": "sm:my-24",
  "sm:32": "sm:my-32",
  "md:0": "md:my-0",
  "md:0.5": "md:my-0.5",
  "md:1": "md:my-1",
  "md:2": "md:my-2",
  "md:3": "md:my-3",
  "md:4": "md:my-4",
  "md:5": "md:my-5",
  "md:6": "md:my-6",
  "md:8": "md:my-8",
  "md:10": "md:my-10",
  "md:12": "md:my-12",
  "md:14": "md:my-14",
  "md:16": "md:my-16",
  "md:20": "md:my-20",
  "md:24": "md:my-24",
  "md:32": "md:my-32",
  "lg:0": "lg:my-0",
  "lg:0.5": "lg:my-0.5",
  "lg:1": "lg:my-1",
  "lg:2": "lg:my-2",
  "lg:3": "lg:my-3",
  "lg:4": "lg:my-4",
  "lg:5": "lg:my-5",
  "lg:6": "lg:my-6",
  "lg:8": "lg:my-8",
  "lg:10": "lg:my-10",
  "lg:12": "lg:my-12",
  "lg:14": "lg:my-14",
  "lg:16": "lg:my-16",
  "lg:20": "lg:my-20",
  "lg:24": "lg:my-24",
  "lg:32": "lg:my-32",
  "xl:0": "xl:my-0",
  "xl:0.5": "xl:my-0.5",
  "xl:1": "xl:my-1",
  "xl:2": "xl:my-2",
  "xl:3": "xl:my-3",
  "xl:4": "xl:my-4",
  "xl:5": "xl:my-5",
  "xl:6": "xl:my-6",
  "xl:8": "xl:my-8",
  "xl:10": "xl:my-10",
  "xl:12": "xl:my-12",
  "xl:14": "xl:my-14",
  "xl:16": "xl:my-16",
  "xl:20": "xl:my-20",
  "xl:24": "xl:my-24",
  "xl:32": "xl:my-32",
  "xxl:0": "xxl:my-0",
  "xxl:0.5": "xxl:my-0.5",
  "xxl:1": "xxl:my-1",
  "xxl:2": "xxl:my-2",
  "xxl:3": "xxl:my-3",
  "xxl:4": "xxl:my-4",
  "xxl:5": "xxl:my-5",
  "xxl:6": "xxl:my-6",
  "xxl:8": "xxl:my-8",
  "xxl:10": "xxl:my-10",
  "xxl:12": "xxl:my-12",
  "xxl:14": "xxl:my-14",
  "xxl:16": "xxl:my-16",
  "xxl:20": "xxl:my-20",
  "xxl:24": "xxl:my-24",
  "xxl:32": "xxl:my-32",
}

export const paddingTopMap: ResponsiveMap<GlowSpacingTokens, "pt"> = {
  "0": "pt-0",
  "0.5": "pt-0.5",
  "1": "pt-1",
  "2": "pt-2",
  "3": "pt-3",
  "4": "pt-4",
  "5": "pt-5",
  "6": "pt-6",
  "8": "pt-8",
  "10": "pt-10",
  "12": "pt-12",
  "14": "pt-14",
  "16": "pt-16",
  "20": "pt-20",
  "24": "pt-24",
  "32": "pt-32",
  "xs:0": "xs:pt-0",
  "xs:0.5": "xs:pt-0.5",
  "xs:1": "xs:pt-1",
  "xs:2": "xs:pt-2",
  "xs:3": "xs:pt-3",
  "xs:4": "xs:pt-4",
  "xs:5": "xs:pt-5",
  "xs:6": "xs:pt-6",
  "xs:8": "xs:pt-8",
  "xs:10": "xs:pt-10",
  "xs:12": "xs:pt-12",
  "xs:14": "xs:pt-14",
  "xs:16": "xs:pt-16",
  "xs:20": "xs:pt-20",
  "xs:24": "xs:pt-24",
  "xs:32": "xs:pt-32",
  "sm:0": "sm:pt-0",
  "sm:0.5": "sm:pt-0.5",
  "sm:1": "sm:pt-1",
  "sm:2": "sm:pt-2",
  "sm:3": "sm:pt-3",
  "sm:4": "sm:pt-4",
  "sm:5": "sm:pt-5",
  "sm:6": "sm:pt-6",
  "sm:8": "sm:pt-8",
  "sm:10": "sm:pt-10",
  "sm:12": "sm:pt-12",
  "sm:14": "sm:pt-14",
  "sm:16": "sm:pt-16",
  "sm:20": "sm:pt-20",
  "sm:24": "sm:pt-24",
  "sm:32": "sm:pt-32",
  "md:0": "md:pt-0",
  "md:0.5": "md:pt-0.5",
  "md:1": "md:pt-1",
  "md:2": "md:pt-2",
  "md:3": "md:pt-3",
  "md:4": "md:pt-4",
  "md:5": "md:pt-5",
  "md:6": "md:pt-6",
  "md:8": "md:pt-8",
  "md:10": "md:pt-10",
  "md:12": "md:pt-12",
  "md:14": "md:pt-14",
  "md:16": "md:pt-16",
  "md:20": "md:pt-20",
  "md:24": "md:pt-24",
  "md:32": "md:pt-32",
  "lg:0": "lg:pt-0",
  "lg:0.5": "lg:pt-0.5",
  "lg:1": "lg:pt-1",
  "lg:2": "lg:pt-2",
  "lg:3": "lg:pt-3",
  "lg:4": "lg:pt-4",
  "lg:5": "lg:pt-5",
  "lg:6": "lg:pt-6",
  "lg:8": "lg:pt-8",
  "lg:10": "lg:pt-10",
  "lg:12": "lg:pt-12",
  "lg:14": "lg:pt-14",
  "lg:16": "lg:pt-16",
  "lg:20": "lg:pt-20",
  "lg:24": "lg:pt-24",
  "lg:32": "lg:pt-32",
  "xl:0": "xl:pt-0",
  "xl:0.5": "xl:pt-0.5",
  "xl:1": "xl:pt-1",
  "xl:2": "xl:pt-2",
  "xl:3": "xl:pt-3",
  "xl:4": "xl:pt-4",
  "xl:5": "xl:pt-5",
  "xl:6": "xl:pt-6",
  "xl:8": "xl:pt-8",
  "xl:10": "xl:pt-10",
  "xl:12": "xl:pt-12",
  "xl:14": "xl:pt-14",
  "xl:16": "xl:pt-16",
  "xl:20": "xl:pt-20",
  "xl:24": "xl:pt-24",
  "xl:32": "xl:pt-32",
  "xxl:0": "xxl:pt-0",
  "xxl:0.5": "xxl:pt-0.5",
  "xxl:1": "xxl:pt-1",
  "xxl:2": "xxl:pt-2",
  "xxl:3": "xxl:pt-3",
  "xxl:4": "xxl:pt-4",
  "xxl:5": "xxl:pt-5",
  "xxl:6": "xxl:pt-6",
  "xxl:8": "xxl:pt-8",
  "xxl:10": "xxl:pt-10",
  "xxl:12": "xxl:pt-12",
  "xxl:14": "xxl:pt-14",
  "xxl:16": "xxl:pt-16",
  "xxl:20": "xxl:pt-20",
  "xxl:24": "xxl:pt-24",
  "xxl:32": "xxl:pt-32",
}

export const marginTopMap: ResponsiveMap<GlowSpacingTokens, "mt"> = {
  "0": "mt-0",
  "0.5": "mt-0.5",
  "1": "mt-1",
  "2": "mt-2",
  "3": "mt-3",
  "4": "mt-4",
  "5": "mt-5",
  "6": "mt-6",
  "8": "mt-8",
  "10": "mt-10",
  "12": "mt-12",
  "14": "mt-14",
  "16": "mt-16",
  "20": "mt-20",
  "24": "mt-24",
  "32": "mt-32",
  "xs:0": "xs:mt-0",
  "xs:0.5": "xs:mt-0.5",
  "xs:1": "xs:mt-1",
  "xs:2": "xs:mt-2",
  "xs:3": "xs:mt-3",
  "xs:4": "xs:mt-4",
  "xs:5": "xs:mt-5",
  "xs:6": "xs:mt-6",
  "xs:8": "xs:mt-8",
  "xs:10": "xs:mt-10",
  "xs:12": "xs:mt-12",
  "xs:14": "xs:mt-14",
  "xs:16": "xs:mt-16",
  "xs:20": "xs:mt-20",
  "xs:24": "xs:mt-24",
  "xs:32": "xs:mt-32",
  "sm:0": "sm:mt-0",
  "sm:0.5": "sm:mt-0.5",
  "sm:1": "sm:mt-1",
  "sm:2": "sm:mt-2",
  "sm:3": "sm:mt-3",
  "sm:4": "sm:mt-4",
  "sm:5": "sm:mt-5",
  "sm:6": "sm:mt-6",
  "sm:8": "sm:mt-8",
  "sm:10": "sm:mt-10",
  "sm:12": "sm:mt-12",
  "sm:14": "sm:mt-14",
  "sm:16": "sm:mt-16",
  "sm:20": "sm:mt-20",
  "sm:24": "sm:mt-24",
  "sm:32": "sm:mt-32",
  "md:0": "md:mt-0",
  "md:0.5": "md:mt-0.5",
  "md:1": "md:mt-1",
  "md:2": "md:mt-2",
  "md:3": "md:mt-3",
  "md:4": "md:mt-4",
  "md:5": "md:mt-5",
  "md:6": "md:mt-6",
  "md:8": "md:mt-8",
  "md:10": "md:mt-10",
  "md:12": "md:mt-12",
  "md:14": "md:mt-14",
  "md:16": "md:mt-16",
  "md:20": "md:mt-20",
  "md:24": "md:mt-24",
  "md:32": "md:mt-32",
  "lg:0": "lg:mt-0",
  "lg:0.5": "lg:mt-0.5",
  "lg:1": "lg:mt-1",
  "lg:2": "lg:mt-2",
  "lg:3": "lg:mt-3",
  "lg:4": "lg:mt-4",
  "lg:5": "lg:mt-5",
  "lg:6": "lg:mt-6",
  "lg:8": "lg:mt-8",
  "lg:10": "lg:mt-10",
  "lg:12": "lg:mt-12",
  "lg:14": "lg:mt-14",
  "lg:16": "lg:mt-16",
  "lg:20": "lg:mt-20",
  "lg:24": "lg:mt-24",
  "lg:32": "lg:mt-32",
  "xl:0": "xl:mt-0",
  "xl:0.5": "xl:mt-0.5",
  "xl:1": "xl:mt-1",
  "xl:2": "xl:mt-2",
  "xl:3": "xl:mt-3",
  "xl:4": "xl:mt-4",
  "xl:5": "xl:mt-5",
  "xl:6": "xl:mt-6",
  "xl:8": "xl:mt-8",
  "xl:10": "xl:mt-10",
  "xl:12": "xl:mt-12",
  "xl:14": "xl:mt-14",
  "xl:16": "xl:mt-16",
  "xl:20": "xl:mt-20",
  "xl:24": "xl:mt-24",
  "xl:32": "xl:mt-32",
  "xxl:0": "xxl:mt-0",
  "xxl:0.5": "xxl:mt-0.5",
  "xxl:1": "xxl:mt-1",
  "xxl:2": "xxl:mt-2",
  "xxl:3": "xxl:mt-3",
  "xxl:4": "xxl:mt-4",
  "xxl:5": "xxl:mt-5",
  "xxl:6": "xxl:mt-6",
  "xxl:8": "xxl:mt-8",
  "xxl:10": "xxl:mt-10",
  "xxl:12": "xxl:mt-12",
  "xxl:14": "xxl:mt-14",
  "xxl:16": "xxl:mt-16",
  "xxl:20": "xxl:mt-20",
  "xxl:24": "xxl:mt-24",
  "xxl:32": "xxl:mt-32",
}

export const paddingBottomMap: ResponsiveMap<GlowSpacingTokens, "pb"> = {
  "0": "pb-0",
  "0.5": "pb-0.5",
  "1": "pb-1",
  "2": "pb-2",
  "3": "pb-3",
  "4": "pb-4",
  "5": "pb-5",
  "6": "pb-6",
  "8": "pb-8",
  "10": "pb-10",
  "12": "pb-12",
  "14": "pb-14",
  "16": "pb-16",
  "20": "pb-20",
  "24": "pb-24",
  "32": "pb-32",
  "xs:0": "xs:pb-0",
  "xs:0.5": "xs:pb-0.5",
  "xs:1": "xs:pb-1",
  "xs:2": "xs:pb-2",
  "xs:3": "xs:pb-3",
  "xs:4": "xs:pb-4",
  "xs:5": "xs:pb-5",
  "xs:6": "xs:pb-6",
  "xs:8": "xs:pb-8",
  "xs:10": "xs:pb-10",
  "xs:12": "xs:pb-12",
  "xs:14": "xs:pb-14",
  "xs:16": "xs:pb-16",
  "xs:20": "xs:pb-20",
  "xs:24": "xs:pb-24",
  "xs:32": "xs:pb-32",
  "sm:0": "sm:pb-0",
  "sm:0.5": "sm:pb-0.5",
  "sm:1": "sm:pb-1",
  "sm:2": "sm:pb-2",
  "sm:3": "sm:pb-3",
  "sm:4": "sm:pb-4",
  "sm:5": "sm:pb-5",
  "sm:6": "sm:pb-6",
  "sm:8": "sm:pb-8",
  "sm:10": "sm:pb-10",
  "sm:12": "sm:pb-12",
  "sm:14": "sm:pb-14",
  "sm:16": "sm:pb-16",
  "sm:20": "sm:pb-20",
  "sm:24": "sm:pb-24",
  "sm:32": "sm:pb-32",
  "md:0": "md:pb-0",
  "md:0.5": "md:pb-0.5",
  "md:1": "md:pb-1",
  "md:2": "md:pb-2",
  "md:3": "md:pb-3",
  "md:4": "md:pb-4",
  "md:5": "md:pb-5",
  "md:6": "md:pb-6",
  "md:8": "md:pb-8",
  "md:10": "md:pb-10",
  "md:12": "md:pb-12",
  "md:14": "md:pb-14",
  "md:16": "md:pb-16",
  "md:20": "md:pb-20",
  "md:24": "md:pb-24",
  "md:32": "md:pb-32",
  "lg:0": "lg:pb-0",
  "lg:0.5": "lg:pb-0.5",
  "lg:1": "lg:pb-1",
  "lg:2": "lg:pb-2",
  "lg:3": "lg:pb-3",
  "lg:4": "lg:pb-4",
  "lg:5": "lg:pb-5",
  "lg:6": "lg:pb-6",
  "lg:8": "lg:pb-8",
  "lg:10": "lg:pb-10",
  "lg:12": "lg:pb-12",
  "lg:14": "lg:pb-14",
  "lg:16": "lg:pb-16",
  "lg:20": "lg:pb-20",
  "lg:24": "lg:pb-24",
  "lg:32": "lg:pb-32",
  "xl:0": "xl:pb-0",
  "xl:0.5": "xl:pb-0.5",
  "xl:1": "xl:pb-1",
  "xl:2": "xl:pb-2",
  "xl:3": "xl:pb-3",
  "xl:4": "xl:pb-4",
  "xl:5": "xl:pb-5",
  "xl:6": "xl:pb-6",
  "xl:8": "xl:pb-8",
  "xl:10": "xl:pb-10",
  "xl:12": "xl:pb-12",
  "xl:14": "xl:pb-14",
  "xl:16": "xl:pb-16",
  "xl:20": "xl:pb-20",
  "xl:24": "xl:pb-24",
  "xl:32": "xl:pb-32",
  "xxl:0": "xxl:pb-0",
  "xxl:0.5": "xxl:pb-0.5",
  "xxl:1": "xxl:pb-1",
  "xxl:2": "xxl:pb-2",
  "xxl:3": "xxl:pb-3",
  "xxl:4": "xxl:pb-4",
  "xxl:5": "xxl:pb-5",
  "xxl:6": "xxl:pb-6",
  "xxl:8": "xxl:pb-8",
  "xxl:10": "xxl:pb-10",
  "xxl:12": "xxl:pb-12",
  "xxl:14": "xxl:pb-14",
  "xxl:16": "xxl:pb-16",
  "xxl:20": "xxl:pb-20",
  "xxl:24": "xxl:pb-24",
  "xxl:32": "xxl:pb-32",
}

export const marginBottomMap: ResponsiveMap<GlowSpacingTokens, "mb"> = {
  "0": "mb-0",
  "0.5": "mb-0.5",
  "1": "mb-1",
  "2": "mb-2",
  "3": "mb-3",
  "4": "mb-4",
  "5": "mb-5",
  "6": "mb-6",
  "8": "mb-8",
  "10": "mb-10",
  "12": "mb-12",
  "14": "mb-14",
  "16": "mb-16",
  "20": "mb-20",
  "24": "mb-24",
  "32": "mb-32",
  "xs:0": "xs:mb-0",
  "xs:0.5": "xs:mb-0.5",
  "xs:1": "xs:mb-1",
  "xs:2": "xs:mb-2",
  "xs:3": "xs:mb-3",
  "xs:4": "xs:mb-4",
  "xs:5": "xs:mb-5",
  "xs:6": "xs:mb-6",
  "xs:8": "xs:mb-8",
  "xs:10": "xs:mb-10",
  "xs:12": "xs:mb-12",
  "xs:14": "xs:mb-14",
  "xs:16": "xs:mb-16",
  "xs:20": "xs:mb-20",
  "xs:24": "xs:mb-24",
  "xs:32": "xs:mb-32",
  "sm:0": "sm:mb-0",
  "sm:0.5": "sm:mb-0.5",
  "sm:1": "sm:mb-1",
  "sm:2": "sm:mb-2",
  "sm:3": "sm:mb-3",
  "sm:4": "sm:mb-4",
  "sm:5": "sm:mb-5",
  "sm:6": "sm:mb-6",
  "sm:8": "sm:mb-8",
  "sm:10": "sm:mb-10",
  "sm:12": "sm:mb-12",
  "sm:14": "sm:mb-14",
  "sm:16": "sm:mb-16",
  "sm:20": "sm:mb-20",
  "sm:24": "sm:mb-24",
  "sm:32": "sm:mb-32",
  "md:0": "md:mb-0",
  "md:0.5": "md:mb-0.5",
  "md:1": "md:mb-1",
  "md:2": "md:mb-2",
  "md:3": "md:mb-3",
  "md:4": "md:mb-4",
  "md:5": "md:mb-5",
  "md:6": "md:mb-6",
  "md:8": "md:mb-8",
  "md:10": "md:mb-10",
  "md:12": "md:mb-12",
  "md:14": "md:mb-14",
  "md:16": "md:mb-16",
  "md:20": "md:mb-20",
  "md:24": "md:mb-24",
  "md:32": "md:mb-32",
  "lg:0": "lg:mb-0",
  "lg:0.5": "lg:mb-0.5",
  "lg:1": "lg:mb-1",
  "lg:2": "lg:mb-2",
  "lg:3": "lg:mb-3",
  "lg:4": "lg:mb-4",
  "lg:5": "lg:mb-5",
  "lg:6": "lg:mb-6",
  "lg:8": "lg:mb-8",
  "lg:10": "lg:mb-10",
  "lg:12": "lg:mb-12",
  "lg:14": "lg:mb-14",
  "lg:16": "lg:mb-16",
  "lg:20": "lg:mb-20",
  "lg:24": "lg:mb-24",
  "lg:32": "lg:mb-32",
  "xl:0": "xl:mb-0",
  "xl:0.5": "xl:mb-0.5",
  "xl:1": "xl:mb-1",
  "xl:2": "xl:mb-2",
  "xl:3": "xl:mb-3",
  "xl:4": "xl:mb-4",
  "xl:5": "xl:mb-5",
  "xl:6": "xl:mb-6",
  "xl:8": "xl:mb-8",
  "xl:10": "xl:mb-10",
  "xl:12": "xl:mb-12",
  "xl:14": "xl:mb-14",
  "xl:16": "xl:mb-16",
  "xl:20": "xl:mb-20",
  "xl:24": "xl:mb-24",
  "xl:32": "xl:mb-32",
  "xxl:0": "xxl:mb-0",
  "xxl:0.5": "xxl:mb-0.5",
  "xxl:1": "xxl:mb-1",
  "xxl:2": "xxl:mb-2",
  "xxl:3": "xxl:mb-3",
  "xxl:4": "xxl:mb-4",
  "xxl:5": "xxl:mb-5",
  "xxl:6": "xxl:mb-6",
  "xxl:8": "xxl:mb-8",
  "xxl:10": "xxl:mb-10",
  "xxl:12": "xxl:mb-12",
  "xxl:14": "xxl:mb-14",
  "xxl:16": "xxl:mb-16",
  "xxl:20": "xxl:mb-20",
  "xxl:24": "xxl:mb-24",
  "xxl:32": "xxl:mb-32",
}

export const paddingLeftMap: ResponsiveMap<GlowSpacingTokens, "pl"> = {
  "0": "pl-0",
  "0.5": "pl-0.5",
  "1": "pl-1",
  "2": "pl-2",
  "3": "pl-3",
  "4": "pl-4",
  "5": "pl-5",
  "6": "pl-6",
  "8": "pl-8",
  "10": "pl-10",
  "12": "pl-12",
  "14": "pl-14",
  "16": "pl-16",
  "20": "pl-20",
  "24": "pl-24",
  "32": "pl-32",
  "xs:0": "xs:pl-0",
  "xs:0.5": "xs:pl-0.5",
  "xs:1": "xs:pl-1",
  "xs:2": "xs:pl-2",
  "xs:3": "xs:pl-3",
  "xs:4": "xs:pl-4",
  "xs:5": "xs:pl-5",
  "xs:6": "xs:pl-6",
  "xs:8": "xs:pl-8",
  "xs:10": "xs:pl-10",
  "xs:12": "xs:pl-12",
  "xs:14": "xs:pl-14",
  "xs:16": "xs:pl-16",
  "xs:20": "xs:pl-20",
  "xs:24": "xs:pl-24",
  "xs:32": "xs:pl-32",
  "sm:0": "sm:pl-0",
  "sm:0.5": "sm:pl-0.5",
  "sm:1": "sm:pl-1",
  "sm:2": "sm:pl-2",
  "sm:3": "sm:pl-3",
  "sm:4": "sm:pl-4",
  "sm:5": "sm:pl-5",
  "sm:6": "sm:pl-6",
  "sm:8": "sm:pl-8",
  "sm:10": "sm:pl-10",
  "sm:12": "sm:pl-12",
  "sm:14": "sm:pl-14",
  "sm:16": "sm:pl-16",
  "sm:20": "sm:pl-20",
  "sm:24": "sm:pl-24",
  "sm:32": "sm:pl-32",
  "md:0": "md:pl-0",
  "md:0.5": "md:pl-0.5",
  "md:1": "md:pl-1",
  "md:2": "md:pl-2",
  "md:3": "md:pl-3",
  "md:4": "md:pl-4",
  "md:5": "md:pl-5",
  "md:6": "md:pl-6",
  "md:8": "md:pl-8",
  "md:10": "md:pl-10",
  "md:12": "md:pl-12",
  "md:14": "md:pl-14",
  "md:16": "md:pl-16",
  "md:20": "md:pl-20",
  "md:24": "md:pl-24",
  "md:32": "md:pl-32",
  "lg:0": "lg:pl-0",
  "lg:0.5": "lg:pl-0.5",
  "lg:1": "lg:pl-1",
  "lg:2": "lg:pl-2",
  "lg:3": "lg:pl-3",
  "lg:4": "lg:pl-4",
  "lg:5": "lg:pl-5",
  "lg:6": "lg:pl-6",
  "lg:8": "lg:pl-8",
  "lg:10": "lg:pl-10",
  "lg:12": "lg:pl-12",
  "lg:14": "lg:pl-14",
  "lg:16": "lg:pl-16",
  "lg:20": "lg:pl-20",
  "lg:24": "lg:pl-24",
  "lg:32": "lg:pl-32",
  "xl:0": "xl:pl-0",
  "xl:0.5": "xl:pl-0.5",
  "xl:1": "xl:pl-1",
  "xl:2": "xl:pl-2",
  "xl:3": "xl:pl-3",
  "xl:4": "xl:pl-4",
  "xl:5": "xl:pl-5",
  "xl:6": "xl:pl-6",
  "xl:8": "xl:pl-8",
  "xl:10": "xl:pl-10",
  "xl:12": "xl:pl-12",
  "xl:14": "xl:pl-14",
  "xl:16": "xl:pl-16",
  "xl:20": "xl:pl-20",
  "xl:24": "xl:pl-24",
  "xl:32": "xl:pl-32",
  "xxl:0": "xxl:pl-0",
  "xxl:0.5": "xxl:pl-0.5",
  "xxl:1": "xxl:pl-1",
  "xxl:2": "xxl:pl-2",
  "xxl:3": "xxl:pl-3",
  "xxl:4": "xxl:pl-4",
  "xxl:5": "xxl:pl-5",
  "xxl:6": "xxl:pl-6",
  "xxl:8": "xxl:pl-8",
  "xxl:10": "xxl:pl-10",
  "xxl:12": "xxl:pl-12",
  "xxl:14": "xxl:pl-14",
  "xxl:16": "xxl:pl-16",
  "xxl:20": "xxl:pl-20",
  "xxl:24": "xxl:pl-24",
  "xxl:32": "xxl:pl-32",
}

export const marginLeftMap: ResponsiveMap<GlowSpacingTokens, "ml"> = {
  "0": "ml-0",
  "0.5": "ml-0.5",
  "1": "ml-1",
  "2": "ml-2",
  "3": "ml-3",
  "4": "ml-4",
  "5": "ml-5",
  "6": "ml-6",
  "8": "ml-8",
  "10": "ml-10",
  "12": "ml-12",
  "14": "ml-14",
  "16": "ml-16",
  "20": "ml-20",
  "24": "ml-24",
  "32": "ml-32",
  "xs:0": "xs:ml-0",
  "xs:0.5": "xs:ml-0.5",
  "xs:1": "xs:ml-1",
  "xs:2": "xs:ml-2",
  "xs:3": "xs:ml-3",
  "xs:4": "xs:ml-4",
  "xs:5": "xs:ml-5",
  "xs:6": "xs:ml-6",
  "xs:8": "xs:ml-8",
  "xs:10": "xs:ml-10",
  "xs:12": "xs:ml-12",
  "xs:14": "xs:ml-14",
  "xs:16": "xs:ml-16",
  "xs:20": "xs:ml-20",
  "xs:24": "xs:ml-24",
  "xs:32": "xs:ml-32",
  "sm:0": "sm:ml-0",
  "sm:0.5": "sm:ml-0.5",
  "sm:1": "sm:ml-1",
  "sm:2": "sm:ml-2",
  "sm:3": "sm:ml-3",
  "sm:4": "sm:ml-4",
  "sm:5": "sm:ml-5",
  "sm:6": "sm:ml-6",
  "sm:8": "sm:ml-8",
  "sm:10": "sm:ml-10",
  "sm:12": "sm:ml-12",
  "sm:14": "sm:ml-14",
  "sm:16": "sm:ml-16",
  "sm:20": "sm:ml-20",
  "sm:24": "sm:ml-24",
  "sm:32": "sm:ml-32",
  "md:0": "md:ml-0",
  "md:0.5": "md:ml-0.5",
  "md:1": "md:ml-1",
  "md:2": "md:ml-2",
  "md:3": "md:ml-3",
  "md:4": "md:ml-4",
  "md:5": "md:ml-5",
  "md:6": "md:ml-6",
  "md:8": "md:ml-8",
  "md:10": "md:ml-10",
  "md:12": "md:ml-12",
  "md:14": "md:ml-14",
  "md:16": "md:ml-16",
  "md:20": "md:ml-20",
  "md:24": "md:ml-24",
  "md:32": "md:ml-32",
  "lg:0": "lg:ml-0",
  "lg:0.5": "lg:ml-0.5",
  "lg:1": "lg:ml-1",
  "lg:2": "lg:ml-2",
  "lg:3": "lg:ml-3",
  "lg:4": "lg:ml-4",
  "lg:5": "lg:ml-5",
  "lg:6": "lg:ml-6",
  "lg:8": "lg:ml-8",
  "lg:10": "lg:ml-10",
  "lg:12": "lg:ml-12",
  "lg:14": "lg:ml-14",
  "lg:16": "lg:ml-16",
  "lg:20": "lg:ml-20",
  "lg:24": "lg:ml-24",
  "lg:32": "lg:ml-32",
  "xl:0": "xl:ml-0",
  "xl:0.5": "xl:ml-0.5",
  "xl:1": "xl:ml-1",
  "xl:2": "xl:ml-2",
  "xl:3": "xl:ml-3",
  "xl:4": "xl:ml-4",
  "xl:5": "xl:ml-5",
  "xl:6": "xl:ml-6",
  "xl:8": "xl:ml-8",
  "xl:10": "xl:ml-10",
  "xl:12": "xl:ml-12",
  "xl:14": "xl:ml-14",
  "xl:16": "xl:ml-16",
  "xl:20": "xl:ml-20",
  "xl:24": "xl:ml-24",
  "xl:32": "xl:ml-32",
  "xxl:0": "xxl:ml-0",
  "xxl:0.5": "xxl:ml-0.5",
  "xxl:1": "xxl:ml-1",
  "xxl:2": "xxl:ml-2",
  "xxl:3": "xxl:ml-3",
  "xxl:4": "xxl:ml-4",
  "xxl:5": "xxl:ml-5",
  "xxl:6": "xxl:ml-6",
  "xxl:8": "xxl:ml-8",
  "xxl:10": "xxl:ml-10",
  "xxl:12": "xxl:ml-12",
  "xxl:14": "xxl:ml-14",
  "xxl:16": "xxl:ml-16",
  "xxl:20": "xxl:ml-20",
  "xxl:24": "xxl:ml-24",
  "xxl:32": "xxl:ml-32",
}

export const paddingRightMap: ResponsiveMap<GlowSpacingTokens, "pr"> = {
  "0": "pr-0",
  "0.5": "pr-0.5",
  "1": "pr-1",
  "2": "pr-2",
  "3": "pr-3",
  "4": "pr-4",
  "5": "pr-5",
  "6": "pr-6",
  "8": "pr-8",
  "10": "pr-10",
  "12": "pr-12",
  "14": "pr-14",
  "16": "pr-16",
  "20": "pr-20",
  "24": "pr-24",
  "32": "pr-32",
  "xs:0": "xs:pr-0",
  "xs:0.5": "xs:pr-0.5",
  "xs:1": "xs:pr-1",
  "xs:2": "xs:pr-2",
  "xs:3": "xs:pr-3",
  "xs:4": "xs:pr-4",
  "xs:5": "xs:pr-5",
  "xs:6": "xs:pr-6",
  "xs:8": "xs:pr-8",
  "xs:10": "xs:pr-10",
  "xs:12": "xs:pr-12",
  "xs:14": "xs:pr-14",
  "xs:16": "xs:pr-16",
  "xs:20": "xs:pr-20",
  "xs:24": "xs:pr-24",
  "xs:32": "xs:pr-32",
  "sm:0": "sm:pr-0",
  "sm:0.5": "sm:pr-0.5",
  "sm:1": "sm:pr-1",
  "sm:2": "sm:pr-2",
  "sm:3": "sm:pr-3",
  "sm:4": "sm:pr-4",
  "sm:5": "sm:pr-5",
  "sm:6": "sm:pr-6",
  "sm:8": "sm:pr-8",
  "sm:10": "sm:pr-10",
  "sm:12": "sm:pr-12",
  "sm:14": "sm:pr-14",
  "sm:16": "sm:pr-16",
  "sm:20": "sm:pr-20",
  "sm:24": "sm:pr-24",
  "sm:32": "sm:pr-32",
  "md:0": "md:pr-0",
  "md:0.5": "md:pr-0.5",
  "md:1": "md:pr-1",
  "md:2": "md:pr-2",
  "md:3": "md:pr-3",
  "md:4": "md:pr-4",
  "md:5": "md:pr-5",
  "md:6": "md:pr-6",
  "md:8": "md:pr-8",
  "md:10": "md:pr-10",
  "md:12": "md:pr-12",
  "md:14": "md:pr-14",
  "md:16": "md:pr-16",
  "md:20": "md:pr-20",
  "md:24": "md:pr-24",
  "md:32": "md:pr-32",
  "lg:0": "lg:pr-0",
  "lg:0.5": "lg:pr-0.5",
  "lg:1": "lg:pr-1",
  "lg:2": "lg:pr-2",
  "lg:3": "lg:pr-3",
  "lg:4": "lg:pr-4",
  "lg:5": "lg:pr-5",
  "lg:6": "lg:pr-6",
  "lg:8": "lg:pr-8",
  "lg:10": "lg:pr-10",
  "lg:12": "lg:pr-12",
  "lg:14": "lg:pr-14",
  "lg:16": "lg:pr-16",
  "lg:20": "lg:pr-20",
  "lg:24": "lg:pr-24",
  "lg:32": "lg:pr-32",
  "xl:0": "xl:pr-0",
  "xl:0.5": "xl:pr-0.5",
  "xl:1": "xl:pr-1",
  "xl:2": "xl:pr-2",
  "xl:3": "xl:pr-3",
  "xl:4": "xl:pr-4",
  "xl:5": "xl:pr-5",
  "xl:6": "xl:pr-6",
  "xl:8": "xl:pr-8",
  "xl:10": "xl:pr-10",
  "xl:12": "xl:pr-12",
  "xl:14": "xl:pr-14",
  "xl:16": "xl:pr-16",
  "xl:20": "xl:pr-20",
  "xl:24": "xl:pr-24",
  "xl:32": "xl:pr-32",
  "xxl:0": "xxl:pr-0",
  "xxl:0.5": "xxl:pr-0.5",
  "xxl:1": "xxl:pr-1",
  "xxl:2": "xxl:pr-2",
  "xxl:3": "xxl:pr-3",
  "xxl:4": "xxl:pr-4",
  "xxl:5": "xxl:pr-5",
  "xxl:6": "xxl:pr-6",
  "xxl:8": "xxl:pr-8",
  "xxl:10": "xxl:pr-10",
  "xxl:12": "xxl:pr-12",
  "xxl:14": "xxl:pr-14",
  "xxl:16": "xxl:pr-16",
  "xxl:20": "xxl:pr-20",
  "xxl:24": "xxl:pr-24",
  "xxl:32": "xxl:pr-32",
}

export const marginRightMap: ResponsiveMap<GlowSpacingTokens, "mr"> = {
  "0": "mr-0",
  "0.5": "mr-0.5",
  "1": "mr-1",
  "2": "mr-2",
  "3": "mr-3",
  "4": "mr-4",
  "5": "mr-5",
  "6": "mr-6",
  "8": "mr-8",
  "10": "mr-10",
  "12": "mr-12",
  "14": "mr-14",
  "16": "mr-16",
  "20": "mr-20",
  "24": "mr-24",
  "32": "mr-32",
  "xs:0": "xs:mr-0",
  "xs:0.5": "xs:mr-0.5",
  "xs:1": "xs:mr-1",
  "xs:2": "xs:mr-2",
  "xs:3": "xs:mr-3",
  "xs:4": "xs:mr-4",
  "xs:5": "xs:mr-5",
  "xs:6": "xs:mr-6",
  "xs:8": "xs:mr-8",
  "xs:10": "xs:mr-10",
  "xs:12": "xs:mr-12",
  "xs:14": "xs:mr-14",
  "xs:16": "xs:mr-16",
  "xs:20": "xs:mr-20",
  "xs:24": "xs:mr-24",
  "xs:32": "xs:mr-32",
  "sm:0": "sm:mr-0",
  "sm:0.5": "sm:mr-0.5",
  "sm:1": "sm:mr-1",
  "sm:2": "sm:mr-2",
  "sm:3": "sm:mr-3",
  "sm:4": "sm:mr-4",
  "sm:5": "sm:mr-5",
  "sm:6": "sm:mr-6",
  "sm:8": "sm:mr-8",
  "sm:10": "sm:mr-10",
  "sm:12": "sm:mr-12",
  "sm:14": "sm:mr-14",
  "sm:16": "sm:mr-16",
  "sm:20": "sm:mr-20",
  "sm:24": "sm:mr-24",
  "sm:32": "sm:mr-32",
  "md:0": "md:mr-0",
  "md:0.5": "md:mr-0.5",
  "md:1": "md:mr-1",
  "md:2": "md:mr-2",
  "md:3": "md:mr-3",
  "md:4": "md:mr-4",
  "md:5": "md:mr-5",
  "md:6": "md:mr-6",
  "md:8": "md:mr-8",
  "md:10": "md:mr-10",
  "md:12": "md:mr-12",
  "md:14": "md:mr-14",
  "md:16": "md:mr-16",
  "md:20": "md:mr-20",
  "md:24": "md:mr-24",
  "md:32": "md:mr-32",
  "lg:0": "lg:mr-0",
  "lg:0.5": "lg:mr-0.5",
  "lg:1": "lg:mr-1",
  "lg:2": "lg:mr-2",
  "lg:3": "lg:mr-3",
  "lg:4": "lg:mr-4",
  "lg:5": "lg:mr-5",
  "lg:6": "lg:mr-6",
  "lg:8": "lg:mr-8",
  "lg:10": "lg:mr-10",
  "lg:12": "lg:mr-12",
  "lg:14": "lg:mr-14",
  "lg:16": "lg:mr-16",
  "lg:20": "lg:mr-20",
  "lg:24": "lg:mr-24",
  "lg:32": "lg:mr-32",
  "xl:0": "xl:mr-0",
  "xl:0.5": "xl:mr-0.5",
  "xl:1": "xl:mr-1",
  "xl:2": "xl:mr-2",
  "xl:3": "xl:mr-3",
  "xl:4": "xl:mr-4",
  "xl:5": "xl:mr-5",
  "xl:6": "xl:mr-6",
  "xl:8": "xl:mr-8",
  "xl:10": "xl:mr-10",
  "xl:12": "xl:mr-12",
  "xl:14": "xl:mr-14",
  "xl:16": "xl:mr-16",
  "xl:20": "xl:mr-20",
  "xl:24": "xl:mr-24",
  "xl:32": "xl:mr-32",
  "xxl:0": "xxl:mr-0",
  "xxl:0.5": "xxl:mr-0.5",
  "xxl:1": "xxl:mr-1",
  "xxl:2": "xxl:mr-2",
  "xxl:3": "xxl:mr-3",
  "xxl:4": "xxl:mr-4",
  "xxl:5": "xxl:mr-5",
  "xxl:6": "xxl:mr-6",
  "xxl:8": "xxl:mr-8",
  "xxl:10": "xxl:mr-10",
  "xxl:12": "xxl:mr-12",
  "xxl:14": "xxl:mr-14",
  "xxl:16": "xxl:mr-16",
  "xxl:20": "xxl:mr-20",
  "xxl:24": "xxl:mr-24",
  "xxl:32": "xxl:mr-32",
}
