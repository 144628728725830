import React from "react"

import { createRoot } from "react-dom/client"

import "./index.css"
import App from "./App"

const domRoot = document.getElementById("app")
if (!domRoot) {
  throw new Error("Could not find root element")
}
const root = createRoot(domRoot)
root.render(<App />)
