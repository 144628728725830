import { createFileRoute, lazyRouteComponent } from "@tanstack/react-router"
import { z } from "zod"

const searchSchema = z.object({
  from: z.string().optional(),
})

export const Route = createFileRoute("/preferences/$userId")({
  validateSearch: searchSchema,
  component: lazyRouteComponent(
    () =>
      import("src/pages/Wizards/PreferencesWizardPage/PreferencesWizardPage"),
  ),
})
