import React from "react"
import { useContext, useEffect, useMemo } from "react"

import useLocalStorageState from "use-local-storage-state"

const EmulateUserContext = React.createContext<{
  emulatingUserId: string | null
  isEmulating: boolean
  setEmulatingUserId: (id: string) => void
  stopEmulating: () => void
} | null>(null)

export function EmulateUserProvider(props: { children: React.ReactNode }) {
  const param = new URLSearchParams(window.location.search)
  const emulateUserId = param.get("emulate_user_id")

  const [value, setValue, { removeItem }] =
    useLocalStorageState<string>("emulate_user")

  useEffect(() => {
    if (emulateUserId == null) {
      return
    }
    if (value == emulateUserId) {
      return
    }
    setValue(emulateUserId)
    const currentParams = new URLSearchParams(window.location.search)
    currentParams.delete("emulate_user_id")
    window.location.search = currentParams.toString()
  }, [emulateUserId, setValue, value])

  const state = useMemo(
    () =>
      ({
        emulatingUserId: value ?? null,
        isEmulating: value != null,
        setEmulatingUserId: setValue,
        stopEmulating: removeItem,
      }) as const,
    [value, setValue, removeItem],
  )
  return (
    <EmulateUserContext.Provider value={state}>
      {props.children}
    </EmulateUserContext.Provider>
  )
}

export default function useEmulateUser() {
  const context = useContext(EmulateUserContext)
  if (context === null) {
    throw new Error("useEmulateUser must be used within a EmulateUserProvider")
  }
  return context
}
