import { createFileRoute, lazyRouteComponent } from "@tanstack/react-router"
import { loadQuery } from "react-relay"

import QuestsPageQuery, {
  QuestsPageQuery as QuestsPageQueryType,
} from "src/pages/QuestsPage/__generated__/QuestsPageQuery.graphql"
import { QuestsPageLayout } from "src/pages/QuestsPage/components/QuestsPageLayout"

import requireIsLoggedIn from "../routeUtils/requireIsLoggedIn"

export const Route = createFileRoute("/quests/")({
  beforeLoad: requireIsLoggedIn({ redirectTo: "/login" }),
  pendingComponent: QuestsPageLayout,
  loader({ context }) {
    return loadQuery<QuestsPageQueryType>(
      context.relayEnvironment(),
      QuestsPageQuery,
      {},
    )
  },
  component: lazyRouteComponent(
    () => import("src/pages/QuestsPage/QuestsPage"),
  ),
})
