/**
 * @generated SignedSource<<186cab9f7c8e44ca58b39f7c0026929c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CurrencyCode = "GBP" | "%future added value";
export type InflationaryPayriseCalculationInput = {
  annualSalary: MoneyInput;
  inflation?: number | null | undefined;
};
export type MoneyInput = {
  amount: number;
  currency: CurrencyCode;
  precision: number;
};
export type InflationaryPayRiseCalculatorPage2Query$variables = {
  input: InflationaryPayriseCalculationInput;
  skip: boolean;
};
export type InflationaryPayRiseCalculatorPage2Query$data = {
  readonly inflationaryPayRiseCalculation?: {
    readonly " $fragmentSpreads": FragmentRefs<"InflationaryPayRiseCalculator_initialComputations">;
  };
};
export type InflationaryPayRiseCalculatorPage2Query = {
  response: InflationaryPayRiseCalculatorPage2Query$data;
  variables: InflationaryPayRiseCalculatorPage2Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "skip"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "amount",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "precision",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "currency",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "InflationaryPayRiseCalculatorPage2Query",
    "selections": [
      {
        "condition": "skip",
        "kind": "Condition",
        "passingValue": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "InflationaryPayriseCalculationResult",
            "kind": "LinkedField",
            "name": "inflationaryPayRiseCalculation",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "InflationaryPayRiseCalculator_initialComputations"
              }
            ],
            "storageKey": null
          }
        ]
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "InflationaryPayRiseCalculatorPage2Query",
    "selections": [
      {
        "condition": "skip",
        "kind": "Condition",
        "passingValue": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "InflationaryPayriseCalculationResult",
            "kind": "LinkedField",
            "name": "inflationaryPayRiseCalculation",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "percentagePayRise",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Money",
                "kind": "LinkedField",
                "name": "requiredAnnualSalary",
                "plural": false,
                "selections": (v2/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Money",
                "kind": "LinkedField",
                "name": "requiredPayRise",
                "plural": false,
                "selections": (v2/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Money",
                "kind": "LinkedField",
                "name": "currentAnnualSalary",
                "plural": false,
                "selections": (v2/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Money",
                "kind": "LinkedField",
                "name": "currentAnnualSalaryAfterTax",
                "plural": false,
                "selections": (v2/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Money",
                "kind": "LinkedField",
                "name": "newAnnualSalaryAfterTax",
                "plural": false,
                "selections": (v2/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "basedOnInflation",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Money",
                "kind": "LinkedField",
                "name": "basedOnSalary",
                "plural": false,
                "selections": (v2/*: any*/),
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ]
      }
    ]
  },
  "params": {
    "cacheID": "9c1aa36e44cb1869a4f6a91628df27eb",
    "id": null,
    "metadata": {},
    "name": "InflationaryPayRiseCalculatorPage2Query",
    "operationKind": "query",
    "text": "query InflationaryPayRiseCalculatorPage2Query(\n  $input: InflationaryPayriseCalculationInput!\n  $skip: Boolean!\n) {\n  inflationaryPayRiseCalculation(input: $input) @skip(if: $skip) {\n    ...InflationaryPayRiseCalculator_initialComputations\n  }\n}\n\nfragment InflationaryPayRiseCalculator_initialComputations on InflationaryPayriseCalculationResult {\n  ...usePayriseCalculator_calculations\n}\n\nfragment useMoneyToDinero_money on Money {\n  amount\n  precision\n  currency\n}\n\nfragment usePayriseCalculator_calculations on InflationaryPayriseCalculationResult {\n  percentagePayRise\n  requiredAnnualSalary {\n    ...useMoneyToDinero_money\n  }\n  requiredPayRise {\n    ...useMoneyToDinero_money\n  }\n  currentAnnualSalary {\n    ...useMoneyToDinero_money\n  }\n  currentAnnualSalaryAfterTax {\n    ...useMoneyToDinero_money\n  }\n  newAnnualSalaryAfterTax {\n    ...useMoneyToDinero_money\n  }\n  basedOnInflation\n  basedOnSalary {\n    ...useMoneyToDinero_money\n  }\n}\n"
  }
};
})();

(node as any).hash = "f8bba108e5f0e430cfaead8d652fce7d";

export default node;
