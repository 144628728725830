/**
 * @generated SignedSource<<5f178600b7f99269894d0981b6e70c7a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type QuestsPageQuery$variables = Record<PropertyKey, never>;
export type QuestsPageQuery$data = {
  readonly household: {
    readonly " $fragmentSpreads": FragmentRefs<"QuestsPageContent_household" | "useQuestsPageRefetch_household" | "useRedirectToWaitlist_household">;
  };
};
export type QuestsPageQuery = {
  response: QuestsPageQuery$data;
  variables: QuestsPageQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "inFocus",
  "storageKey": null
},
v3 = {
  "kind": "TypeDiscriminator",
  "abstractKey": "__isIUserTask"
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "priority",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "completed",
  "storageKey": null
},
v6 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "dealCardExpiresAfterDays",
    "storageKey": null
  }
],
v7 = {
  "kind": "InlineFragment",
  "selections": (v6/*: any*/),
  "type": "ReviewEnergyRecommendationUserTask",
  "abstractKey": null
},
v8 = {
  "kind": "InlineFragment",
  "selections": (v6/*: any*/),
  "type": "ReviewMobileRecommendationUserTask",
  "abstractKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "concreteType": null,
  "kind": "LinkedField",
  "name": "quest",
  "plural": false,
  "selections": [
    (v1/*: any*/),
    (v0/*: any*/)
  ],
  "storageKey": null
},
v10 = {
  "alias": "tasks",
  "args": null,
  "concreteType": null,
  "kind": "LinkedField",
  "name": "userTasks",
  "plural": true,
  "selections": [
    (v1/*: any*/),
    (v3/*: any*/),
    (v0/*: any*/),
    (v4/*: any*/),
    (v5/*: any*/),
    (v7/*: any*/),
    (v8/*: any*/),
    (v9/*: any*/)
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "QuestsPageQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Household",
        "kind": "LinkedField",
        "name": "household",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "QuestsPageContent_household"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "useQuestsPageRefetch_household"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "useRedirectToWaitlist_household"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "QuestsPageQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Household",
        "kind": "LinkedField",
        "name": "household",
        "plural": false,
        "selections": [
          {
            "alias": "inFocusQuests",
            "args": [
              {
                "kind": "Literal",
                "name": "inFocus",
                "value": true
              }
            ],
            "concreteType": null,
            "kind": "LinkedField",
            "name": "quests",
            "plural": true,
            "selections": [
              (v0/*: any*/),
              (v1/*: any*/),
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "userTasks",
                "plural": true,
                "selections": [
                  (v1/*: any*/),
                  (v0/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": "quests(inFocus:true)"
          },
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "savingsQuests",
            "plural": true,
            "selections": [
              (v1/*: any*/),
              (v0/*: any*/),
              {
                "kind": "TypeDiscriminator",
                "abstractKey": "__isISavingsQuest"
              },
              (v2/*: any*/),
              (v10/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "setupQuests",
            "plural": true,
            "selections": [
              (v1/*: any*/),
              (v0/*: any*/),
              (v10/*: any*/)
            ],
            "storageKey": null
          },
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "inWaitlist",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "serviceLevel",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isDelegated",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "cbcfddbe7d009e76f413c4fd8704179e",
    "id": null,
    "metadata": {},
    "name": "QuestsPageQuery",
    "operationKind": "query",
    "text": "query QuestsPageQuery {\n  household {\n    ...QuestsPageContent_household\n    ...useQuestsPageRefetch_household\n    ...useRedirectToWaitlist_household\n    id\n  }\n}\n\nfragment QuestsPageContent_household on Household {\n  ...UpNext_household\n  ...QuestsPageQuests_household\n  ...useInfocusQuest_household\n}\n\nfragment QuestsPageQuestsQuestTasks_task on IUserTask {\n  __isIUserTask: __typename\n  id\n  __typename\n  ...UserTask_task\n}\n\nfragment QuestsPageQuestsSavingsQuest_quest on ISavingsQuest {\n  __isISavingsQuest: __typename\n  id\n  ...SavingsQuestBadge_quest\n  tasks: userTasks {\n    __typename\n    ...QuestsPageQuestsQuestTasks_task\n    id\n  }\n}\n\nfragment QuestsPageQuests_household on Household {\n  savingsQuests {\n    __typename\n    id\n    ...QuestsPageQuestsSavingsQuest_quest\n  }\n  setupQuests {\n    __typename\n    id\n    tasks: userTasks {\n      __typename\n      ...QuestsPageQuestsQuestTasks_task\n      id\n    }\n  }\n}\n\nfragment SavingsQuestBadge_quest on ISavingsQuest {\n  __isISavingsQuest: __typename\n  __typename\n  inFocus\n}\n\nfragment UpNext_household on Household {\n  ...useInfocusQuest_household\n}\n\nfragment UserTask_task on IUserTask {\n  __isIUserTask: __typename\n  id\n  priority\n  completed\n  __typename\n  ... on ReviewEnergyRecommendationUserTask {\n    dealCardExpiresAfterDays\n  }\n  ... on ReviewMobileRecommendationUserTask {\n    dealCardExpiresAfterDays\n  }\n  quest {\n    __typename\n    id\n  }\n}\n\nfragment useInfocusQuest_household on Household {\n  inFocusQuests: quests(inFocus: true) {\n    id\n    __typename\n    inFocus\n    userTasks {\n      __typename\n      id\n      ...UserTask_task\n    }\n  }\n}\n\nfragment useQuestsPageRefetch_household on Household {\n  id\n  ...QuestsPageContent_household\n}\n\nfragment useRedirectToWaitlist_household on Household {\n  inWaitlist\n  serviceLevel\n  isDelegated\n}\n"
  }
};
})();

(node as any).hash = "9873f6d1edb1c19d91106d8b99824e55";

export default node;
