/**
 * @generated SignedSource<<b72d38703e0c95ceb9a6b83f6860f942>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type InviteModalContent_invites$data = ReadonlyArray<{
  readonly id: string;
  readonly " $fragmentSpreads": FragmentRefs<"InviteModalPendingInvite_invite">;
  readonly " $fragmentType": "InviteModalContent_invites";
}>;
export type InviteModalContent_invites$key = ReadonlyArray<{
  readonly " $data"?: InviteModalContent_invites$data;
  readonly " $fragmentSpreads": FragmentRefs<"InviteModalContent_invites">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "InviteModalContent_invites",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "InviteModalPendingInvite_invite"
    }
  ],
  "type": "HouseholdMemberInvite",
  "abstractKey": null
};

(node as any).hash = "919e488f9912e5a4b2ad416aa5b8b128";

export default node;
