import { useCallback } from "react"

import { ID, Options, UserTraits } from "@segment/analytics-next"

import { useUser } from "src/auth"
import { buildIntegrations } from "src/tracking/buildIntegrations"

import { useAnalytics } from "../tracking/useAnalytics"

function consoleIdentify(
  id?: object | ID,
  traits?: UserTraits | null,
  options?: Options,
) {
  const args = {
    id,
    traits,
    options,
  }

  // eslint-disable-next-line no-console
  console.debug("%cIDENTIFY", "font-weight: bold; color: darkCyan", args)
}

export default function useIdentify() {
  const analytics = useAnalytics()
  const user = useUser()

  return useCallback(
    async ({
      id,
      traits,
      options,
    }: {
      id?: object | ID
      traits?: UserTraits | null
      options?: Options
    }) => {
      const identify = analytics?.identify
      if (identify == null) {
        return
      }
      await identify(id ?? user?.id, traits, {
        ...options,
        integrations: buildIntegrations(),
      })
      consoleIdentify(id, traits, options)
    },
    [analytics, user?.id],
  )
}
