import { FileRoutesByPath, Link } from "@tanstack/react-router"
import { clsx } from "clsx"

import { GlowIcon } from "src/glow"
import useTracking from "src/tracking/useTracking"

import { Logo as LogoComponent } from "../Logo/Logo"

type HeaderProps = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLElement>,
  HTMLElement
> & {
  // Elements to be included _below_ the header
  below?: React.ReactNode
  background?: "white" | "nous-glow" | "transparent"
  sticky?: boolean
}

export const Header = ({
  children,
  className,
  below,
  background = "white",
  sticky = true,
  ...props
}: HeaderProps) => {
  return (
    <div className={clsx("top-0 z-header", sticky && "sticky")}>
      <header
        className={clsx(
          "flex h-12 w-full flex-col md:h-16",
          background === "white" && "bg-white",
          background === "nous-glow" && "bg-nous-glow-400",
          background === "transparent" && "bg-transparent",
          className,
        )}
        {...props}
      >
        <div className="mx-auto flex h-full w-full max-w-7xl items-center justify-between px-4">
          {children}
        </div>
      </header>
      {below}
    </div>
  )
}

const Logo = ({
  render = true,
  linkTo,
}: {
  render?: boolean
  linkTo?: keyof FileRoutesByPath
}) => {
  const track = useTracking()
  const contents = (
    <div className="flex items-start">
      <LogoComponent className="h-5 md:h-6" />
      {render && (
        // eslint-disable-next-line formatjs/no-literal-string-in-jsx
        <span className="ml-1.5 -mt-1 rounded bg-gray-100 p-1 font-bold text-gray-500/64 text-xs">
          BETA
        </span>
      )}
    </div>
  )

  if (linkTo) {
    return (
      <Link
        to={linkTo}
        onClick={() => track(["Navigation", "Clicked", { to: linkTo }])}
      >
        {contents}
      </Link>
    )
  }

  return <>{contents}</>
}

type EmailProps = {
  email: string
}
const Email = ({ email }: EmailProps) => {
  return <p className="inline-flex text-gray-500/64 text-base">{email}</p>
}

type TimeEstimateProps = {
  minutes: string
}

function TimeEstimate({ minutes }: TimeEstimateProps) {
  return (
    <span className="inline-flex">
      <GlowIcon name="clock_circle_1_regular" className="mr-2 h-5 w-5" />
      <span className="hidden md:mr-1 md:inline">You&apos;ll be done in</span>
      {` ${minutes} `}
      minutes
    </span>
  )
}

type SecureIconProps = {
  iconText: string
}

function SecureIcon({ iconText }: SecureIconProps) {
  return (
    <span className="inline-flex">
      <GlowIcon name="lock_shield_bold" className="mr-2 w-5" />
      <span className="hidden md:mr-1 md:inline" data-testid="secure-payment">
        {iconText}
      </span>
    </span>
  )
}

Header.Logo = Logo
Header.Email = Email
Header.TimeEstimate = TimeEstimate
Header.SecureIcon = SecureIcon
