/**
 * @generated SignedSource<<2c6947fcde9cf2f8a83d3f4b93eb6c64>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type PriceIndexMeasure = "CPI" | "CPIH" | "RPI" | "%future added value";
export type MobilePriceHikeCheckerQuery$variables = Record<PropertyKey, never>;
export type MobilePriceHikeCheckerQuery$data = {
  readonly getProvidersForCategory: ReadonlyArray<{
    readonly id: string;
    readonly logo: string;
    readonly name: string;
    readonly nextContractPriceIncrease: {
      readonly date: string;
      readonly increaseBasedOn: {
        readonly priceChangePercentage: number;
        readonly priceIndexMeasure: PriceIndexMeasure;
        readonly surchargePercentage: number;
      } | null | undefined;
      readonly percentage: number;
    } | null | undefined;
  }>;
};
export type MobilePriceHikeCheckerQuery = {
  response: MobilePriceHikeCheckerQuery$data;
  variables: MobilePriceHikeCheckerQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "Literal",
    "name": "category",
    "value": "MOBILE"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "logo",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "date",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "percentage",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "IncreaseBasedOn",
  "kind": "LinkedField",
  "name": "increaseBasedOn",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "priceIndexMeasure",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "surchargePercentage",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "priceChangePercentage",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "MobilePriceHikeCheckerQuery",
    "selections": [
      {
        "alias": null,
        "args": (v0/*: any*/),
        "concreteType": "Provider",
        "kind": "LinkedField",
        "name": "getProvidersForCategory",
        "plural": true,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "PriceIncrease",
            "kind": "LinkedField",
            "name": "nextContractPriceIncrease",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": "getProvidersForCategory(category:\"MOBILE\")"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "MobilePriceHikeCheckerQuery",
    "selections": [
      {
        "alias": null,
        "args": (v0/*: any*/),
        "concreteType": "Provider",
        "kind": "LinkedField",
        "name": "getProvidersForCategory",
        "plural": true,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "PriceIncrease",
            "kind": "LinkedField",
            "name": "nextContractPriceIncrease",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v1/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": "getProvidersForCategory(category:\"MOBILE\")"
      }
    ]
  },
  "params": {
    "cacheID": "5a4e3bdb332c1a0853f8d85fc04e446a",
    "id": null,
    "metadata": {},
    "name": "MobilePriceHikeCheckerQuery",
    "operationKind": "query",
    "text": "query MobilePriceHikeCheckerQuery {\n  getProvidersForCategory(category: MOBILE) {\n    id\n    name\n    logo\n    nextContractPriceIncrease {\n      date\n      percentage\n      increaseBasedOn {\n        priceIndexMeasure\n        surchargePercentage\n        priceChangePercentage\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "342b31cfaede24b1d63b00d270d82cc3";

export default node;
