import { createFileRoute, lazyRouteComponent } from "@tanstack/react-router"
import { loadQuery } from "react-relay"

import YourHouseholdPageQuery, {
  YourHouseholdPageQuery as YourHouseholdPageQueryType,
} from "src/pages/YourHouseholdPage/__generated__/YourHouseholdPageQuery.graphql"
import YourHouseholdPageLayout from "src/pages/YourHouseholdPage/YourHouseholdPageLayout"
import requireIsLoggedIn from "src/routeUtils/requireIsLoggedIn"
export const Route = createFileRoute("/your-household")({
  pendingComponent: YourHouseholdPageLayout,
  beforeLoad: requireIsLoggedIn({ redirectTo: "/login" }),
  loader: ({ context }) =>
    loadQuery<YourHouseholdPageQueryType>(
      context.relayEnvironment(),
      YourHouseholdPageQuery,
      {},
    ),
  component: lazyRouteComponent(
    () => import("src/pages/YourHouseholdPage/YourHouseholdPage"),
  ),
})
