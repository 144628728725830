import {
  createFileRoute,
  lazyRouteComponent,
  redirect,
} from "@tanstack/react-router"
import { z } from "zod"
const searchSchema = z.object({
  oobCode: z.string().optional().catch(undefined),
})
export const Route = createFileRoute("/reset-password")({
  beforeLoad: ({ context, search }) => {
    if (context.authState.isAuthenticated) {
      throw redirect({ to: "/your-household" })
    }
    if (!search.oobCode) {
      throw redirect({ to: "/login" })
    }
  },
  validateSearch: searchSchema,
  component: lazyRouteComponent(
    () => import("src/pages/ResetPasswordPage/ResetPasswordPage"),
  ),
})
