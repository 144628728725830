import { useEffect, useRef } from "react"

import { useRouterState } from "@tanstack/react-router"

export default function useEffectAfterRouterTransition(
  callback: (path: string) => unknown,
) {
  const state = useRouterState()
  const firedOnce = useRef<boolean>(false)

  useEffect(() => {
    if (
      !firedOnce.current &&
      state.isTransitioning === false &&
      state.status === "idle"
    ) {
      callback(state.location.pathname)
      firedOnce.current = true
    }
  }, [callback, state.isTransitioning, state.location.pathname, state.status])
}
