import { createFileRoute, redirect } from "@tanstack/react-router"

import LoadingPagePlaceholder from "src/LoadingPagePlaceholder"

export const Route = createFileRoute("/onboard/create-account")({
  beforeLoad: ({ search }) => {
    throw redirect({
      to: "/sign-up",
      search,
    })
  },
  component: LoadingPagePlaceholder,
})
