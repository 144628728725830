import React from "react"

import clsx from "clsx"

import {
  GlowResponsiveSpacing,
  GlowResponsiveWrapper,
  glowFlexDirectionToClassNames,
  glowGapToClassNames,
  glowSpacingToClassNames,
  GlowFlexDirectionTokens,
  GlowAlignItemsTokens,
  glowAlignItemsToClassNames,
  GlowJustifyContentTokens,
  glowJustifyContentToClassNames,
  GlowSpacingTokens,
} from "./structure"

type CommonProps = {
  children?: React.ReactNode
  direction?: GlowResponsiveWrapper<GlowFlexDirectionTokens>
  gap?: GlowResponsiveWrapper<GlowSpacingTokens>
  justifyContent?: GlowResponsiveWrapper<GlowJustifyContentTokens>
  alignItems?: GlowResponsiveWrapper<GlowAlignItemsTokens>
  alignSelf?: "stretch" | "center"
  grow?: number
  shrink?: number
  basis?: string
  className?: string
  padding?: GlowResponsiveSpacing
  margin?: GlowResponsiveSpacing
  innerRef?: React.Ref<HTMLDivElement>
}

type GlowFlexboxProps = CommonProps &
  (
    | ({
        as?: never
      } & React.ComponentPropsWithoutRef<"div">)
    | ({
        as: "fieldset"
      } & React.ComponentPropsWithoutRef<"fieldset">)
    | ({
        as: "button"
      } & React.ComponentPropsWithoutRef<"button">)
    | ({
        as: "div"
      } & React.ComponentPropsWithoutRef<"div">)
    | ({
        as: "span"
      } & React.ComponentPropsWithoutRef<"span">)
    | ({
        as: "ul"
      } & React.ComponentPropsWithoutRef<"ul">)
    | ({
        as: "header"
      } & React.ComponentPropsWithoutRef<"header">)
  )

export function GlowFlexbox(props: GlowFlexboxProps) {
  const commonProps = {
    style: {
      flexGrow: props.grow,
      flexShrink: props.shrink,
      flexBasis: props.basis,
      alignSelf: props.alignSelf,
    },
    className: clsx(
      "flex",
      ...glowSpacingToClassNames(props.padding ?? {}, "p"),
      ...glowSpacingToClassNames(props.margin ?? {}, "m"),
      ...(props.className?.split(" ").some((x) => x.startsWith("gap-"))
        ? []
        : glowGapToClassNames(props.gap)),
      ...glowFlexDirectionToClassNames(props.direction),
      ...glowAlignItemsToClassNames(props.alignItems),
      ...glowJustifyContentToClassNames(props.justifyContent),
      props.className,
    ),
  }
  if (props.as === "button") {
    const {
      justifyContent: _justifyContent,
      grow: _grow,
      shrink: _shrink,
      alignSelf: _alignSelf,
      padding: _padding,
      margin: _margin,
      gap: _gap,
      direction: _direction,
      alignItems: _alignItems,
      className: _className,
      as: _as,
      ...rest
    } = props
    const C = props.as
    return (
      <C {...commonProps} {...rest}>
        {props.children}
      </C>
    )
  }
  if (props.as === "fieldset") {
    const {
      justifyContent: _justifyContent,
      grow: _grow,
      shrink: _shrink,
      alignSelf: _alignSelf,
      padding: _padding,
      margin: _margin,
      gap: _gap,
      direction: _direction,
      alignItems: _alignItems,
      className: _className,
      as: _as,
      ...rest
    } = props
    const C = props.as
    return (
      <C {...commonProps} {...rest}>
        {props.children}
      </C>
    )
  }
  if (props.as === "span" || props.as === "ul") {
    const {
      justifyContent: _justifyContent,
      grow: _grow,
      shrink: _shrink,
      alignSelf: _alignSelf,
      padding: _padding,
      margin: _margin,
      gap: _gap,
      direction: _direction,
      alignItems: _alignItems,
      className: _className,
      as: _as,
      ...rest
    } = props
    const C = props.as
    return (
      <C {...commonProps} {...rest}>
        {props.children}
      </C>
    )
  }
  const {
    justifyContent: _justifyContent,
    grow: _grow,
    shrink: _shrink,
    alignSelf: _alignSelf,
    padding: _padding,
    margin: _margin,
    gap: _gap,
    direction: _direction,
    alignItems: _alignItems,
    className: _className,
    innerRef: innerRef,
    as: _as,
    ...rest
  } = props
  return (
    <div {...commonProps} {...rest} ref={innerRef}>
      {props.children}
    </div>
  )
}
