import { useMemo } from "react"

import { tracker } from "@nous/tracking"
import { Options } from "@segment/analytics-next"

import useTrackingInternal from "./useTrackingInternal"

export default function useTrackingWithoutLocation() {
  const internalTrack = useTrackingInternal()
  return useMemo(
    () =>
      tracker(({ event, properties }, options: Options | undefined) =>
        internalTrack(event, properties, options),
      ).track,
    [internalTrack],
  )
}
