import React from "react"

import { graphql, useLazyLoadQuery } from "react-relay"

import { GlowText } from "src/glow"

import { AddressNavQuery as AddressNavQueryType } from "./__generated__/AddressNavQuery.graphql"

import { LoadingDots } from "../LoadingDots/LoadingDots"

export const AddressNavQuery = graphql`
  query AddressNavQuery {
    household: maybeHousehold {
      address {
        firstLine
      }
    }
  }
`

function AddressItem() {
  const data = useLazyLoadQuery<AddressNavQueryType>(AddressNavQuery, {})

  return (
    <GlowText isDisplay fontWeight="medium">
      {data.household?.address?.firstLine}
    </GlowText>
  )
}

export default function AddressNav() {
  return (
    <>
      {
        <React.Suspense fallback={<LoadingDots />}>
          <AddressItem />
        </React.Suspense>
      }
    </>
  )
}
