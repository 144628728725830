import { createFileRoute, lazyRouteComponent } from "@tanstack/react-router"
import { loadQuery } from "react-relay"

import MobilePriceHikeCheckerQuery, {
  MobilePriceHikeCheckerQuery as MobilePriceHikeCheckerQueryType,
} from "src/tools/MobilePriceHikeChecker/__generated__/MobilePriceHikeCheckerQuery.graphql"

export const Route = createFileRoute("/widgets/mobile-hikes")({
  loader: ({ context }) =>
    loadQuery<MobilePriceHikeCheckerQueryType>(
      context.relayEnvironment(),
      MobilePriceHikeCheckerQuery,
      {},
    ),
  component: lazyRouteComponent(
    () =>
      import(
        "src/pages/Widgets/MobilePriceHikeCheckerPage/MobilePriceHikeCheckerPage"
      ),
  ),
})
