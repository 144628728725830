/**
 * @generated SignedSource<<2cb3615ca119a643143c6ba19f1715f0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type WaitlistPageQuery$variables = Record<PropertyKey, never>;
export type WaitlistPageQuery$data = {
  readonly household: {
    readonly address: {
      readonly firstLine: string | null | undefined;
    } | null | undefined;
    readonly inWaitlist: boolean | null | undefined;
  };
  readonly user: {
    readonly email: string | null | undefined;
    readonly fullName: string | null | undefined;
  } | null | undefined;
};
export type WaitlistPageQuery = {
  response: WaitlistPageQuery$data;
  variables: WaitlistPageQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "inWaitlist",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "concreteType": "HouseholdAddress",
  "kind": "LinkedField",
  "name": "address",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "firstLine",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "fullName",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "WaitlistPageQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Household",
        "kind": "LinkedField",
        "name": "household",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": "user",
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "currentUser",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "WaitlistPageQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Household",
        "kind": "LinkedField",
        "name": "household",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          (v4/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": "user",
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "currentUser",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "cb3048ba914ebddd63cd05e2a10a4c0d",
    "id": null,
    "metadata": {},
    "name": "WaitlistPageQuery",
    "operationKind": "query",
    "text": "query WaitlistPageQuery {\n  household {\n    inWaitlist\n    address {\n      firstLine\n    }\n    id\n  }\n  user: currentUser {\n    email\n    fullName\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "71302d504854f1ba3681dad92e635c59";

export default node;
