import React, { ReactNode, useEffect } from "react"

import { useUser } from "src/auth"

export const HubspotProvider = ({ children }: { children: ReactNode }) => {
  const user = useUser()
  useEffect(() => {
    if (__NOUS_ENV__ === "production" && user) {
      const script = document.createElement("script")
      script.async = true
      script.defer = true
      script.src = "//js-eu1.hs-scripts.com/139765111.js"
      script.type = "text/javascript"
      script.id = "hs-script-loader"
      document.head.appendChild(script)
    }
  }, [user])

  return <>{children}</>
}
