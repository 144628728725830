import React from "react"

import { createRootRouteWithContext, Outlet } from "@tanstack/react-router"
import { Environment } from "react-relay"

import { User } from "src/auth"

type RouteContextAuthState =
  | {
      isAuthenticated: true
      user: User
    }
  | {
      isAuthenticated: false
      user: null
    }

export type RouteContext = {
  relayEnvironment(): Environment
  authState: RouteContextAuthState
  checkGate(gateName: string): boolean
}

export const Route = createRootRouteWithContext<RouteContext>()({
  component: () => <Outlet />,
})
