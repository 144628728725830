import { Schema } from "./schema"
import { EventObjects, EventActions, EventTuple } from "./types"

export type TrackerFn<TOptions, TResult> = (
  eventWithProperties: { event: string; properties: object; isValid: boolean },
  options: TOptions | undefined,
) => TResult

export declare class Tracker<TSchema extends Schema<any>, TOptions, TResult> {
  schema: TSchema
  track<
    TObject extends EventObjects<TSchema["definitions"]>,
    TAction extends EventActions<TSchema["definitions"], TObject>,
  >(
    event: EventTuple<TSchema["definitions"], TObject, TAction>,
    options?: TOptions,
  ): TResult
}

export function tracker<TSchema extends Schema<any>, TOptions, TResult>(
  schema: TSchema,
  tracker: TrackerFn<TOptions, TResult>,
): Tracker<TSchema, TOptions, TResult> {
  return {
    schema,
    track(event, options) {
      const [object, action, properties] = event
      return tracker(
        {
          event: `${String(object)} ${String(action)}`,
          properties: properties ?? {},
          isValid: schema.isValidEvent(event),
        },
        options,
      )
    },
  }
}
