/**
 * @generated SignedSource<<d4e604e0527eec2210310659eb9e2169>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type InviteModalContentCancelInviteMutation$variables = {
  id: string;
};
export type InviteModalContentCancelInviteMutation$data = {
  readonly cancelHouseholdInviteV2: {
    readonly household: {
      readonly pendingInvites: ReadonlyArray<{
        readonly id: string;
      }>;
    } | null | undefined;
  } | null | undefined;
};
export type InviteModalContentCancelInviteMutation = {
  response: InviteModalContentCancelInviteMutation$data;
  variables: InviteModalContentCancelInviteMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "HouseholdMemberInvite",
  "kind": "LinkedField",
  "name": "pendingInvites",
  "plural": true,
  "selections": [
    (v2/*: any*/)
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "InviteModalContentCancelInviteMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CancelHouseholdInviteV2Response",
        "kind": "LinkedField",
        "name": "cancelHouseholdInviteV2",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Household",
            "kind": "LinkedField",
            "name": "household",
            "plural": false,
            "selections": [
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "InviteModalContentCancelInviteMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CancelHouseholdInviteV2Response",
        "kind": "LinkedField",
        "name": "cancelHouseholdInviteV2",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Household",
            "kind": "LinkedField",
            "name": "household",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "d4404ea77a0c1258b29f7e31a336da93",
    "id": null,
    "metadata": {},
    "name": "InviteModalContentCancelInviteMutation",
    "operationKind": "mutation",
    "text": "mutation InviteModalContentCancelInviteMutation(\n  $id: ID!\n) {\n  cancelHouseholdInviteV2(id: $id) {\n    household {\n      pendingInvites {\n        id\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "35d3b5ff5e54f052764e12988eb12ed9";

export default node;
