export const breakpointsList = ["xs", "sm", "md", "lg", "xl", "xxl"] as const

//Sorts object keys based on the order of the values in the keysOrder array
export function sortObjectKeys<TKey extends string, TValue>(
  obj: Partial<Record<TKey, TValue>>,
  keysOrder: readonly TKey[],
) {
  const sortedKeys: TKey[] = (Object.keys(obj) as TKey[]).sort((a, b) => {
    return keysOrder.indexOf(a) - keysOrder.indexOf(b)
  })

  const sortedObject: Partial<Record<TKey, TValue>> = {}
  for (const key of sortedKeys) {
    sortedObject[key] = obj[key]
  }

  return sortedObject
}

export type GlowBreakpoint = (typeof breakpointsList)[number]

type PropByBreakpoint<PropValue> = { [key in GlowBreakpoint]?: PropValue }
type ResponsiveProp<PropValue> = PropValue | PropByBreakpoint<PropValue>

export const isPropByBreakpoint = <PropValue>(
  value: ResponsiveProp<PropValue>,
): value is PropByBreakpoint<PropValue> => {
  return (
    typeof value === "object" &&
    value !== null &&
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    breakpointsList.includes(Object.keys(value)[0] as any)
  )
}
