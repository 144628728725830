import {
  createFileRoute,
  lazyRouteComponent,
  redirect,
} from "@tanstack/react-router"
import { z } from "zod"

const searchSchema = z
  .object({
    token: z.string(),
    type: z.string().optional(),
    subject: z.string().optional(),
  })
  .or(
    z.object({
      token: z.undefined(),
    }),
  )

export const Route = createFileRoute("/t")({
  validateSearch: searchSchema,
  beforeLoad: (args) => {
    if (!args.search.token) {
      throw redirect({ to: "/" })
    }
  },
  component: lazyRouteComponent(
    () => import("src/pages/TokenLoginPage/TokenLoginPage"),
  ),
})
