import { Action } from "./action"
import { BasePropertiesType, Properties, noProperties } from "./properties"

export type BaseActionsType = { [k: string]: Action<BasePropertiesType> }

export declare class Object<
  TActions extends BaseActionsType,
  TProperties extends BasePropertiesType,
> {
  actions: TActions
  properties: Properties<TProperties>
}

export function object<
  TActions extends BaseActionsType,
  TProperties extends Record<string, any>,
>({
  actions,
  properties,
}: {
  actions: TActions
  properties: Properties<TProperties>
}): Object<TActions, TProperties>

export function object<TActions extends BaseActionsType>({
  actions,
  properties,
}: {
  actions: TActions
  properties?: never
}): Object<TActions, undefined>

export function object<
  TActions extends BaseActionsType,
  TProperties extends BasePropertiesType,
>({
  actions,
  properties,
}: {
  actions: TActions
  properties?: Properties<TProperties>
}): Object<TActions, TProperties | undefined> {
  return { actions, properties: properties ?? noProperties() }
}
