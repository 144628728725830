import { createFileRoute, lazyRouteComponent } from "@tanstack/react-router"
import { loadQuery } from "react-relay"

import { QuestPageLayout } from "src/pages/QuestPage/components/QuestPageLayout"
import TaskPageQuery, {
  TaskPageQuery as TaskPageQueryType,
} from "src/pages/TaskPage/__generated__/TaskPageQuery.graphql"

import requireIsLoggedIn from "../routeUtils/requireIsLoggedIn"

export const Route = createFileRoute("/quests/$questId/tasks/$taskId")({
  beforeLoad: requireIsLoggedIn({ redirectTo: "/login" }),
  pendingComponent: QuestPageLayout,
  loader: ({ context, params }) =>
    loadQuery<TaskPageQueryType>(context.relayEnvironment(), TaskPageQuery, {
      id: params.taskId,
    }),
  component: lazyRouteComponent(() => import("src/pages/TaskPage/TaskPage")),
})
