import React from "react"
import { useMemo, useState } from "react"

import clsx from "clsx"
import { ErrorBoundary } from "react-error-boundary"
import { FormattedMessage, defineMessage, useIntl } from "react-intl"
import {
  PreloadedQuery,
  graphql,
  usePreloadedQuery,
  useQueryLoader,
} from "react-relay"

import { getStableId, useCheckGate } from "src/Gate"
import {
  GlowFlexbox,
  GlowForm,
  GlowFormInput,
  GlowHorizontalDivider,
  GlowLink,
  GlowText,
} from "src/glow"
import useEmulateUser from "src/hooks/useEmulateUser"
import { useIntlDebugLocaleId } from "src/intl"
import useTrackingWithoutLocation from "src/tracking/useTrackingWithoutLocation"
import { filterNullsAndFalse } from "src/utils"

import { AdminToolbarDebugQuery as AdminToolbarDebugQueryType } from "./__generated__/AdminToolbarDebugQuery.graphql"
import AdminToolbarDebugQuery from "./__generated__/AdminToolbarDebugQuery.graphql"

import { LoadingDots } from "../LoadingDots/LoadingDots"

const DEBUG_PAGE_DETAILS = [
  defineMessage({
    id: "adminToolbar.debugPageTool.userId",
    defaultMessage: "<strong>User ID:</strong>{br}{userId}",
  }),
  defineMessage({
    id: "adminToolbar.debugPageTool.email",
    defaultMessage: "<strong>Email:</strong>{br}{email}",
  }),
  defineMessage({
    id: "adminToolbar.debugPageTool.householdId",
    defaultMessage: "<strong>Household ID:</strong>{br}{householdId}",
  }),
  defineMessage({
    id: "adminToolbar.debugPageTool.householdMemberId",
    defaultMessage:
      "<strong>Household Member ID:</strong>{br}{householdMemberId}",
  }),
  defineMessage({
    id: "adminToolbar.debugPageTool.stableId",
    defaultMessage: "<strong>Stable ID:</strong>{br}{stableId}",
  }),
]

function DebugPageTool(props: {
  query: PreloadedQuery<AdminToolbarDebugQueryType>
}) {
  const stableId = getStableId()
  const data = usePreloadedQuery<AdminToolbarDebugQueryType>(
    graphql`
      query AdminToolbarDebugQuery {
        currentUser {
          id
          email
          householdMember {
            id
          }
        }
        maybeHousehold {
          id
        }
      }
    `,
    props.query,
  )
  const intl = useIntl()
  const na = intl.formatMessage({
    id: "adminToolbar.debugPageTool.na",
    defaultMessage: "N/A",
  })
  return (
    <GlowFlexbox direction="column" gap="1">
      {DEBUG_PAGE_DETAILS.map((detail, i) => (
        <GlowText size={"xs"} key={i}>
          {
            <FormattedMessage
              {...detail}
              values={{
                userId: data.currentUser?.id ?? na,
                email: data.currentUser?.email ?? na,
                householdId: data.maybeHousehold?.id ?? na,
                householdMemberId: data.currentUser?.householdMember?.id ?? na,
                stableId: stableId ?? na,
                strong: (msg) => <GlowText fontWeight="bold">{msg}</GlowText>,
                br: <br />,
              }}
            />
          }
        </GlowText>
      ))}
      <button
        className="border h-8 self-start rounded-lg px-2"
        onClick={() => {
          window.navigator.clipboard.write([
            new ClipboardItem({
              "text/plain": new Blob(
                [
                  DEBUG_PAGE_DETAILS.map((detail) =>
                    intl.formatMessage(detail, {
                      userId: data.currentUser?.id ?? na,
                      email: data.currentUser?.email ?? na,
                      householdId: data.maybeHousehold?.id ?? na,
                      householdMemberId:
                        data.currentUser?.householdMember?.id ?? na,
                      stableId: stableId ?? na,
                      strong: (msg) => `*${msg}*`,
                      br: " ",
                    }),
                  ).join("\n"),
                ],
                {
                  type: "text/plain",
                },
              ),
              "text/html": new Blob(
                [
                  DEBUG_PAGE_DETAILS.map((detail) =>
                    intl.formatMessage(detail, {
                      userId: data.currentUser?.id ?? na,
                      email: data.currentUser?.email ?? na,
                      householdId: data.maybeHousehold?.id ?? na,
                      householdMemberId:
                        data.currentUser?.householdMember?.id ?? na,
                      stableId: stableId ?? na,
                      strong: (msg) => `<b>${msg}</b>`,
                      br: " ",
                    }),
                  ).join("<br />"),
                ],
                {
                  type: "text/html",
                },
              ),
            }),
          ])
        }}
      >
        {intl.formatMessage({
          id: "adminToolbar.debugPageTool.copy",
          defaultMessage: "Copy to Clipboard",
        })}
      </button>
    </GlowFlexbox>
  )
}

function EmulateUsersTool() {
  const track = useTrackingWithoutLocation()
  const { emulatingUserId, isEmulating, setEmulatingUserId, stopEmulating } =
    useEmulateUser()
  const [formState, setFormState] = useState({
    userId: emulatingUserId ?? "",
  })
  const intl = useIntl()
  if (isEmulating) {
    return (
      <GlowFlexbox direction="column" gap="2">
        <GlowText size="sm">
          <FormattedMessage
            id="adminToolbar.emulateUsersTool.emulating"
            defaultMessage="Emulating user <code>{userId}</code>"
            values={{
              userId: emulatingUserId,
              code(msgs) {
                return <code>{msgs}</code>
              },
            }}
          />
        </GlowText>
        <button
          className="border border-angsty-red-400 px-2 py-1 text-sm rounded-lg "
          onClick={stopEmulating}
        >
          <FormattedMessage
            id="adminToolbar.emulateUsersTool.stopEmulating"
            defaultMessage="Stop Emulating"
          />
        </button>
      </GlowFlexbox>
    )
  }
  return (
    <GlowForm
      value={formState}
      onChange={setFormState}
      onSubmit={(formState) => {
        setEmulatingUserId(formState.userId)
        track([
          "User",
          "Emulated",
          {
            userId: formState.userId,
          },
        ])
      }}
    >
      <GlowFlexbox gap="2">
        <GlowFormInput
          label={intl.formatMessage({
            id: "adminToolbar.emulateUsersTool.label",
            defaultMessage: "Emulate User",
          })}
          className="h-8"
          formKey="userId"
          type="text"
          getErrors={() => {
            if (formState.userId.trim() === "") {
              return [
                intl.formatMessage({
                  id: "adminToolbar.emulateUsersTool.required",
                  defaultMessage: "Required",
                }),
              ]
            }
            return []
          }}
          placeholder={intl.formatMessage({
            id: "adminToolbar.emulateUsersTool.placeholder",
            defaultMessage: "User ID",
          })}
        />
        <button className="border h-8 self-start rounded-lg px-2 mt-6">
          {intl.formatMessage({
            id: "adminToolbar.emulateUsersTool.submit",
            defaultMessage: "Go",
          })}
        </button>
      </GlowFlexbox>
    </GlowForm>
  )
}

function ToggleIntlTool() {
  const intl = useIntl()
  const [_debug, setDebug] = useIntlDebugLocaleId()
  return (
    <button
      className="border h-8 rounded-lg px-2"
      onClick={() =>
        setDebug((prev) => ({ ...prev, debugLocaleId: !prev.debugLocaleId }))
      }
    >
      {intl.formatMessage({
        id: "adminToolbar.emulateUsersTool.toggleIntl",
        defaultMessage: "Toggle Intl Debug",
      })}
    </button>
  )
}

function CheckGateTool() {
  const intl = useIntl()
  const [formState, setFormState] = useState({
    gate: "debug_page",
  })
  const checkGate = useCheckGate()
  const gate = useMemo(() => {
    return formState.gate && checkGate(formState.gate)
  }, [formState.gate, checkGate])
  return (
    <GlowForm value={formState} onChange={setFormState} onSubmit={() => {}}>
      <GlowFlexbox direction="column" gap="2">
        <GlowFlexbox gap="2">
          <GlowFormInput
            label={intl.formatMessage({
              id: "adminToolbar.checkGateTool.label",
              defaultMessage: "Check gate for current user",
            })}
            className="h-8"
            formKey="gate"
            type="text"
          />
          <button className="border h-8 self-start rounded-lg px-2 mt-6">
            {intl.formatMessage({
              id: "adminToolbar.checkGateTool.submit",
              defaultMessage: "Go",
            })}
          </button>
        </GlowFlexbox>
        <GlowText
          fontWeight="bold"
          textAlign="center"
          size="xs"
          padding={{ x: "2", y: "1" }}
          className={clsx(
            gate
              ? "bg-virtuous-green-500 text-white"
              : "bg-angsty-red-500 text-white",
          )}
        >
          {intl.formatMessage(
            gate
              ? {
                  id: "adminToolbar.checkGateTool.passed",
                  defaultMessage: "Passed",
                }
              : {
                  id: "adminToolbar.checkGateTool.failed",
                  defaultMessage: "Failed",
                },
          )}
        </GlowText>
      </GlowFlexbox>
    </GlowForm>
  )
}

function InnerAdminToolbar() {
  const checkGate = useCheckGate()
  const [ref, load, dispose] = useQueryLoader<AdminToolbarDebugQueryType>(
    AdminToolbarDebugQuery,
  )
  const adminTools = filterNullsAndFalse([
    checkGate("debug_page") && (
      <React.Fragment>
        {ref && <DebugPageTool key="debug_page_tool" query={ref} />}
      </React.Fragment>
    ),
    checkGate("debug_page") && <CheckGateTool key="checkGateTool" />,
    checkGate("debug_page") && __NOUS_ENV__ !== "production" && (
      <ToggleIntlTool key="toggleIntl" />
    ),
    checkGate("emulate_users") && <EmulateUsersTool key="enumate_tool" />,
  ])
  const intl = useIntl()

  if (adminTools.length === 0) {
    return null
  }

  return (
    <GlowFlexbox
      className="fixed bottom-2 left-2 shadow-lg rounded-lg bg-white"
      direction="column"
      alignItems="start"
    >
      {ref ? (
        <GlowFlexbox
          direction="column"
          margin={{ y: "2" }}
          alignItems="start"
          gap="2"
          className="w-64"
        >
          <React.Suspense
            fallback={
              <GlowFlexbox alignSelf="center">
                <LoadingDots />
              </GlowFlexbox>
            }
          >
            {adminTools.map((tool, i) => (
              <React.Fragment key={i}>
                <div className="mx-2">{tool}</div>
                <GlowHorizontalDivider className="self-stretch" />
              </React.Fragment>
            ))}
          </React.Suspense>
          <GlowLink
            className="self-center"
            label={intl.formatMessage({
              id: "adminToolbar.close",
              defaultMessage: "Close",
            })}
            onClick={() => dispose()}
          />
        </GlowFlexbox>
      ) : (
        <button
          className="rounded-lg bg-nous-glow-400 text-white p-2 "
          onClick={() => load({})}
        >
          {intl.formatMessage({
            id: "adminToolbar.title",
            defaultMessage: "Admin Tools",
          })}
        </button>
      )}
    </GlowFlexbox>
  )
}

export default function AdminToolbar() {
  return (
    <ErrorBoundary fallback={<>😢</>}>
      <React.Suspense fallback={null}>
        <InnerAdminToolbar />
      </React.Suspense>
    </ErrorBoundary>
  )
}
