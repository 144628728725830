import React from "react"

import {
  FileRoutesByPath,
  Link as RouterLink,
  useNavigate,
} from "@tanstack/react-router"
import { useIntl } from "react-intl"

import { useAuthActions } from "src/auth"
import { useCheckGate } from "src/Gate"
import { GlowFlexbox, GlowIcon, GlowText } from "src/glow"
import useTracking from "src/tracking/useTracking"
import { filterNullsAndFalse } from "src/utils"

import AddressNav from "./AddressNav"
import NavAvatar from "./NavAvatar"
import NotificationNav from "./NotificationNav"

import InviteMemberButton from "../InviteMemberButton/InviteMemberButton"
import { Menu } from "../Menu/Menu"

type LinkProps = React.PropsWithChildren<{
  to: keyof FileRoutesByPath
}>
const Link = ({ to, children }: LinkProps) => {
  const track = useTracking()
  return (
    <RouterLink
      className="flex h-full items-center text-gray-500/64 hover:text-gray-500"
      activeProps={{
        className: "!text-gray-500 shadow-bottom-glow",
      }}
      to={to}
      onClick={() => {
        track(["Navigation", "Clicked", { to }])
      }}
    >
      {children}
    </RouterLink>
  )
}
const NavButton = () => {
  return (
    <div
      className="flex items-center space-x-2 group"
      data-testid="desktop-menu-button"
    >
      <React.Suspense
        fallback={
          <div className="rounded-full bg-gray-100 w-10 h-10 overflow-hidden" />
        }
      >
        <NavAvatar background="yellow" />
      </React.Suspense>
      <GlowIcon
        name="arrow_down_1_regular"
        className="w-4 h-4 text-off-black-64 group-hover:text-gray-500"
      />
    </div>
  )
}

export const DesktopNav = () => {
  const { logOut } = useAuthActions()
  const intl = useIntl()
  const track = useTracking()
  const navigate = useNavigate()
  const checkGate = useCheckGate()
  const navLinks = filterNullsAndFalse([
    <Link key={"/"} to={"/"}>
      <p className="text-base">
        {intl.formatMessage({
          defaultMessage: "Home",
          id: "nav.home",
        })}
      </p>
    </Link>,
    <Link key={"savings"} to={"/savings"}>
      <p className="text-base">
        {intl.formatMessage({
          defaultMessage: "Savings",
          id: "nav.savings",
        })}
      </p>
    </Link>,

    <Link key={"tasks"} to={"/quests/"}>
      <p className="text-base">
        {intl.formatMessage({
          defaultMessage: "To-do",
          id: "nav.tasks",
        })}
      </p>
    </Link>,

    <Link key={"documents"} to={"/documents/"}>
      <p className="text-base">
        {intl.formatMessage({
          defaultMessage: "Documents",
          id: "nav.documents",
        })}
      </p>
    </Link>,

    checkGate("invite_2nd_household_journey") && (
      <div key={"inviteMember"} className="ml-auto">
        <InviteMemberButton />
      </div>
    ),
  ])

  const notificationsAndMenu = (
    <GlowFlexbox gap="4" alignItems="center" justifyContent="center">
      <NotificationNav />
      <Menu position="right" button={<NavButton />}>
        <Menu.Item
          onClick={() =>
            navigate({
              to: "/your-household",
            })
          }
        >
          <GlowIcon name="cog_regular" className="w-4 h-4" />
          <GlowText as="p">
            {intl.formatMessage({
              defaultMessage: "Household settings",
              id: "nav.desktopDropdownMenu.householdSettings",
            })}
          </GlowText>
        </Menu.Item>

        <Menu.Item
          onClick={async () => {
            await logOut()
          }}
        >
          <GlowIcon
            name="logout_1_regular"
            className="w-4 h-4 text-angsty-red-500"
          />
          <GlowText
            as="p"
            className="text-angsty-red-500"
            data-testid="desktop-logout-button"
          >
            {intl.formatMessage({
              defaultMessage: "Logout",
              id: "nav.desktopDropdownMenu.logout",
            })}
          </GlowText>
        </Menu.Item>
      </Menu>
    </GlowFlexbox>
  )

  return (
    <GlowFlexbox as="header" direction="column" className="bg-white">
      <GlowFlexbox className="border-b border-off-black-12 z-header">
        <GlowFlexbox
          alignItems="center"
          justifyContent="space-between"
          className="h-16 mx-auto w-full max-w-7xl px-4"
        >
          <RouterLink
            to={"/"}
            onClick={() => track(["Navigation", "Clicked", { to: "/" }])}
          >
            <GlowFlexbox alignItems="start">
              <GlowIcon name="nous_logo" className="w-[97px] h-6" />
              <GlowText
                size="xs"
                fontWeight="bold"
                className="ml-1.5 -mt-1 rounded bg-gray-100 px-1 py-[2px] uppercase text-off-black-64"
              >
                {intl.formatMessage({
                  defaultMessage: "BETA",
                  id: "nav.logo.beta",
                })}
              </GlowText>
            </GlowFlexbox>
          </RouterLink>

          <AddressNav />

          {notificationsAndMenu}
        </GlowFlexbox>
      </GlowFlexbox>
      <GlowFlexbox
        alignItems="center"
        justifyContent="start"
        gap="8"
        className="h-16 mx-auto w-full max-w-7xl px-4"
      >
        {navLinks}
      </GlowFlexbox>
    </GlowFlexbox>
  )
}
