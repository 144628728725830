import { createFileRoute, lazyRouteComponent } from "@tanstack/react-router"
import { loadQuery } from "react-relay"
import { z } from "zod"

import LoadingPagePlaceholder from "src/LoadingPagePlaceholder"
import OnboardingPageQuery, {
  OnboardingPageQuery as OnboardingPageQueryType,
} from "src/pages/Onboarding/__generated__/OnboardingPageQuery.graphql"

const searchSchema = z.object({
  organizationInvite: z.string().optional(),
  organization: z.string().optional(),
  session: z.string().optional(),
})

export const Route = createFileRoute("/onboarding/")({
  pendingComponent: LoadingPagePlaceholder,
  validateSearch: searchSchema,
  loaderDeps: ({ search: { organizationInvite, organization, session } }) => ({
    organizationInviteId: organizationInvite,
    organizationId: organization,
    organizationOnboardingSessionId: session,
  }),
  loader: ({
    context,
    deps: {
      organizationInviteId,
      organizationId,
      organizationOnboardingSessionId,
    },
  }) =>
    loadQuery<OnboardingPageQueryType>(
      context.relayEnvironment(),
      OnboardingPageQuery,
      {
        skipQuery: !context.authState.isAuthenticated,
        organizationInviteId: organizationInviteId ?? "",
        skipOrganizationInvite: !organizationInviteId,
        organizationId: organizationId ?? "",
        skipOrganization: !organizationId,
        organizationOnboardingSessionId: organizationOnboardingSessionId ?? "",
        skipOrganizationOnboardingSession: !organizationOnboardingSessionId,
      },
    ),
  component: lazyRouteComponent(
    () => import("src/pages/Onboarding/OnboardingPage"),
  ),
})
