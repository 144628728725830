import { format } from "date-fns"
import Dinero from "dinero.js"
import { MessageDescriptor, defineMessage } from "react-intl"
import { UseMutationConfig } from "react-relay"
import { Disposable, MutationParameters } from "relay-runtime"

import { logger } from "src/logger"

import { GlowIconName } from "./glow/GlowIcon"
import { SavingsQuest, savingsQuests } from "./types"

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function match<T, K extends keyof any>(k: K, cases: { [P in K]: T }): T {
  return cases[k]
}

export function minBy<T>(
  elements: T[],
  fn: (element: T) => number,
): T | undefined {
  const mapped = elements.map(fn)
  const min = Math.min(...mapped)
  if (isFinite(min)) {
    return elements[mapped.indexOf(min)]
  }
}

export function maxBy<T>(
  elements: T[],
  fn: (element: T) => number,
): T | undefined {
  const mapped = elements.map(fn)
  const max = Math.max(...mapped)
  if (isFinite(max)) {
    return elements[mapped.indexOf(max)]
  }
}

type PartitionReturn<T> = { true: T[]; false: T[] }

export function partition<T>(
  elements: T[],
  predicate: (element: T) => boolean,
): PartitionReturn<T> {
  const out: PartitionReturn<T> = { true: [], false: [] }
  for (const element of elements) {
    if (predicate(element)) {
      out.true.push(element)
    } else {
      out.false.push(element)
    }
  }
  return out
}

export function formatDateTime(dateString: string, fmt = "MMM yy") {
  // `new Date` could bork here, don't let it crash the whole app!
  try {
    return format(new Date(dateString), fmt)
  } catch (error) {
    logger.error("Error formatting datetime", {
      error,
      dateString,
      format,
    })
    return ""
  }
}

export function getOutcodeFromPostcode(postcode: string): string {
  return postcode.replace(" ", "").slice(0, -3)
}

export const getCalendarMonth = (date: Date) => {
  // month is indexed from 0
  return date.getMonth() + 1
}

export const formatMoneyAmount = ({
  amount,
  precision,
  currency,
}: {
  amount: number
  precision?: number
  currency?: Dinero.Currency
}) => {
  return Dinero({
    amount,
    precision,
    currency,
  }).toFormat("$0,0")
}

export function filterNulls<T>(input: Array<T | null | undefined>): T[] {
  return input.filter(Boolean) as T[]
}
export function filterNullsAndFalse<T>(
  input: Array<T | null | undefined | false>,
): T[] {
  return input.filter(Boolean) as T[]
}

/**
 * Truncate a string of text to a given length with a word boundary.
 * @example
 * ```
 * truncate("Hello world", 5)
 * Returns: "Hello..."
 * ```
 * @example
 * ```
 * truncate("Hello world", 6, false)
 * Returns: "Hello ..."
 * ```
 */
export const truncate = (
  str: string,
  maxLength = 20,
  withWordBoundary = true,
) => {
  if (str.length <= maxLength) {
    return str
  }
  const subString = str.slice(0, maxLength)
  return `${
    withWordBoundary && subString.lastIndexOf(" ") !== -1
      ? subString.slice(0, subString.lastIndexOf(" "))
      : subString
  }\u2026`
}

export type CheckedRelayEnum<T extends string> = Exclude<
  T,
  "%future added value"
>

export function handleFutureValueOnRelayEnum<T extends string>(
  value: T | null | undefined,
  defaultValue: CheckedRelayEnum<T>,
): CheckedRelayEnum<T> {
  return (
    !value || value === "%future added value" ? defaultValue : value
  ) as CheckedRelayEnum<T>
}

export function relayMutationToPromise<TMutation extends MutationParameters>(
  mutationFn: (config: UseMutationConfig<TMutation>) => Disposable,
  config: UseMutationConfig<TMutation>,
): Promise<TMutation["response"]> {
  return new Promise((resolve, reject) => {
    mutationFn({
      ...config,
      onCompleted(...args) {
        config.onCompleted?.(...args)
        resolve(args[0])
      },
      onError(...args) {
        config.onError?.(...args)
        reject(args[0])
      },
    })
  })
}

export function first<T>(array: ReadonlyArray<T>): T | undefined {
  return array[0]
}

export function capitalize(str: string): string {
  return str.charAt(0).toUpperCase() + str.slice(1)
}

export function timeoutPromise<T>({
  promise,
  timeout = 5000,
  onTimeout,
}: {
  promise: Promise<T>
  timeout?: number
  onTimeout?: () => void
}): Promise<T | null> {
  let resolved = false

  return new Promise((resolve, reject) => {
    const timeoutId = setTimeout(() => {
      if (!resolved) {
        resolved = true
        onTimeout?.()
        resolve(null)
      }
    }, timeout)

    promise.then(
      (value) => {
        if (!resolved) {
          resolved = true
          clearTimeout(timeoutId)
          resolve(value)
        }
      },
      (error) => {
        if (!resolved) {
          resolved = true
          clearTimeout(timeoutId)
          reject(error)
        }
      },
    )
  })
}

export const clamp = (value: number, min: number, max: number) =>
  Math.max(min, Math.min(value, max))

export const mapSavingsQuestToBadge: Record<
  SavingsQuest,
  { iconName: GlowIconName; label: MessageDescriptor }
> = {
  BroadbandSavingsQuest: {
    iconName: "wifi_regular",
    label: defineMessage({
      id: "quests.savingsQuests.badge.broadbandSavingsQuest",
      defaultMessage: "Broadband savings",
    }),
  },
  EnergySavingsQuest: {
    iconName: "flash_1_regular",
    label: defineMessage({
      id: "quests.savingsQuests.badge.energySavingsQuest",
      defaultMessage: "Energy savings",
    }),
  },
  MobileSavingsQuest: {
    iconName: "mobile_phone_regular",
    label: defineMessage({
      id: "quests.savingsQuests.badge.mobileSavingsQuest",
      defaultMessage: "Mobile savings",
    }),
  },
  MortgageSavingsQuest: {
    iconName: "real_estate_insurance_house_regular",
    label: defineMessage({
      id: "quests.savingsQuests.badge.mortgageSavingsQuest",
      defaultMessage: "Mortgage savings",
    }),
  },
}

export function isSavingsQuest<T extends { __typename: string }>(
  quest: T,
): quest is T & { __typename: SavingsQuest } {
  return (savingsQuests as Array<string>).includes(quest.__typename)
}
