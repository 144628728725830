import { createFileRoute, lazyRouteComponent } from "@tanstack/react-router"
import { loadQuery } from "react-relay"
import { z } from "zod"

import SignUpPageQuery, {
  SignUpPageQuery as SignUpPageQueryType,
} from "src/pages/SignUpPage/__generated__/SignUpPageQuery.graphql"

const signUpSearchParams = z.object({
  invite: z.string().optional(),
})

export const Route = createFileRoute("/sign-up")({
  validateSearch: (search) => signUpSearchParams.parse(search),
  loaderDeps: ({ search }) => {
    return search
  },
  loader: ({ context, deps }) => {
    const hasInvite = !!deps.invite

    return loadQuery<SignUpPageQueryType>(
      context.relayEnvironment(),
      SignUpPageQuery,
      {
        inviteId: deps.invite ?? "",
        skipInvite: !hasInvite,
        skipHousehold: context.authState.user?.id == null,
        skipCurrrentUser: context.authState.user?.id == null,
      },
    )
  },
  component: lazyRouteComponent(
    () => import("src/pages/SignUpPage/SignUpPage"),
  ),
})
