import { useIntl } from "react-intl"

import { Breadcrumbs } from "src/components/Breadcrumbs/Breadcrumbs"
import MetaTags from "src/components/MetaTags/MetaTags"
import { DesktopNav } from "src/components/Nav/DesktopNav"
import { MobileTopNav } from "src/components/Nav/MobileTopNav"
import { GlowFlexbox, GlowLayoutCentered } from "src/glow"
import { NavLayout } from "src/layouts/NavLayout/NavLayout"
import LoadingPagePlaceholder from "src/LoadingPagePlaceholder"

export default function NotificationsLayout({
  children,
}: {
  children?: React.ReactNode
}) {
  const intl = useIntl()
  const pageTitle = intl.formatMessage({
    defaultMessage: "Notifications",
    id: "notificationsPage.title",
  })

  const pageDescription = intl.formatMessage({
    defaultMessage: "Notifications page",
    id: "notificationsPage.description",
  })
  return (
    <>
      <MetaTags title={pageTitle} description={pageDescription} />

      <NavLayout
        mobileNav={{
          top: (
            <MobileTopNav
              variant="secondary"
              backgroundColour="white"
              title={intl.formatMessage({
                id: "notificationsPage.mobileNav.title",
                defaultMessage: "Notifications",
              })}
              showBack
            />
          ),
        }}
        desktopNav={<DesktopNav />}
      >
        <GlowFlexbox
          direction="column"
          gap="10"
          className="max-w-7xl mx-auto md:px-4 md:pt-10 md:pb-10 h-full bg-white md:bg-gray-100"
        >
          <div className="hidden md:block">
            <Breadcrumbs crumbs={[{ text: "Notifications" }]} />
          </div>
          <GlowLayoutCentered variant="wide">
            {children ?? <LoadingPagePlaceholder />}
          </GlowLayoutCentered>
        </GlowFlexbox>
      </NavLayout>
    </>
  )
}
