import { BasePropertiesType, Properties, noProperties } from "./properties"

export declare class Action<TProperties extends BasePropertiesType> {
  properties: Properties<TProperties>
}

export function action<TProperties extends Record<string, any>>(
  properties: Properties<TProperties>,
): Action<TProperties>

export function action(): Action<undefined>

export function action<TProperties extends BasePropertiesType>(
  properties?: Properties<TProperties>,
): Action<TProperties | undefined> {
  return { properties: properties ?? noProperties() }
}
