import { Fragment } from "react"

import { Dialog, Transition } from "@headlessui/react"
import clsx from "clsx"
import { FormattedMessage } from "react-intl"

import { useViewport, Viewport } from "src/hooks/responsive"

import { GlowFlexbox } from "./GlowFlexbox"
import { GlowIcon } from "./GlowIcon"
import { GlowText } from "./GlowText"

type CommonProps = {
  children: React.ReactNode
  colour?: "white" | "glow"
  isOpen: boolean
  modalClassName?: string
  noPadding?: boolean
  title?: string
  level?: "0" | "1" | "2"
  hideDrawerTitle?: boolean
}

type GlowModalProps = CommonProps &
  (
    | {
        disableManualClose: true
        onClose?: () => void
      }
    | {
        disableManualClose?: false
        onClose: () => void
      }
  )

const backgroundColorMap = {
  white: "bg-white",
  glow: "radial-gradient",
}

export const GlowModal = ({
  children,
  colour = "white",
  disableManualClose,
  hideDrawerTitle,
  isOpen,
  level = "0",
  modalClassName,
  noPadding,
  onClose,
  title,
}: GlowModalProps) => {
  const viewport = useViewport()

  const content = (
    <GlowFlexbox
      direction="column"
      gap="0"
      className={clsx(
        "relative",
        "min-w-0 h-full max-h-[90vh] overflow-y-auto",
        "md:h-auto md:min-w-[400px] md:max-w-[528px]",
        "shadow-xl rounded-t-2xl md:rounded-lg",
        level === "1" ? "md:z-modal-level-1" : "md:z-modal",
        backgroundColorMap[colour],
        modalClassName,
      )}
    >
      {title || !disableManualClose ? (
        <GlowFlexbox
          justifyContent="center"
          alignItems="center"
          className="w-full px-4 md:px-6 md:border-b md:border-gray-500/12"
        >
          {title && (
            <div
              className={clsx(
                "py-4 text-center px-6 ml-auto",
                hideDrawerTitle && "hidden md:block",
              )}
            >
              <GlowText
                size={{ sm: "lg", md: "base" }}
                className="font-medium md:font-bold"
              >
                {title}
              </GlowText>
            </div>
          )}
          {!disableManualClose && (
            <button
              type="button"
              className="text-off-black-64 cursor-pointer ml-auto py-3"
              onClick={onClose}
            >
              <span className="sr-only">
                <FormattedMessage
                  id="glowModal.closeButton.screenReader.text"
                  defaultMessage="close panel"
                />
              </span>
              <GlowIcon name="close_regular" className="h-5 w-5" />
            </button>
          )}
        </GlowFlexbox>
      ) : (
        !disableManualClose && <div className="mt-8" />
      )}
      <div className={clsx(noPadding || "px-4 pb-4 md:p-6")}>{children}</div>
    </GlowFlexbox>
  )

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className={clsx(
          "relative z-drawer",
          "md:fixed md:inset-0 md:flex md:flex-wrap md:overflow-y-auto",
          level === "0" && "md:z-modal",
          level === "1" && "md:z-modal-level-1",
          level === "2" && "md:z-modal-level-2",
        )}
        onClose={() => {
          if (disableManualClose) {
            return
          }

          onClose()
        }}
        open={isOpen}
      >
        {viewport < Viewport.MD ? (
          <>
            <Transition.Child
              as={Fragment}
              enter="ease-in-out duration-500"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in-out duration-500"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-off-black-64 transition-opacity cursor-default" />
            </Transition.Child>
            <div className="fixed inset-0 overflow-hidden">
              <div className="absolute inset-0 overflow-hidden w-full min-h-full">
                <div className="fixed bottom-0 right-0 flex w-full pointer-events-none">
                  <Transition.Child
                    as={Fragment}
                    enter="transform transition ease-in-out duration-500 md:duration-700"
                    enterFrom="translate-y-full"
                    enterTo="translate-y-0"
                    leave="transform transition ease-in-out duration-500 md:duration-700"
                    leaveFrom="translate-y-0"
                    leaveTo="translate-y-full"
                  >
                    <Dialog.Panel className="pointer-events-auto relative w-full">
                      {content}
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </div>
            </div>
          </>
        ) : (
          <GlowFlexbox
            direction="column"
            alignItems="center"
            justifyContent="center"
            className="w-full h-full"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 opacity-64" />

            {content}
          </GlowFlexbox>
        )}
      </Dialog>
    </Transition.Root>
  )
}
