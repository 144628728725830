import { createFileRoute, lazyRouteComponent } from "@tanstack/react-router"
import { loadQuery } from "react-relay"
import { z } from "zod"

import EnergyWizardPageQuery, {
  EnergyWizardPageQuery as EnergyWizardPageQueryType,
} from "src/pages/Wizards/EnergyWizardPage/__generated__/EnergyWizardPageQuery.graphql"
import requireIsLoggedIn from "src/routeUtils/requireIsLoggedIn"

const searchSchema = z.object({
  from: z.string().optional(),
  id: z.string().optional(),
})

export const Route = createFileRoute("/wizard/energy")({
  beforeLoad: requireIsLoggedIn({ redirectTo: "/login" }),
  validateSearch: searchSchema,
  loaderDeps: ({ search }) => ({ id: search.id }),
  loader: ({ context, deps }) =>
    loadQuery<EnergyWizardPageQueryType>(
      context.relayEnvironment(),
      EnergyWizardPageQuery,
      { energyServiceSummaryId: deps.id ?? null },
    ),
  component: lazyRouteComponent(
    () => import("src/pages/Wizards/EnergyWizardPage/EnergyWizardPage"),
  ),
})
