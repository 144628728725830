import { Analytics, AnalyticsBrowser } from "@segment/analytics-next"
import * as Sentry from "@sentry/react"

import { logger } from "src/logger"
import { timeoutPromise } from "src/utils"

const SEGMENT_WRITE_KEY = __SEGMENT_WRITE_KEY__

const NANOSECONDS_PER_MILLISECOND = 1_000_000

function createDurationNs() {
  const start = Date.now()
  return () => (Date.now() - start) * NANOSECONDS_PER_MILLISECOND
}

async function loadAnalytics() {
  if (!SEGMENT_WRITE_KEY) {
    return null
  }
  logger.debug("Loading analytics")
  const duration = createDurationNs()
  try {
    const analyticsP = AnalyticsBrowser.load({
      writeKey: SEGMENT_WRITE_KEY,
    }).then(([analytics]) => analytics)

    const analytics = await timeoutPromise<Analytics | null>({
      promise: analyticsP,
      timeout: 3_000,
      onTimeout: () => {
        logger.warn("Analytics load timed out", {
          duration: duration(),
        })
      },
    })
    if (analytics != null) {
      logger.debug("Loaded analytics", {
        duration: duration(),
      })
    }
    return analytics
  } catch (error) {
    logger.error("Failed to load analytics", {
      error,
      duration: duration(),
    })
    Sentry.captureException(error, {
      level: "warning",
    })
    return null
  }
}

let analyticsPromise:
  | {
      status: "PENDING"
      promise: Promise<unknown>
    }
  | {
      status: "RESOLVED"
      analytics: Analytics | null
    } = {
  status: "PENDING",
  promise: loadAnalytics().then((analytics) => {
    analyticsPromise = {
      status: "RESOLVED",
      analytics,
    }
  }),
}

export function useAnalytics() {
  if (analyticsPromise.status === "PENDING") {
    throw analyticsPromise.promise
  }
  return analyticsPromise.analytics
}
