import { createFileRoute, redirect } from "@tanstack/react-router"

export const Route = createFileRoute("/induction/service-level")({
  beforeLoad: ({ search }) => {
    throw redirect({
      to: "/onboarding/service-level",
      search,
    })
  },
})
