import { createFileRoute, lazyRouteComponent } from "@tanstack/react-router"
import { z } from "zod"

import requireIsLoggedIn from "src/routeUtils/requireIsLoggedIn"

const categorySchema = z.enum([
  "broadband",
  "car-insurance",
  "energy",
  "home-insurance",
  "mobile",
  "mortgage",
  "television",
])

const paramsSchema = z.object({
  category: categorySchema,
})

const searchSchema = z.object({
  id: z.string().optional(),
})

export const Route = createFileRoute("/add-provider/$category")({
  validateSearch: searchSchema,
  parseParams: paramsSchema.parse,
  beforeLoad: requireIsLoggedIn({ redirectTo: "/login" }),
  component: lazyRouteComponent(
    () => import("src/pages/AddProviderPage/AddProviderPage"),
  ),
})
