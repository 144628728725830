import React from "react"

import { clsx } from "clsx"
import { useIntl } from "react-intl"

import { GlowButton } from "src/glow"
import { useViewport, Viewport } from "src/hooks/responsive"

import { Modal } from "../Modal/Modal"

type TooltipPosition = "bottom" // "top" | ...
type TooltipProps = {
  text: string | React.ReactNode
  position?: TooltipPosition
  children: React.ReactNode
  pointer?: boolean
  width?: React.CSSProperties["width"]
  className?: string
}

const BottomTooltip = ({
  text,
  pointer,
  width,
  className,
  children,
}: Omit<TooltipProps, "position">) => {
  return (
    <div
      className={clsx(
        "group relative w-fit",
        pointer && "cursor-pointer",
        className,
      )}
      role="tooltip"
    >
      {children}
      <div className="absolute left-1/2 z-tooltip flex hidden -translate-x-1/2 flex-col items-center group-hover:flex">
        <div className="mt-2 h-3 w-3 rotate-45 bg-black"></div>
        <span
          className={clsx(
            "-mt-2 rounded-lg bg-black p-2 text-center text-white shadow-lg text-xs",
            width === undefined ? "whitespace-nowrap" : null,
          )}
          style={{ width }}
        >
          {text}
        </span>
      </div>
    </div>
  )
}

export const Tooltip = ({ position = "bottom", ...props }: TooltipProps) => {
  switch (position) {
    case "bottom":
      return <BottomTooltip {...props} />
  }
}

export type ResponsiveTooltipProps = Omit<TooltipProps, "children"> & {
  title: string
  children: (props: {
    openModal?: () => void
    closeModal?: () => void
  }) => React.ReactNode
}
/**
 * A tooltip on desktop, but a modal on mobile (where we can't hover).
 */
export const ResponsiveTooltip = ({
  title,
  children,
  text,
  ...tooltipProps
}: ResponsiveTooltipProps) => {
  const viewport = useViewport()
  const intl = useIntl()

  const [modalIsOpen, setModalIsOpen] = React.useState(false)
  function openModal() {
    setModalIsOpen(true)
  }
  function closeModal() {
    setModalIsOpen(false)
  }
  return (
    <>
      {viewport >= Viewport.MD ? (
        <Tooltip text={text} {...tooltipProps}>
          {children({})}
        </Tooltip>
      ) : (
        children({ openModal, closeModal })
      )}
      <Modal
        variant="floaty"
        header={title}
        isOpen={modalIsOpen}
        onClose={closeModal}
      >
        <div>{text}</div>
        <GlowButton
          variant="secondary"
          className="mt-6 w-full"
          onClick={closeModal}
          label={intl.formatMessage({
            id: "tooltip.modal.close",
            defaultMessage: "Close",
          })}
        />
      </Modal>
    </>
  )
}
