import { createFileRoute, lazyRouteComponent } from "@tanstack/react-router"
import { loadQuery } from "react-relay"
import { z } from "zod"

import ConnectBankPageQuery, {
  ConnectBankPageQuery as ConnectBankPageQueryType,
} from "src/pages/ConnectBankPage/__generated__/ConnectBankPageQuery.graphql"
import requireIsLoggedIn from "src/routeUtils/requireIsLoggedIn"

const searchSchema = z.object({
  from: z.string().optional(),
  credentialsId: z.string().optional(),
  bankId: z.string().optional(),
  error: z.string().optional(),
  message: z.string().optional(),
  bankName: z.string().optional(),
  bankLogo: z.string().optional(),
})

export const Route = createFileRoute("/connect-bank")({
  validateSearch: searchSchema,
  beforeLoad: requireIsLoggedIn({ redirectTo: "/login" }),
  loader: ({ context }) =>
    loadQuery<ConnectBankPageQueryType>(
      context.relayEnvironment(),
      ConnectBankPageQuery,
      {},
    ),
  component: lazyRouteComponent(
    () => import("src/pages/ConnectBankPage/ConnectBankPage"),
  ),
})
