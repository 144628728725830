import { createFileRoute, lazyRouteComponent } from "@tanstack/react-router"

export const Route = createFileRoute("/widgets/payrise")({
  component: lazyRouteComponent(
    () =>
      import(
        "src/pages/Widgets/InflationaryPayRiseCalculatorPage/InflationaryPayRiseCalculatorPage"
      ),
  ),
})
