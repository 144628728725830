import clsx from "clsx"

import { GlowInputErrorMessagesType, GlowInputErrors } from "./GlowInput"
import { GlowText, GlowTextSize } from "./GlowText"
import { GlowResponsiveWrapper } from "./structure"

export type GlowCheckboxProps = {
  label: React.ReactNode
  isChecked?: boolean
  labelFontSize?: GlowResponsiveWrapper<GlowTextSize>
  onChange?: (isChecked: boolean) => void
  labelIsHidden?: boolean
  isDisabled?: boolean
  labelClassName?: string
  errors?: GlowInputErrorMessagesType
} & Omit<React.ComponentProps<"input">, "onChange" | "disabled">

export function GlowCheckbox({
  isDisabled,
  onChange,
  isChecked,
  labelIsHidden,
  label,
  labelClassName,
  errors,
  ...props
}: GlowCheckboxProps) {
  return (
    <div>
      <label className="flex items-center">
        <input
          type="checkbox"
          className={clsx(
            " peer/checkbox",
            "rounded border h-6 w-6 border-black/20",
            "hover:border-black",
            "focus:outline-2 focus:outline-serene-blue-400 focus:outline-offset-0",
            "focus:ring-0",
            "disabled:border-black/10",
            "disabled:hover:border-black/10",
            "disabled:checked:bg-virtuous-green-100",
            "checked:bg-virtuous-green-400",
            "checked:hover:bg-virtuous-green-500",
            "checked:focus:bg-virtuous-green-500",
            "transition-colors",
            "enabled:cursor-pointer",
            props.className,
          )}
          checked={isChecked}
          disabled={isDisabled}
          onChange={(e) => onChange?.(e.target.checked)}
        />
        {labelIsHidden || (
          <GlowText
            className={clsx(
              "ml-2 peer-enabled/checkbox:cursor-pointer",
              labelClassName,
            )}
            size={props.labelFontSize}
          >
            {label}
          </GlowText>
        )}
      </label>
      <GlowInputErrors errors={errors} />
    </div>
  )
}
