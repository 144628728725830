import {
  bankDetailsEntryMode,
  categories,
  emailProviders,
  inductionVideoPassedRequiredWatchDurationReasons,
  recommendationSections,
  savingsReportCategories,
  savingsReportFullSections,
  savingsReportOverviewSections,
  serviceLevels,
  userTaskItemContext,
  userTaskViewAllContext,
} from "./enums"

import * as defs from "../definitions"

export const schema = defs.schema({
  BankAccount: defs.object({
    actions: {
      CashRewardsTurnedOff: defs.action(
        defs.properties({
          bankAccountId: defs.string(),
        }),
      ),
      CashRewardsTurnedOn: defs.action(
        defs.properties({
          bankAccountId: defs.string(),
        }),
      ),
      DetailsEntryConfirmed: defs.action(),
      DetailsEntryRequested: defs.action(),
      DetailsEntrySaved: defs.action(
        defs.properties({
          mode: defs.enumString(bankDetailsEntryMode),
        }),
      ),
      DetailsEntrySkipped: defs.action(),
      DetailsEntryStarted: defs.action(
        defs.properties({
          mode: defs.enumString(bankDetailsEntryMode),
        }),
      ),
      Linked: defs.action(),
    },
  }),
  Blueprint: defs.object({
    actions: {
      ManualInterventionRequired: defs.action(
        defs.properties({
          householdService: defs.enumString(["Energy", "Mobile", "Broadband"]),
        }),
      ),
      Paused: defs.action(
        defs.properties({
          householdService: defs.enumString(["Energy", "Mobile", "Broadband"]),
          pausedAt: defs.string(),
        }),
      ),
      Started: defs.action(
        defs.properties({
          householdService: defs
            .enumString(["Energy", "Mobile", "Broadband"])
            .nullable(),
        }),
      ),
      Transitioned: defs.action(
        defs.properties({
          nextState: defs.string(),
        }),
      ),
      Unpaused: defs.action(
        defs.properties({
          householdService: defs.enumString(["Energy", "Mobile", "Broadband"]),
        }),
      ),
    },
    properties: defs.properties({
      blueprintId: defs.string(),
      blueprintType: defs.string(),
    }),
  }),
  BlueprintCheckpoint: defs.object({
    actions: { Reached: defs.action() },
    properties: defs.properties({
      blueprintId: defs.string(),
      category: defs.enumString(["Energy", "Mobile", "Broadband"]),
      checkpoint: defs.enumString([
        "RecommendationOffered",
        "SwitchRegistered",
        "SwitchFailed",
      ]),
    }),
  }),
  CollapsibleRecommendationContent: defs.object({
    actions: {
      InteractedWith: defs.action(),
    },
    properties: defs.properties({
      dealCardId: defs.string(),
      title: defs.string(),
      toggleState: defs.enumString(["OPEN", "CLOSE"]),
    }),
  }),

  DataSufficiency: defs.object({
    actions: {
      Failed: defs.action(
        defs.properties({
          dataRequiredToPass: defs.array(defs.string()),
        }),
      ),
      NotYetPassed: defs.action(
        defs.properties({
          passesAt: defs.string(),
        }),
      ),
      Passed: defs.action(defs.properties({})),
    },
    properties: defs.properties({
      category: defs.enumString(["Energy", "Mobile", "Broadband"]),
      gate: defs.string(),
      parentObject: defs.string(),
      serviceId: defs.string().nullable(),
    }),
  }),

  DeleteService: defs.object({
    actions: {
      Cancelled: defs.action(),
      Confirmed: defs.action(),
      Opened: defs.action(),
    },
    properties: defs.properties({
      category: defs.enumString(categories),
      id: defs.string(),
    }),
  }),
  EmailConnection: defs.object({
    actions: {
      Attempted: defs.action(
        defs.properties({
          emailProvider: defs.enumString(emailProviders),
        }),
      ),
      Offered: defs.action(),
      Rejected: defs.action(
        defs.properties({ reason: defs.string().nullable() }),
      ),
      Succeeded: defs.action(
        defs.properties({
          emailProvider: defs.enumString(emailProviders),
        }),
      ),
    },
  }),
  ExpectedSavings: defs.object({
    actions: {
      Clicked: defs.action(
        defs.properties({
          amountInGbpPounds: defs.number(),
          category: defs.enumString(savingsReportCategories).nullable(),
          context: defs.enumString(["ServiceLevel", "ServiceDetails"]),
        }),
      ),
    },
  }),
  FAQQuestion: defs.object({
    actions: {
      Clicked: defs.action(),
    },
    properties: defs.properties({
      dealCardId: defs.string(),
      faqQuestion: defs.string(),
      parentObject: defs.string(),
      toggleState: defs.enumString(["OPEN", "CLOSE"]),
    }),
  }),
  Form: defs.object({
    actions: {
      Completed: defs.action(
        defs.properties({
          category: defs.string(),
          context: defs.string(),
          savingsPlanDataSufficiency: defs.union([
            defs.string(),
            defs.boolean(),
          ]),
        }),
      ),
    },
  }),
  Hopper: defs.object({
    actions: {
      Entered: defs.action(),
      Exited: defs.action(),
    },
    properties: defs.properties({
      category: defs.enumString(["Energy", "Mobile", "Broadband"]),
      hopperType: defs.string(),
    }),
  }),
  Household: defs.object({
    actions: {
      Created: defs.action(),
      CreatedAnonymously: defs.action(),
      DelegationStatusChanged: defs.action(
        defs.properties({
          newDelegationStatus: defs.enumString(["ON", "OFF"]),
          previousDelegationStatus: defs.enumString(["ON", "OFF"]).nullable(),
          waitlistStatus: defs.enumString(["IN", "OUT"]),
        }),
      ),
      MemberAdded: defs.action(),
      WaitlistStatusChanged: defs.action(
        defs.properties({
          delegationStatus: defs.enumString(["ON", "OFF"]),
          newWaitlistStatus: defs.enumString(["IN", "OUT"]),
        }),
      ),
    },
  }),
  HouseholdServiceSwitch: defs.object({
    actions: {
      Completed: defs.action(),
      Failed: defs.action(),
      ReadyToRegister: defs.action(),
      Registered: defs.action(),
    },
    properties: defs.properties({
      householdService: defs.enumString(["Energy", "Mobile", "Broadband"]),
    }),
  }),
  InFocusQuest: defs.object({
    actions: {
      Clicked: defs.action(),
      Viewed: defs.action(),
    },
    properties: defs.properties({
      questType: defs.string(),
      taskType: defs.string().nullable(),
    }),
  }),
  InductionVideo: defs.object({
    actions: {
      AutoProceeded: defs.action(),
      Ended: defs.action(
        defs.properties({
          url: defs.string(),
        }),
      ),
      Paused: defs.action(
        defs.properties({
          progressDecimalPercentage: defs.number(),
          progressSeconds: defs.number(),
          url: defs.string(),
        }),
      ),
      Played: defs.action(
        defs.properties({
          progressDecimalPercentage: defs.number(),
          progressSeconds: defs.number(),
          url: defs.string(),
        }),
      ),
      ProceedButtonClicked: defs.action(
        defs.properties({
          progressDecimalPercentage: defs.number().nullable(),
          progressSeconds: defs.number().nullable(),
        }),
      ),
      ProceedButtonDisplayed: defs.action(
        defs.properties({
          progressDecimalPercentage: defs.number(),
          progressSeconds: defs.number(),
          reason: defs.enumString(
            inductionVideoPassedRequiredWatchDurationReasons,
          ),
        }),
      ),
      ProgressMade: defs.action(
        defs.properties({
          progressDecimalPercentage: defs.number(),
          progressSeconds: defs.number(),
          realProgressDecimalPercentage: defs.number(),
          url: defs.string(),
        }),
      ),
      Started: defs.action(defs.properties({ url: defs.string() })),
      Viewed: defs.action(defs.properties({ url: defs.string() })),
    },
  }),
  Invite: defs.object({
    actions: {
      Sent: defs.action(
        defs.properties({
          customMessage: defs.string().nullable(),
          email: defs.string(),
          firstName: defs.string(),
          inviteId: defs.string(),
          lastName: defs.string(),
        }),
      ),
      Shared: defs.action(
        defs.properties({
          inviteId: defs.string(),
        }),
      ),
      Started: defs.action(),
    },
  }),
  LearnMoreButton: defs.object({
    actions: {
      Clicked: defs.action(),
    },
    properties: defs.properties({
      dealCardId: defs.string(),
      parentObject: defs.string(),
      position: defs.enumString(["TOP", "BOTTOM"]),
      title: defs.string().nullable(),
    }),
  }),
  MagicLink: defs.object({
    actions: {
      Clicked: defs.action(),
      Expired: defs.action(),
      Resent: defs.action(),
      Sent: defs.action(),
    },
  }),
  Navigation: defs.object({
    actions: {
      Clicked: defs.action(),
    },
    properties: defs.properties({
      to: defs.string(),
    }),
  }),
  Notification: defs.object({
    actions: {
      Clicked: defs.action(),
      Viewed: defs.action(),
    },
    properties: defs.properties({
      id: defs.string(),
      type: defs.string(),
    }),
  }),
  Organization: defs.object({
    actions: {
      SecondaryEmailEntered: defs.action(
        defs.properties({
          email: defs.string(),
        }),
      ),
      SecondaryEmailViewed: defs.action(),
    },
    properties: defs.properties({
      id: defs.string(),
      name: defs.string().nullable(),
    }),
  }),
  OrganizationInvite: defs.object({
    actions: {
      Opened: defs.action(),
    },
    properties: defs.properties({
      id: defs.string(),
      organizationId: defs.string(),
      organizationName: defs.string().nullable(),
    }),
  }),
  OrganizationOnboardingSession: defs.object({
    actions: {
      Joined: defs.action(),
    },
    properties: defs.properties({
      id: defs.string(),
    }),
  }),
  PhoneNumber: defs.object({
    actions: {
      Edited: defs.action(),
      Submitted: defs.action(),
    },
    properties: defs.properties({
      context: defs.enumString(["Onboarding", "Wizard", "Task"]),
      parentObject: defs.string(),
    }),
  }),
  Preferences: defs.object({
    actions: {
      Updated: defs.action(
        defs.properties({
          firstPreference: defs.string(),
          secondPreference: defs.string(),
          thirdPreference: defs.string(),
        }),
      ),
    },
    properties: defs.properties({ type: defs.enumString(["Household"]) }),
  }),
  Provider: defs.object({
    actions: {
      Added: defs.action(),
      Clicked: defs.action(
        defs.properties({
          providerType: defs.string(),
        }),
      ),
    },
  }),
  Question: defs.object({
    actions: {
      Answered: defs.action(
        defs.properties({ answer: defs.string().nullable() }),
      ),
    },
    properties: defs.properties({
      question: defs.string(),
      questionContext: defs.string(),
    }),
  }),
  RateComparison: defs.object({
    actions: {
      Clicked: defs.action(),
    },
    properties: defs.properties({
      dealCardId: defs.string(),
      parentObject: defs.string(),
      title: defs.string(),
    }),
  }),
  Recommendation: defs.object({
    actions: {
      Accepted: defs.action(
        defs.properties({
          category: defs.enumString(["Energy", "Mobile", "Broadband"]),
          context: defs.enumString(["Deal Card"]).nullable(),
          previouslyAskedForMoreInfo: defs.boolean().nullable(),
        }),
      ),
      Clicked: defs.action(
        defs.properties({
          context: defs.enumString(["Savings Plan", "Dashboard Callout"]),
          taskId: defs.string().nullable(),
        }),
      ),
      Offered: defs.action(
        defs.properties({
          category: defs.enumString(["Energy", "Mobile", "Broadband"]),
          hasPreviouslyConversedWithOps: defs.boolean(),
          notificationId: defs.string(),
          taskId: defs.string(),
        }),
      ),
      Rejected: defs.action(
        defs.properties({
          category: defs.enumString(["Energy", "Mobile", "Broadband"]),
          context: defs.enumString(["Deal Card"]),
          previouslyAskedForMoreInfo: defs.boolean().nullable(),
          rejectReason: defs.string().nullable(),
        }),
      ),
      RequestedMoreInformation: defs.action(
        defs.properties({
          category: defs.enumString(["Energy", "Mobile", "Broadband"]),
          context: defs.enumString(["Deal Card"]),
        }),
      ),
      SectionViewed: defs.action(
        defs.properties({
          section: defs.enumString(recommendationSections),
        }),
      ),
      Shared: defs.action(defs.properties({ inviteId: defs.string() })),
    },
    properties: defs.properties({ dealCardId: defs.string().nullable() }),
  }),
  RecommendationCallout: defs.object({
    actions: {
      Viewed: defs.action(),
    },
    properties: defs.properties({ context: defs.enumString(["Dashboard"]) }),
  }),
  SavingsReport: defs.object({
    actions: {
      FullReportCategoryDetailsClosed: defs.action(
        defs.properties({
          category: defs.enumString(savingsReportCategories),
        }),
      ),
      FullReportCategoryDetailsEstimatedTooltipToggled: defs.action(
        defs.properties({
          category: defs.enumString(savingsReportCategories),
          state: defs.enumString(["OPEN", "CLOSE"]),
        }),
      ),
      FullReportCategoryDetailsOpened: defs.action(
        defs.properties({
          category: defs.enumString(savingsReportCategories),
          directGbpPounds: defs.number().nullable(),
          rewardsGbpPounds: defs.number().nullable(),
          status: defs.enumString(["Calculated", "Estimated"]),
          totalGbpPounds: defs.number().nullable(),
        }),
      ),
      FullReportClosed: defs.action(),
      FullReportSectionScrolledIntoView: defs.action(
        defs.properties({
          nickname: defs.string().nullable(),
          section: defs.enumString(savingsReportFullSections),
          tooltipsEnabled: defs.boolean(),
        }),
      ),
      FullReportViewed: defs.action(
        defs.properties({
          directGbpPounds: defs.number().nullable(),
          rewardsGbpPounds: defs.number().nullable(),
          tooltipsEnabled: defs.boolean(),
          totalGbpPounds: defs.number().nullable(),
        }),
      ),
      OverviewSectionScrolledIntoView: defs.action(
        defs.properties({
          section: defs.enumString(savingsReportOverviewSections),
        }),
      ),
      OverviewViewed: defs.action(
        defs.properties({
          totalGbpPounds: defs.number().nullable(),
        }),
      ),
      PrimaryCtaClicked: defs.action(
        defs.properties({
          text: defs.string(),
          totalGbpPounds: defs.number().nullable(),
        }),
      ),
      PrimaryCtaScrollIntoViewButtonClicked: defs.action(),
      SummaryHeaderTooltipToggled: defs.action(
        defs.properties({
          state: defs.enumString(["OPEN", "CLOSE"]),
        }),
      ),
      SummaryRowEstimatedTooltipToggled: defs.action(
        defs.properties({
          category: defs.enumString(savingsReportCategories),
          state: defs.enumString(["OPEN", "CLOSE"]),
        }),
      ),
      ViewFullReportClicked: defs.action(
        defs.properties({
          text: defs.string(),
          totalGbpPounds: defs.number().nullable(),
        }),
      ),
    },
  }),
  ServiceLevel: defs.object({
    actions: {
      Confirmed: defs.action(
        defs.properties({
          level: defs.enumString(serviceLevels),
        }),
      ),
      Proposed: defs.action(),
      Selected: defs.action(
        defs.properties({
          description: defs.string(),
          level: defs.enumString(serviceLevels),
          name: defs.string(),
        }),
      ),
    },
  }),
  ServiceLineManagement: defs.object({
    actions: {
      Confirmed: defs.action(
        defs.properties({
          broadband: defs.boolean().nullable(),
          categoriesOff: defs.array(defs.enumString(savingsReportCategories)),
          categoriesOn: defs.array(defs.enumString(savingsReportCategories)),
          energy: defs.boolean().nullable(),
          mobile: defs.boolean().nullable(),
          mortgage: defs.boolean().nullable(),
        }),
      ),
      EditModeStarted: defs.action(),
      Edited: defs.action(
        defs.properties({
          category: defs.enumString(savingsReportCategories),
          value: defs.enumString(["ON", "OFF"]),
        }),
      ),
      Updated: defs.action(),
    },
  }),
  SignUpStep: defs.object({
    actions: {
      ClickedBack: defs.action(
        defs.properties({
          source: defs.string(),
        }),
      ),
      ClickedCalculateSavings: defs.action(
        defs.properties({
          category: defs.enumString(savingsReportCategories),
          source: defs.string(),
        }),
      ),
      ClickedContinue: defs.action(
        defs.properties({
          source: defs.string(),
        }),
      ),
      Viewed: defs.action(
        defs.properties({
          dealCardSavingsAmount: defs.number().nullable(),
          experiments: defs.array(defs.string()).nullable(),
        }),
      ),
    },
    properties: defs.properties({ step: defs.string() }),
  }),
  TariffDetails: defs.object({
    actions: { Clicked: defs.action() },
    properties: defs.properties({
      dealCardId: defs.string(),
      parentObject: defs.string(),
      title: defs.string(),
      toggleState: defs.enumString(["OPEN", "CLOSE"]).nullable(),
    }),
  }),
  Task: defs.object({
    actions: {
      Clicked: defs.action(
        defs.properties({
          completed: defs.boolean().nullable(),
          context: defs.enumString(userTaskItemContext),
          id: defs.string(),
          priority: defs.string().nullable(),
          type: defs.string(),
        }),
      ),
      Completed: defs.action(
        defs.properties({
          id: defs.string(),
          type: defs.string(),
        }),
      ),
      SequenceModalClosed: defs.action(
        defs.properties({
          step: defs.string(),
        }),
      ),
      ViewAll: defs.action(
        defs.properties({
          context: defs.enumString(userTaskViewAllContext).nullable(),
        }),
      ),
    },
  }),
  Tour: defs.object({
    actions: {
      Clicked: defs.action(),
      Completed: defs.action(),
    },
    properties: defs.properties({
      context: defs.enumString(["Dashboard"]),
      step: defs.number(),
    }),
  }),
  TrustpilotReview: defs.object({
    actions: {
      Viewed: defs.action(),
    },
    properties: defs.properties({
      dealCardId: defs.string(),
      parentObject: defs.string(),
      providerId: defs.string(),
      score: defs.string(),
      title: defs.string(),
      trustpilotReviewId: defs.string(),
    }),
  }),
  UpcomingEvents: defs.object({
    actions: { ViewAllClicked: defs.action(), Viewed: defs.action() },
  }),
  User: defs.object({
    actions: {
      AttemptedNavigationOrRefresh: defs.action(),
      Created: defs.action(),
      Deleted: defs.action(),
      Emulated: defs.action(defs.properties({ userId: defs.string() })),
      Inactive: defs.action(),
      LoggedIn: defs.action(
        defs.properties({
          method: defs.enumString(["SSO", "Magic Link", "Email & Password"]),
          provider: defs.string().nullable(),
        }),
      ),
      LoggedInAnonymously: defs.action(),
      LoggedOut: defs.action(),
      UsedBrowserNavigation: defs.action(),
    },
  }),
  WhatsApp: defs.object({
    actions: {
      Confirmed: defs.action(
        defs.properties({
          type: defs.enumString(["Manual", "Automatic"]),
        }),
      ),
      Offered: defs.action(),
      SkipReasonShown: defs.action(),
      SkipShown: defs.action(),
      Skipped: defs.action(
        defs.properties({
          reason: defs.string().nullable(),
        }),
      ),
    },
    properties: defs.properties({
      context: defs.enumString(["Onboarding", "Wizard", "Task"]),
      parentObject: defs.string(),
    }),
  }),
  Wizard: defs.object({
    actions: {
      Completed: defs.action(),
    },
    properties: defs.properties({
      householdService: defs.enumString([
        "Energy",
        "Broadband",
        "Mobile",
        "Mortgage",
      ]),
    }),
  }),
})
