import { properties as propertiesDef } from "./properties"
import {
  EventObjects,
  EventActions,
  EventTuple,
  AnySchemaDefinitions,
} from "./types"
export declare class Schema<TSchema extends AnySchemaDefinitions> {
  definitions: TSchema
  isValidEvent<
    TObject extends EventObjects<TSchema>,
    TAction extends EventActions<TSchema, TObject>,
  >(event: unknown): event is EventTuple<TSchema, TObject, TAction>
}

export function schema<TSchema extends AnySchemaDefinitions>(
  definitions: TSchema,
): Schema<TSchema> {
  return {
    definitions,
    isValidEvent<
      TObject extends EventObjects<TSchema>,
      TAction extends EventActions<TSchema, TObject>,
    >(event: unknown): event is EventTuple<TSchema, TObject, TAction> {
      if (!Array.isArray(event)) {
        return false
      }
      if (event.length < 2 || event.length > 3) {
        return false
      }
      const [object, action, properties] = event
      if (typeof object !== "string" || typeof action !== "string") {
        return false
      }
      const objectSpec = definitions[object]
      if (objectSpec === undefined) {
        return false
      }
      const actionSpec = objectSpec.actions[action]
      if (actionSpec === undefined) {
        return false
      }

      if (
        (actionSpec.properties.properties !== undefined ||
          objectSpec.properties.properties !== undefined) &&
        properties === undefined
      ) {
        return false
      }

      if (properties !== undefined) {
        return propertiesDef({
          ...actionSpec.properties.properties,
          ...objectSpec.properties.properties,
        }).isValid(properties)
      }
      return true
    },
  }
}
