import {
  createFileRoute,
  lazyRouteComponent,
  redirect,
} from "@tanstack/react-router"

export const Route = createFileRoute("/energy-forecast")({
  beforeLoad: ({ context }) => {
    if (context.authState.isAuthenticated) {
      throw redirect({ to: "/tools/energy-forecast" })
    }
  },
  component: lazyRouteComponent(
    () => import("src/pages/EnergyForecast2023Page/EnergyForecast2023Page"),
  ),
})
