import { useLazyLoadQuery } from "react-relay"
import { graphql } from "relay-runtime"

import { GlowAvatarBackground } from "src/glow"

import { NavAvatarQuery } from "./__generated__/NavAvatarQuery.graphql"

import Avatar from "../Avatar/Avatar"

export default function NavAvatar({
  background,
}: {
  background?: GlowAvatarBackground
}) {
  const result = useLazyLoadQuery<NavAvatarQuery>(
    graphql`
      query NavAvatarQuery {
        currentUser {
          ...Avatar_user
        }
      }
    `,
    {},
  )
  if (result.currentUser == null) {
    return <Avatar />
  }
  return <Avatar user={result.currentUser} background={background} />
}
