import React, { useCallback } from "react"

import clsx from "clsx"

import useToggle from "src/hooks/useToggle"

import { GlowIcon } from "./GlowIcon"
import { GlowText, GlowTextSize } from "./GlowText"

export type GlowInputProps = {
  value: string | number
  onChange: (value: string) => void
  errors?: GlowInputErrorMessagesType
  label?: string
  placeholder?: string
  assistiveText?: string
  type?: "text" | "password" | "email" | "number" | "tel"
  prefixIcon?: React.ReactElement<React.ComponentProps<typeof GlowIcon>>
  className?: string
} & Omit<React.ComponentPropsWithoutRef<"input">, "onChange">

export function GlowInput({
  onChange,
  value,
  errors,
  label,
  placeholder,
  assistiveText,
  type,
  className,
  prefixIcon,
  ...rest
}: GlowInputProps) {
  const { value: showPassword, toggle: toggleShowPassword } = useToggle(false)
  const onChangeWrapped = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const fn = onChange
      fn(e.target.value)
    },
    [onChange],
  )
  return (
    <label className="flex-col flex gap-1 w-full">
      {label && (
        <GlowText size="sm" fontWeight="medium">
          {label}
        </GlowText>
      )}
      {assistiveText && (
        <GlowText size="xs" className="text-off-black-64">
          {assistiveText}
        </GlowText>
      )}
      <div className="relative focus-within:outline-[3px] focus-within:outline-serene-blue-400 focus-within:outline-offset-0 rounded-lg">
        {prefixIcon && (
          <div className="absolute left-[14px] top-[14px]">
            {React.cloneElement(prefixIcon, {
              className: clsx(prefixIcon.props.className, "w-5 h-5"),
            })}
          </div>
        )}
        <input
          type={showPassword ? "text" : type}
          onChange={onChangeWrapped}
          value={value}
          placeholder={placeholder}
          className={clsx(
            "w-full",
            "focus:outline-0",
            "focus:ring-0",
            "focus:border-transparent",
            "border rounded-lg p-[11px]",
            type === "password" && "pr-[47px]",
            "hover:border-black",
            errors?.length ? "border-angsty-red-400" : "border-off-black-20",
            "placeholder:text-off-black-64",
            "disabled:bg-gray-50 disabled:text-off-black-32 disabled:hover:border-off-black-20",
            prefixIcon && "pl-[40px]",
            className,
          )}
          {...rest}
        />
        {type === "password" && (
          <button
            type="button"
            className="absolute right-3 top-3"
            onClick={toggleShowPassword}
          >
            {showPassword ? (
              <GlowIcon name="view_1_regular" className="w-6 h-6" />
            ) : (
              <GlowIcon name="view_off_regular" className="w-6 h-6" />
            )}
          </button>
        )}
      </div>
      <GlowInputErrors errors={errors} />
    </label>
  )
}

export type GlowInputErrorMessagesType = (string | false | null | undefined)[]

export type GlowInputErrorsProps = {
  errors?: GlowInputErrorMessagesType
  fontSize?: GlowTextSize
}
export function GlowInputErrors({ errors, fontSize }: GlowInputErrorsProps) {
  return (
    <>
      {errors?.filter(Boolean).map((error, i) => (
        <GlowText
          key={i}
          as="p"
          className="text-angsty-red-400"
          margin={{ top: "1" }}
          size={fontSize ?? "xs"}
        >
          {error}
        </GlowText>
      ))}
    </>
  )
}

export function ReadOnlyGlowInput({
  value,
  label,
  assistiveText,
  className,
}: {
  value: string
  label?: string
  assistiveText?: string
  className?: string
}) {
  return (
    <label className="flex-col flex gap-1 w-full pointer-events-none">
      {label && (
        <GlowText size="sm" fontWeight="medium">
          {label}
        </GlowText>
      )}
      {assistiveText && (
        <GlowText size="xs" className="text-off-black-64">
          {assistiveText}
        </GlowText>
      )}
      <div className="relative focus-within:outline-[3px] focus-within:outline-serene-blue-400 focus-within:outline-offset-0 rounded-lg">
        <input
          type="text"
          readOnly
          value={value}
          className={clsx(
            "w-full",
            "focus:outline-0",
            "focus:ring-0",
            "bg-gray-50",
            "focus:border-transparent",
            "border rounded-lg p-[11px]",
            className,
          )}
        />
        <div className="absolute right-3 top-3">
          <GlowIcon
            name="check_circle_1_bold"
            className="w-6 h-6 text-virtuous-green-400"
          />
        </div>
      </div>
    </label>
  )
}
