import { createFileRoute, lazyRouteComponent } from "@tanstack/react-router"
import { loadQuery } from "react-relay"

import { ServiceDetailsLoading } from "src/pages/ServiceDetails/__components__/ServiceDetailsLoading/ServiceDetailsLoading"
import { serviceSummaryTypenameToServiceNameRecord } from "src/pages/ServiceDetails/__utils__/serviceSummaryTypenameToServiceNameRecord"
import WaterPageQuery, {
  type WaterPageQuery as WaterPageQueryType,
} from "src/pages/ServiceDetails/WaterPage/__generated__/WaterPageQuery.graphql"
import requireIsLoggedIn from "src/routeUtils/requireIsLoggedIn"

export const Route = createFileRoute("/water")({
  beforeLoad: requireIsLoggedIn({ redirectTo: "/login" }),
  loader: ({ context }) => {
    return loadQuery<WaterPageQueryType>(
      context.relayEnvironment(),
      WaterPageQuery,
      {},
    )
  },
  pendingComponent: () => (
    <ServiceDetailsLoading
      title={serviceSummaryTypenameToServiceNameRecord["WaterServiceSummary"]}
    />
  ),
  component: lazyRouteComponent(
    () => import("src/pages/ServiceDetails/WaterPage/WaterPage"),
  ),
})
