import { createFileRoute, lazyRouteComponent } from "@tanstack/react-router"
import { loadQuery } from "react-relay"

import ConnectEmailPageQuery, {
  ConnectEmailPageQuery as ConnectEmailPageQueryType,
} from "src/pages/ConectEmailPage/__generated__/ConnectEmailPageQuery.graphql"
import ConnectEmailPageLayout from "src/pages/ConectEmailPage/ConnectEmailPageLayout"
import requireIsLoggedIn from "src/routeUtils/requireIsLoggedIn"

export const Route = createFileRoute("/connect-email")({
  beforeLoad: requireIsLoggedIn({ redirectTo: "/login" }),
  pendingComponent: ConnectEmailPageLayout,
  loader({ context }) {
    return loadQuery<ConnectEmailPageQueryType>(
      context.relayEnvironment(),
      ConnectEmailPageQuery,
      {},
    )
  },
  component: lazyRouteComponent(
    () => import("src/pages/ConectEmailPage/ConnectEmailPage"),
  ),
})
