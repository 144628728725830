import { createFileRoute, lazyRouteComponent } from "@tanstack/react-router"
import { loadQuery } from "react-relay"

import { ServiceDetailsLoading } from "src/pages/ServiceDetails/__components__/ServiceDetailsLoading/ServiceDetailsLoading"
import { serviceSummaryTypenameToServiceNameRecord } from "src/pages/ServiceDetails/__utils__/serviceSummaryTypenameToServiceNameRecord"
import CouncilTaxPageQuery, {
  type CouncilTaxPageQuery as CouncilTaxPageQueryType,
} from "src/pages/ServiceDetails/CouncilTaxPage/__generated__/CouncilTaxPageQuery.graphql"
import requireIsLoggedIn from "src/routeUtils/requireIsLoggedIn"

export const Route = createFileRoute("/council-tax")({
  beforeLoad: requireIsLoggedIn({ redirectTo: "/login" }),
  loader: ({ context }) => {
    return loadQuery<CouncilTaxPageQueryType>(
      context.relayEnvironment(),
      CouncilTaxPageQuery,
      {},
    )
  },
  pendingComponent: () => (
    <ServiceDetailsLoading
      title={
        serviceSummaryTypenameToServiceNameRecord["CouncilTaxServiceSummary"]
      }
    />
  ),
  component: lazyRouteComponent(
    () => import("src/pages/ServiceDetails/CouncilTaxPage/CouncilTaxPage"),
  ),
})
