import LoadingPagePlaceholder from "src/LoadingPagePlaceholder"

import OnboardingLayout from "../Onboarding/layouts/OnboardingLayout"

export default function WaitlistPageLayout({
  title,
  children,
}: {
  title?: string
  children?: React.ReactNode
}) {
  return (
    <OnboardingLayout title={title ?? ""}>
      {children ?? <LoadingPagePlaceholder />}
    </OnboardingLayout>
  )
}
