import { useMemo } from "react"

import { tracker } from "@nous/tracking"
import { Options } from "@segment/analytics-next"
import { useRouterState } from "@tanstack/react-router"

import { logger } from "src/logger"

import useTrackingInternal from "./useTrackingInternal"

export default function useTracking() {
  // @ts-ignore
  const pathname = useRouterState({
    // @ts-ignore
    select: (state) => state.location.pathname,
  })
  const internalTrack = useTrackingInternal({ path: pathname })
  return useMemo(
    () =>
      tracker(
        ({ event, properties, isValid }, options: Options | undefined) => {
          if (!isValid) {
            logger.warn("Invalid tracking event", { event, properties })
          }
          return internalTrack(event, properties, options)
        },
      ).track,
    [internalTrack],
  )
}
