import React from "react"

import { LoadingDots } from "./components/LoadingDots/LoadingDots"

export default function LoadingPagePlaceholder() {
  return (
    <div className="h-screen w-screen flex items-center justify-center">
      <LoadingDots />
    </div>
  )
}
