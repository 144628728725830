import { SetStateAction, useCallback, useState } from "react"

export default function useToggle(initialValue = false) {
  const [value, actuallySetValue] = useState(initialValue)
  const [beenToggled, setBeenToggled] = useState(false)

  const setValue = useCallback((v: SetStateAction<boolean>) => {
    actuallySetValue(v)
    setBeenToggled(true)
  }, [])

  const toggle = useCallback(() => setValue((v) => !v), [setValue])

  const setTrue = useCallback(() => setValue(true), [setValue])
  const setFalse = useCallback(() => setValue(false), [setValue])

  return {
    value,
    toggle,
    setValue,
    setTrue,
    setFalse,
    beenToggled,
  }
}
