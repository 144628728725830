import { createFileRoute, lazyRouteComponent } from "@tanstack/react-router"
import { loadQuery } from "react-relay"
import { z } from "zod"

import LoadingPagePlaceholder from "src/LoadingPagePlaceholder"
import SessionPageQuery, {
  SessionPageQuery as SessionPageQueryType,
} from "src/pages/SessionPage/__generated__/SessionPageQuery.graphql"

const searchSchema = z.object({
  organization: z.string().optional(),
  mode: z.string().optional(),
  session: z.string().optional(),
})

export const Route = createFileRoute("/session/$id")({
  pendingComponent: LoadingPagePlaceholder,
  validateSearch: searchSchema,
  loaderDeps: ({ search: { organization, session } }) => ({
    organizationId: organization,
    organizationOnboardingSessionId: session,
  }),
  loader: ({
    context,
    deps: { organizationId, organizationOnboardingSessionId },
  }) =>
    loadQuery<SessionPageQueryType>(
      context.relayEnvironment(),
      SessionPageQuery,
      {
        organizationId: organizationId ?? "",
        skipOrganization: !organizationId,
        organizationOnboardingSessionId: organizationOnboardingSessionId ?? "",
        skipOrganizationOnboardingSession: !organizationOnboardingSessionId,
      },
    ),
  component: lazyRouteComponent(
    () => import("src/pages/SessionPage/SessionPage"),
  ),
})
