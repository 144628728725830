import { createFileRoute, redirect } from "@tanstack/react-router"

export const Route = createFileRoute("/induction/")({
  beforeLoad: ({ search }) => {
    throw redirect({
      to: "/onboarding",
      replace: true,
      search,
    })
  },
})
