import { createFileRoute, lazyRouteComponent } from "@tanstack/react-router"
import { loadQuery } from "react-relay"
import { z } from "zod"

import MobileWizardPageQuery, {
  MobileWizardPageQuery as MobileWizardPageQueryType,
} from "src/pages/Wizards/MobileWizardPage/__generated__/MobileWizardPageQuery.graphql"
import requireIsLoggedIn from "src/routeUtils/requireIsLoggedIn"

const searchSchema = z.object({
  from: z.string().optional(),
  id: z.string().optional(),
})

export const Route = createFileRoute("/wizard/mobile")({
  beforeLoad: requireIsLoggedIn({ redirectTo: "/login" }),
  validateSearch: searchSchema,
  loaderDeps: ({ search }) => ({ id: search.id }),
  loader: ({ context, deps }) =>
    loadQuery<MobileWizardPageQueryType>(
      context.relayEnvironment(),
      MobileWizardPageQuery,
      { mobileServiceSummaryId: deps.id ?? null },
    ),
  component: lazyRouteComponent(
    () => import("src/pages/Wizards/MobileWizardPage/MobileWizardPage"),
  ),
})
