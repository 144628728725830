import { createFileRoute, lazyRouteComponent } from "@tanstack/react-router"
import { loadQuery } from "react-relay"
import { z } from "zod"

import MobileRecommendationPageQuery, {
  MobileRecommendationPageQuery as MobileRecommendationPageQueryType,
} from "src/pages/Recommendations/MobileRecommendationPage/__generated__/MobileRecommendationPageQuery.graphql"

const searchSchema = z.object({
  taskId: z.string().optional(),
})

export const Route = createFileRoute("/recommendation/mobile/$id")({
  validateSearch: searchSchema,
  loaderDeps({ search }) {
    return {
      taskId: search.taskId,
    }
  },
  loader: ({ context, deps, params }) =>
    loadQuery<MobileRecommendationPageQueryType>(
      context.relayEnvironment(),
      MobileRecommendationPageQuery,
      {
        dealCardId: params.id,
        taskId: deps.taskId ?? "",
      },
    ),
  component: lazyRouteComponent(
    () =>
      import(
        "src/pages/Recommendations/MobileRecommendationPage/MobileRecommendationPage"
      ),
  ),
})
