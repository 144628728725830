import { useRouterState } from "@tanstack/react-router"

import useTrackingInternal from "./useTrackingInternal"

export default function useTracking() {
  // @ts-ignore
  const pathname = useRouterState({
    // @ts-ignore
    select: (state) => state.location.pathname,
  })
  return useTrackingInternal({ path: pathname })
}
