import { createFileRoute, lazyRouteComponent } from "@tanstack/react-router"
import { loadQuery } from "react-relay"
import { z } from "zod"

import DashboardPageQuery, {
  DashboardPageQuery as DashboardPageQueryType,
} from "src/pages/DashboardPage/__generated__/DashboardPageQuery.graphql"
import { DashboardPageLayout } from "src/pages/DashboardPage/DashboardPageLayout"
import requireIsLoggedIn from "src/routeUtils/requireIsLoggedIn"

const searchSchema = z.object({
  taskSequence: z.boolean().optional().catch(false),
  emilyTour: z.boolean().optional().catch(false),
})

export const Route = createFileRoute("/")({
  validateSearch: searchSchema,
  beforeLoad: requireIsLoggedIn({ redirectTo: "/sign-up" }),
  pendingComponent: DashboardPageLayout,
  pendingMs: 0,
  loader({ context }) {
    return loadQuery<DashboardPageQueryType>(
      context.relayEnvironment(),
      DashboardPageQuery,
      {},
    )
  },
  component: lazyRouteComponent(
    () => import("src/pages/DashboardPage/DashboardPage"),
  ),
})
