import React from "react"

import useClickOutsideRef from "./useClickOutsideRef"

export default function useClickOutside<TElement extends HTMLElement>(
  fn: () => void,
) {
  const ref = React.useRef<TElement>(null)
  useClickOutsideRef<TElement>(ref, fn)
  return ref
}
