import { useEffect, useRef } from "react"

export default function useEffectOnce(
  callback: React.EffectCallback,
  deps: React.DependencyList,
  condition = true,
) {
  const firedOnce = useRef<boolean>(false)
  useEffect(() => {
    if (!firedOnce.current && condition) {
      callback()
      firedOnce.current = true
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...deps, condition])
}
