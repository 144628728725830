import { endOfToday, addMinutes } from "date-fns"

export function getSessionStartTime() {
  const storedSessionStartTime = window.document.cookie
    .split("; ")
    .reduce((previous: Date | null, v) => {
      const parts = v.split("=")
      if (parts[0] !== "sessionStartTime") {
        return previous
      }
      const parsed = parseInt(parts[1], 10)
      if (isNaN(parsed)) {
        return previous
      }
      return new Date(parsed)
    }, null)
  if (storedSessionStartTime) {
    return storedSessionStartTime
  }
  const sessionTime = new Date()
  storeWithNewExpiration(sessionTime)

  return sessionTime
}

function storeWithNewExpiration(sessionTime: Date) {
  const plus30Mins = addMinutes(sessionTime, 30)
  const expires =
    endOfToday().getTime() < plus30Mins.getTime()
      ? endOfToday().toUTCString()
      : plus30Mins.toUTCString()
  window.document.cookie = `sessionStartTime=${sessionTime.getTime()}; path=/; expires=${expires}`
}

function debounce(callback: () => void, waitInMs: number) {
  let timeout: ReturnType<typeof setTimeout> | undefined
  return () => {
    clearTimeout(timeout)
    timeout = setTimeout(callback, waitInMs)
  }
}

const WRITE_TO_WEB_STORAGE_TIMEOUT = 5_000

const debounchedUpdateExpiration = debounce(() => {
  const sessionTime = getSessionStartTime()
  storeWithNewExpiration(sessionTime)
}, WRITE_TO_WEB_STORAGE_TIMEOUT)

window.addEventListener("click", () => {
  debounchedUpdateExpiration()
})
window.addEventListener("focus", () => {
  debounchedUpdateExpiration()
})
