import {
  createFileRoute,
  lazyRouteComponent,
  redirect,
} from "@tanstack/react-router"
import { loadQuery } from "react-relay"

import MobilePriceHikeCheckerQuery, {
  MobilePriceHikeCheckerQuery as MobilePriceHikeCheckerQueryType,
} from "src/tools/MobilePriceHikeChecker/__generated__/MobilePriceHikeCheckerQuery.graphql"

export const Route = createFileRoute("/mobile-hikes")({
  loader: ({ context }) =>
    loadQuery<MobilePriceHikeCheckerQueryType>(
      context.relayEnvironment(),
      MobilePriceHikeCheckerQuery,
      {},
    ),
  beforeLoad({ context }) {
    if (context.authState.isAuthenticated) {
      throw redirect({ to: "/tools/mobile-hikes" })
    }
  },
  component: lazyRouteComponent(
    () =>
      import("src/pages/MobilePriceHikeCheckerPage/MobilePriceHikeCheckerPage"),
  ),
})
