import { datadogRum } from "@datadog/browser-rum"

export const initDatadogUserMonitoring = (
  clientToken: string,
  applicationId: string,
  site: string,
) => {
  datadogRum.init({
    applicationId: applicationId,
    clientToken,
    site,
    service: __SERVICE_NAME__,
    version: __GITHUB_SHA__ ?? "unknown",
    sessionSampleRate: 75,
    traceSampleRate: 75,
    sessionReplaySampleRate: 50,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: "mask-user-input",
    allowedTracingUrls: ["https://api.staging.nous.co", "https://api.nous.co"],
  })

  datadogRum.startSessionReplayRecording()
}
