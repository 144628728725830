import React, { useCallback } from "react"

import clsx from "clsx"

import { GlowIcon } from "./GlowIcon"
import { GlowText, GlowTextSize } from "./GlowText"

export type GlowTextAreaProps = {
  value: string | number
  onChange: (value: string) => void
  errors?: GlowTextAreaErrorMessagesType
  label?: string
  placeholder?: string
  assistiveText?: string
  prefixIcon?: React.ReactElement<React.ComponentProps<typeof GlowIcon>>
  className?: string
} & Omit<React.ComponentPropsWithoutRef<"textarea">, "onChange">

export function GlowTextArea({
  onChange,
  value,
  errors,
  label,
  placeholder,
  assistiveText,
  className,
  prefixIcon,
  ...rest
}: GlowTextAreaProps) {
  const onChangeWrapped = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      const fn = onChange
      fn(e.target.value)
    },
    [onChange],
  )
  return (
    <label className="flex-col flex gap-1 w-full">
      {label && (
        <GlowText size="sm" fontWeight="medium">
          {label}
        </GlowText>
      )}
      {assistiveText && (
        <GlowText size="xs" className="text-off-black-64">
          {assistiveText}
        </GlowText>
      )}
      <div className="relative focus-within:outline-[3px] focus-within:outline-serene-blue-400 focus-within:outline-offset-0 rounded-lg">
        {prefixIcon && (
          <div className="absolute left-[14px] top-[14px]">
            {React.cloneElement(prefixIcon, {
              className: clsx(prefixIcon.props.className, "w-5 h-5"),
            })}
          </div>
        )}
        <textarea
          onChange={onChangeWrapped}
          value={value}
          placeholder={placeholder}
          className={clsx(
            "w-full",
            "focus:outline-0",
            "focus:ring-0",
            "focus:border-transparent",
            "border rounded-lg p-[11px]",
            "hover:border-black",
            errors?.length ? "border-angsty-red-400" : "border-off-black-20",
            "placeholder:text-off-black-64",
            "disabled:bg-gray-50 disabled:text-off-black-32 disabled:hover:border-off-black-20",
            prefixIcon && "pl-[40px]",
            className,
          )}
          {...rest}
        />
      </div>
      <GlowTextAreaErrors errors={errors} />
    </label>
  )
}

export type GlowTextAreaErrorMessagesType = (
  | string
  | false
  | null
  | undefined
)[]

export type GlowTextAreaErrorsProps = {
  errors?: GlowTextAreaErrorMessagesType
  fontSize?: GlowTextSize
}
export function GlowTextAreaErrors({
  errors,
  fontSize,
}: GlowTextAreaErrorsProps) {
  return (
    <>
      {errors?.filter(Boolean).map((error, i) => (
        <GlowText
          key={i}
          as="p"
          className="text-angsty-red-400"
          margin={{ top: "1" }}
          size={fontSize ?? "xs"}
        >
          {error}
        </GlowText>
      ))}
    </>
  )
}
