import React from "react"

import { clsx } from "clsx"

type LoadingDotsProps = {
  className?: string
}

export const LoadingDots = ({ className }: LoadingDotsProps) => {
  return (
    <div
      className={clsx(
        "loading-dots-animation flex items-center justify-center space-x-1 font-bold",
        className,
      )}
    >
      <span>.</span>
      <span>.</span>
      <span>.</span>
    </div>
  )
}
