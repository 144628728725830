/**
 * @generated SignedSource<<32e5602d63696f8c5c3a03e0577dc04d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type InviteModalContentQuery$variables = Record<PropertyKey, never>;
export type InviteModalContentQuery$data = {
  readonly household: {
    readonly pendingInvites: ReadonlyArray<{
      readonly id: string;
      readonly " $fragmentSpreads": FragmentRefs<"InviteModalContent_invites" | "InviteModalPendingInvite_invite">;
    }>;
  };
};
export type InviteModalContentQuery = {
  response: InviteModalContentQuery$data;
  variables: InviteModalContentQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "InviteModalContentQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Household",
        "kind": "LinkedField",
        "name": "household",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "HouseholdMemberInvite",
            "kind": "LinkedField",
            "name": "pendingInvites",
            "plural": true,
            "selections": [
              (v0/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "InviteModalPendingInvite_invite"
              },
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "InviteModalContent_invites"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "InviteModalContentQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Household",
        "kind": "LinkedField",
        "name": "household",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "HouseholdMemberInvite",
            "kind": "LinkedField",
            "name": "pendingInvites",
            "plural": true,
            "selections": [
              (v0/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "initials",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "firstName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "displayName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "email",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v0/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "48f76ee4619aa29dacefd7b592fd071c",
    "id": null,
    "metadata": {},
    "name": "InviteModalContentQuery",
    "operationKind": "query",
    "text": "query InviteModalContentQuery {\n  household {\n    pendingInvites {\n      id\n      ...InviteModalPendingInvite_invite\n      ...InviteModalContent_invites\n    }\n    id\n  }\n}\n\nfragment Avatar_invite on HouseholdMemberInvite {\n  initials\n  firstName\n}\n\nfragment InviteModalContent_invites on HouseholdMemberInvite {\n  id\n  ...InviteModalPendingInvite_invite\n}\n\nfragment InviteModalPendingInvite_invite on HouseholdMemberInvite {\n  ...Avatar_invite\n  displayName\n  email\n}\n"
  }
};
})();

(node as any).hash = "bc2f5e3d16151e884ce4b81caa5885d7";

export default node;
