import React from "react"

import { Dialog } from "@headlessui/react"
import clsx from "clsx"
import { AnimatePresence, motion } from "framer-motion"

import { GlowIcon } from "src/glow"
import { Viewport, useViewport } from "src/hooks/responsive"

export type ModalProps = {
  header: string | React.ReactNode
  isOpen: boolean
  onClose: () => void
  /**
   * Use this to override the default `onClose` behaviour.
   */
  onOverlayClick?: () => void
  noPadding?: boolean
  level?: "0" | "1" | "2"
  disableManualClose?: boolean
  children?: React.ReactNode
  modalClassName?: string
}

export const ModalTitle = ({ children }: { children?: React.ReactNode }) => (
  <p className="text-center font-bold text-base">{children}</p>
)

type ModalFullScreenProps = Exclude<ModalProps, "onOverlayClick"> & {
  bgColour?: string
}

export const ModalFullScreen = ({
  header,
  isOpen,
  onClose,
  noPadding,
  disableManualClose,
  bgColour = "bg-white",
  children,
  modalClassName,
}: ModalFullScreenProps) => {
  return (
    <AnimatePresence>
      {isOpen && (
        <Dialog
          static
          as={motion.div}
          className={clsx("fixed inset-0 z-modal !m-0 flex flex-col", bgColour)}
          initial={{ y: "100vh" }}
          exit={{ y: "100vh" }}
          animate={{ y: 0 }}
          transition={{
            duration: 0.2,
          }}
          open={isOpen}
          onClose={onClose}
        >
          {header && (
            <div className="w-full-escape relative flex h-14 items-center justify-center border-b border-gray-500/12 py-5">
              <div className="flex items-start">
                {typeof header === "string" ? (
                  <ModalTitle>{header}</ModalTitle>
                ) : (
                  header
                )}
              </div>
              {!disableManualClose && (
                <button
                  onClick={onClose}
                  className="absolute right-4 p-1"
                  style={{ borderRadius: "50%" }}
                >
                  <GlowIcon name="close_regular" className="w-6" />
                </button>
              )}
            </div>
          )}

          <div
            className={clsx(
              "flex grow flex-col overflow-hidden",
              noPadding || "p-4 md:p-6",
              modalClassName,
            )}
          >
            {children}
          </div>
        </Dialog>
      )}
    </AnimatePresence>
  )
}

type ModalFloatyProps = ModalProps & {
  overlayClassName?: string
  onClick?: () => void
  onScroll?: () => void
}

export const ModalFloaty = ({
  header,
  isOpen,
  onClose,
  children,
  onOverlayClick,
  noPadding,
  level = "0",
  disableManualClose,
  overlayClassName,
  modalClassName,
  ...props
}: ModalFloatyProps) => {
  const ref = React.useRef<HTMLDivElement>(null)

  const onScroll = React.useCallback(() => {
    props.onScroll?.()
  }, [props])

  React.useEffect(() => {
    if (!ref.current) {
      return
    }

    const element = ref.current

    element.addEventListener("scroll", onScroll)

    return () => {
      element.removeEventListener("scroll", onScroll)
    }
  }, [onScroll])

  return (
    <Dialog
      className={clsx(
        "fixed inset-0 flex flex-wrap overflow-y-auto",
        level === "0" && "z-modal",
        level === "1" && "z-modal-level-1",
        level === "2" && "z-modal-level-2",
        overlayClassName,
      )}
      onClose={() => {
        if (disableManualClose) {
          return
        }

        if (onOverlayClick) {
          return onOverlayClick()
        }

        onClose()
      }}
      open={isOpen}
    >
      <div className="flex min-h-full w-full flex-col items-center justify-center md:min-h-0">
        <Dialog.Overlay className="fixed inset-0 bg-gray-500 opacity-64" />

        <div
          className={clsx(
            "h-auto max-h-[90vh]",
            "min-w-[500px]",
            "max-w-[528px]",
            "flex flex-col flex-shrink-0",
            "mx-6 rounded-lg bg-white shadow-lg",
            level === "1" ? "z-modal-level-1" : "z-modal",
            "cursor-default",
            modalClassName,
          )}
          onClick={(event) => {
            event.stopPropagation()
          }}
          tabIndex={-1}
          onKeyDown={(event) => {
            event.stopPropagation()
          }}
          role="button"
        >
          <div className="flex justify-center border-b border-gray-500/12 relative">
            <div className="py-3 md:py-4 text-center w-full px-12 min-h-[56px]">
              {typeof header === "string" ? (
                <ModalTitle>{header}</ModalTitle>
              ) : (
                header
              )}
            </div>
            {!disableManualClose && (
              <button
                onClick={(event) => {
                  event.stopPropagation()
                  onClose()
                }}
                data-testid="modal-close-button"
                className="absolute right-4 top-4"
              >
                <GlowIcon name="close_regular" className="w-6" />
              </button>
            )}
          </div>

          <div
            className={clsx("overflow-y-auto", noPadding || "p-4 md:p-6")}
            ref={ref}
          >
            {children}
          </div>
        </div>
      </div>
    </Dialog>
  )
}

export const Modal = ({
  variant = "floaty",
  ...props
}: ModalProps & {
  variant?: "fullscreen" | "floaty"
  onScroll?: () => void
}) => {
  const viewport = useViewport()
  const isMobile = viewport <= Viewport.SM
  if (isMobile) {
    return variant === "fullscreen" ? (
      <ModalFullScreen {...props} />
    ) : (
      <ModalFloaty {...props} />
    )
  }
  // No full screen modal on desktop!
  return <ModalFloaty {...props} />
}
