export const savingsQuests: ReadonlyArray<`${string}SavingsQuest`> = [
  "BroadbandSavingsQuest",
  "EnergySavingsQuest",
  "MobileSavingsQuest",
  "MortgageSavingsQuest",
] as const
export type SavingsQuest = (typeof savingsQuests)[number]

const setupQuests: ReadonlyArray<`${string}ManagementQuest`> = [
  "HouseholdManagementQuest",
] as const
export type SetupQuest = (typeof setupQuests)[number]
