import { createFileRoute, lazyRouteComponent } from "@tanstack/react-router"
import { loadQuery } from "react-relay"

import OnboardingLayout from "src/pages/Onboarding/layouts/OnboardingLayout"
import OnboardingWhatsAppPageQuery, {
  OnboardingWhatsAppPageQuery as OnboardingWhatsAppPageQueryType,
} from "src/pages/Onboarding/OnboardingWhatsAppPage/__generated__/OnboardingWhatsAppPageQuery.graphql"
import requireIsLoggedIn from "src/routeUtils/requireIsLoggedIn"

export const Route = createFileRoute("/onboarding/whatsApp-details")({
  beforeLoad: requireIsLoggedIn({ redirectTo: "/onboarding/" }),
  pendingComponent: () => (
    <OnboardingLayout title={""}>
      <></>
    </OnboardingLayout>
  ),

  loader: ({ context }) =>
    loadQuery<OnboardingWhatsAppPageQueryType>(
      context.relayEnvironment(),
      OnboardingWhatsAppPageQuery,
      {},
    ),
  component: lazyRouteComponent(
    () =>
      import(
        "src/pages/Onboarding/OnboardingWhatsAppPage/OnboardingWhatsAppPage"
      ),
  ),
})
